import { createSelector } from 'reselect';

const selectMember = createSelector(
  (state) => state.industry,
  (industry) => {
    return industry.member;
  }
);

export default selectMember;
