import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';

import HeaderSubMenuItemLink from './HeaderSubMenuItemLink';
import SmartLink from './SmartLink';
import ImgixImage from './ImgixImage';
import TextTitle from './TextTitle';
import useHover from './useHover';
import selectMember from '../state/industry/selectMember';
import { setActiveHeaderSubMenuItem } from '../state/ui/uiSlice';
import getStoryblokLinkUrl from '../lib/getStoryblokLinkUrl';

const HeaderSubMenuItem = ({
  text,
  _uid,
  link,
  secondaryText,
  items = [],
  image,
  component,
}) => {
  const dispatch = useDispatch();

  const [ref] = useHover(() => {
    dispatch(setActiveHeaderSubMenuItem(_uid));
  });
  const member = useSelector(selectMember);
  const activeHeaderSubMenuItem = useSelector(
    (state) => state.ui.activeHeaderSubMenuItem
  );
  return (
    <Box ref={ref} flex background="white" gap="small">
      {component === 'SubMenu' && (
        <SmartLink plain to={getStoryblokLinkUrl(link)}>
          {text}
        </SmartLink>
      )}
      {items.length > 0 ? (
        <Grid
          gap="xsmall"
          columns={{ count: items.length > 5 ? 2 : 1, size: 'auto' }}
        >
          {items.map((x) => (
            <HeaderSubMenuItemLink
              key={x._uid}
              member={member}
              {...x}
              secondaryText={secondaryText}
              _uid={_uid}
              activeItem={activeHeaderSubMenuItem}
            />
          ))}
        </Grid>
      ) : (
        <SmartLink fill plain to={getStoryblokLinkUrl(link)}>
          <Box gap="small">
            <Box fill>
              {image?.filename && (
                <ImgixImage
                  src={image.filename}
                  sizes="25vw"
                  srcSetOptions={{ ar: '1:1', crop: 'entropy', fit: 'crop' }}
                  fit="cover"
                  fill
                />
              )}
            </Box>
            <SmartLink plain to={link.cached_url}>
              <TextTitle>{text}</TextTitle>
            </SmartLink>
          </Box>
        </SmartLink>
      )}
    </Box>
  );
};

HeaderSubMenuItem.propTypes = {
  text: PropTypes.string,
  last: PropTypes.bool,
  _uid: PropTypes.string,
  link: PropTypes.object,
  secondaryText: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.array,
  image: PropTypes.object,
  component: PropTypes.string,
};

export default memo(HeaderSubMenuItem);
