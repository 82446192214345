import {
  find,
  get,
  isEqual,
  includes,
  isString,
  toLower,
  flatten,
  filter,
  reject,
  map,
  last,
  head,
  lowerCase,
  isUndefined,
  contains,
  reduce,
  some,
  titleCase,
} from './nodash';
import snakeCase from 'lodash.snakecase';
import formatCurrency from './formatCurrency';
import extractGid from './extractGid';
import deNodify from './deNodify';
import {
  PAINT_PRODUCT_TYPES,
  INTERIOR_STANDARD,
  NON_RETURNABLE_TYPES,
  SUPPLY_PRODUCT_TYPES,
  APPAREL_TYPES,
  WALLCOVERING_PRODUCT_TYPES,
  isApparelType,
} from './productTypes';
import { WALLCOVERINGS_SIZE_TAGS } from './constants';

export const getProdType = (x) =>
  get('product_type', x) ||
  get('productType', x) ||
  get('variant.product.productType', x) ||
  get('product.productType', x) ||
  get('node.variant.product.productType', x);

export const findOneGalVariant = (variants) => {
  return find((x) => {
    const title = toLower(get('title', x));
    const fullInTitle = includes('full', title);
    const singleInTitle = includes('single', title);
    const notPlural = !includes('gallons', title);
    return (fullInTitle || singleInTitle) && notPlural;
  }, deNodify(variants));
};

export const findHalfGalVariant = (variants) => {
  return find((x) => {
    const title = toLower(get('title', x));
    const fullInTitle = includes('half', title);
    const notPlural = !includes('gallons', title);
    return fullInTitle && notPlural;
  }, deNodify(variants));
};

export const findFiveGalVariant = (variants) =>
  find(
    (x) =>
      includes('five', toLower(get('title', x))) ||
      includes('5', toLower(get('title', x))),
    deNodify(variants)
  );

export const findSampleVariant = (variants) => {
  return find(
    (x) => {
      return includes('sample', toLower(get('title', x)));
    },
    get('edges', variants) ? deNodify(variants) : variants
  );
};

export const findMemoVariant = (variants) => {
  return find(
    (x) => {
      return includes('memo', toLower(get('title', x)));
    },
    get('edges', variants) ? deNodify(variants) : variants
  );
};

export const isPeelAndStick = (x) => {
  const title = toLower(get('title', x));
  const peelInTitle = includes('peel', title);
  return peelInTitle;
};

export const isTraditional = (x) => {
  const title = toLower(get('title', x));
  const tradInTitle = includes('traditional', title);
  return tradInTitle;
};

export const isSingleRoll = (x) => {
  const title = toLower(get('title', x));
  const singleInTitle = includes('single', title);
  const rollInTitle = includes('roll', title);
  return singleInTitle && rollInTitle;
};

export const isPanel = (x) => {
  const title = toLower(get('title', x));
  const panelInTitle = includes('panel', title);
  return panelInTitle;
};

export const findSingleRollTraditionalVariant = (variants) => {
  return (
    find((x) => {
      return isTraditional(x) && isSingleRoll(x);
    }, deNodify(variants)) ?? findSingleRollVariants(variants)[0]
  );
};

export const findDefaultTraditionalVariant = (variants) => {
  return (
    find((x) => {
      return isTraditional(x) && (isSingleRoll(x) || isPanel(x));
    }, deNodify(variants)) ?? findSingleRollVariants(variants)[0]
  );
};

export const findSingleRollPeelStickVariant = (variants) => {
  return find((x) => {
    return isPeelAndStick(x) && isSingleRoll(x);
  }, deNodify(variants));
};

export const findSingleRollVariants = (variants) => {
  return variants.filter((x) => {
    const title = toLower(get('title', x));
    const singleInTitle = includes('single', title);
    const rollInTitle = includes('roll', title);
    return rollInTitle && singleInTitle;
  });
};

export const findFullWallcoveringsVariants = (variants) => {
  return variants.filter((x) => {
    return !isMemo(x);
  });
};

export const findPanelVariants = (variants) => {
  return variants.filter(isPanel);
};

export const findInteriorStandardShopifyProduct = (shopifyProducts) =>
  find((x) => contains('interior-standard', get('handle', x)), shopifyProducts);

export const findInteriorStandardGroupedProduct = (group) =>
  find((x) => {
    return isEqual(snakeCase(INTERIOR_STANDARD), get('product_type', x));
  }, get('products', group));

export const getVariantPrice = (variant, unformatted = false) =>
  unformatted
    ? get('price.amount', variant) ||
      get('price', variant) ||
      get('priceV2.amount', variant)
    : formatCurrency(
        get('price.amount', variant) ||
          get('price', variant) ||
          get('priceV2.amount', variant)
      );

export const getVariantTitle = (x) =>
  get('variant_title', x) ||
  get('variant.title', x) ||
  get('node.variant.title', x) ||
  get('title', x);

export const getMinUnitLabel = (variants) => {
  return some(isPaint, variants)
    ? findOneGalVariant(variants)
      ? '1 Gallon'
      : findFiveGalVariant(variants)
      ? '5 Gallons'
      : 'Sample'
    : '';
};

export const findSampleVariantForColor = (products) =>
  findSampleVariant(flatten(map('variants', products)));

export const isSample = (x) =>
  includes(getProdType(x) || PAINT_PRODUCT_TYPES[0], [
    ...PAINT_PRODUCT_TYPES,
    ...WALLCOVERING_PRODUCT_TYPES,
  ]) && includes('sample', lowerCase(getVariantTitle(x)));
export const isMemo = (x) =>
  getProdType(x) === 'Wallcoverings' &&
  includes('memo', lowerCase(getVariantTitle(x)));
export const isPaint = (x) =>
  isEqual('paint', get('group_type', x)) ||
  isEqual('paint', toLower(get('collection_type', x))) ||
  isEqual('paint', toLower(get('collectionType', x))) ||
  (includes(getProdType(x), [
    ...PAINT_PRODUCT_TYPES,
    ...map(snakeCase, PAINT_PRODUCT_TYPES),
  ]) &&
    !includes('sample', lowerCase(getVariantTitle(x))));
export const isWallcovering = (x) =>
  isEqual('wallcoverings', get('group_type', x)) ||
  isEqual('wallcoverings', toLower(get('collection_type', x))) ||
  isEqual('wallcoverings', toLower(get('collectionType', x))) ||
  (includes(getProdType(x), [
    ...WALLCOVERING_PRODUCT_TYPES,
    ...map(snakeCase, WALLCOVERING_PRODUCT_TYPES),
  ]) &&
    !includes('memo', lowerCase(getVariantTitle(x))));
export const isSupply = (x) => includes(getProdType(x), SUPPLY_PRODUCT_TYPES);
export const isPrimer = (x) => includes('primer', lowerCase(getProdType(x)));

export const isFiveGallonQty = (paintData) => {
  return (
    includes('5 gallon', toLower(getVariantTitle(paintData))) ||
    includes('five gallon', toLower(getVariantTitle(paintData)))
  );
};

export const isVariantHalfGal = (x) => {
  const variant = x.node ? x.node : x;
  const title = getVariantTitle(variant);
  return (
    toLower(title).indexOf('0.5 gallon') > -1 ||
    toLower(title).indexOf('half gallon') > -1
  );
};
export const isVariantPurchasable = (x) =>
  get('quantityAvailable', x) > 0 || get('currentlyNotInStock', x);

export const isVariant5Gal = (x) => {
  const title = toLower(get('title', x));
  return (
    (title.indexOf('5') > -1 && title.indexOf('gallons') > -1) ||
    title.indexOf('five gallon') > -1
  );
};

export const getVariantGallonCount = (x) => {
  if (isFiveGallonQty(x)) {
    return 5;
  }
  if (isVariantHalfGal(x)) {
    return 0.5;
  }
  return 1;
};

export const getGallonSizeText = (count) => {
  switch (count) {
    case 0.5:
      return 'Half';
    case 1:
      return 'Single';
    case 5:
      return 'Five';
    default:
      return 'Single';
  }
};

export const getVariantSizeAttribute = (variant) => {
  if (isPaint(variant.product)) {
    const gallons = isSample(variant) ? 0 : getVariantGallonCount(variant);
    return gallons
      ? `${getGallonSizeText(gallons)} Gallon`
      : '12"x12" Adhesive Swatch';
  }
  if (isWallcovering(variant.product)) {
    return isMemo(variant)
      ? 'Memo'
      : variant.title
          .replace(variant.product?.productType, '')
          .replace(' / ', '');
  }
  if (isApparelType(variant.product?.productType)) {
    return variant.title
      .replace(variant.product?.productType, '')
      .replace(' / ', '');
  }
};

export const getProductPath = (product = {}, suffix) => {
  return product
    ? `/products/${product.handle}${suffix ? `-${suffix}` : ''}`
    : undefined;
};

export const getMaxPrice = (priceRange) => {
  return get('maxVariantPrice.amount', priceRange);
};

export const extractVariantId = (gid) => {
  if (!gid || typeof gid === 'undefined') return null;
  const id = extractGid(gid);
  return last(id.split('/'));
};

export const getLowestVariantPrice = (variants) => {
  return head(
    map(
      (x) => parseFloat(get('price.amount', x) ?? get('price', x)),
      reject(isSample, variants)
    ).sort((a, b) => a - b)
  );
};

export const isReturnable = (lineItem) => {
  const product = lineItem?.product ?? lineItem;

  return (
    !isSample(product) &&
    !isMemo(product) &&
    !isFiveGallonQty(product) &&
    NON_RETURNABLE_TYPES.indexOf(getProdType(product)) === -1 &&
    (isUndefined(get('requiresShipping', product))
      ? true
      : get('requiresShipping', product) &&
        isEqual('fulfilled', get('fulfillmentStatus', product)))
  );
};

export const withoutSamples = (collection) =>
  reject(
    (x) => contains('sample', lowerCase(get('variant_title', x))),
    collection
  );

export const withoutDonations = (collection) =>
  reject(
    (x) =>
      contains(
        'donation',
        lowerCase(get('title', x)) || lowerCase(get('name', x))
      ),
    collection
  );

export const parseTagFeatures = (tags) =>
  reduce(
    (mem, tag) => {
      const tagVal = isString(tag) ? tag : get('slug', tag);
      switch (tagVal) {
        case 'color:best-sellers':
          mem['bestSeller'] = true;
          break;
        case 'color.best-sellers':
          mem['bestSeller'] = true;
          break;
        case 'partnership:coming-soon':
          mem['comingSoon'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership.coming-soon':
          mem['comingSoon'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership:dunkin':
          mem['dunkin'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership.dunkin':
          mem['dunkin'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership:madewell':
          mem['madewell'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership.madewell':
          mem['madewell'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership:barbie':
          mem['barbie'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership.barbie':
          mem['barbie'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership:porsche':
          mem['porsche'] = true;
          mem['partnership'] = true;
          break;
        case 'partnership.porsche':
          mem['porsche'] = true;
          mem['partnership'] = true;
          break;
        case 'badge:new':
          mem['isNew'] = true;
          break;
        case 'badge.new':
          mem['isNew'] = true;
          break;
        case 'badge:color-of-the-year':
          mem['colorOfTheYear'] = true;
          break;
        case 'badge:best-wallpaper':
          mem['bestWallpaper'] = true;
          break;
        case 'badge:best-wallpaper-2023':
          mem['bestWallpaper2023'] = true;
          break;
      }
      return mem;
    },
    {},
    tags
  );

// Legacy product_group method, remove when migrated
export const findImageOfType = (relation_type, product, returnMultiple) => {
  const method = returnMultiple ? filter : find;
  const getMethod = returnMultiple ? map : get;
  return getMethod(
    'media',
    method({ relation_type }, get('product_media', product))
  );
};

export const findImage = (images, matcher) => {
  const match = find((x) => {
    return includes(
      toLower(matcher),
      toLower(get('src', x) || get('originalSrc', x))
    );
  }, deNodify(images));
  return get('src', match) || get('originalSrc', match);
};

export const findImages = (images, matcher) => {
  return filter((x) => {
    return includes(
      toLower(matcher),
      toLower(get('src', x) || get('originalSrc', x))
    );
  }, deNodify(images));
};

export const findMetafieldValue = (key, metafields, namespace) => {
  const matcher = namespace ? { key, namespace } : { key };
  const field = find(matcher, metafields);
  const value = get('value', field);
  if (
    field?.type === 'json_string' ||
    (typeof value === 'string' && value.substring(0, 2) === '[{')
  ) {
    return JSON.parse(value);
  }
  return value;
};

export const getMetafieldTags = (metafields, key = 'tags', namespace) => {
  return (findMetafieldValue(key, metafields, namespace) || '').split('|');
};

export const findImageMetafield = (key, metafields) => {
  const field = find({ key, namespace: 'images' }, metafields);
  return isEqual('json_string', get('type', field))
    ? JSON.parse(get('value', field))
    : get('value', field);
};

export const hasDarkTag = (tags) => {
  const lowertags = map(toLower, tags);
  return includes('invert', lowertags);
};

export const canQuickAdd = (product) => {
  return (
    isPrimer(product) ||
    (!isPaint(product) &&
      APPAREL_TYPES.indexOf(getProdType(product)) === -1 &&
      toLower(get('title', product)).indexOf('touch up') === -1)
  );
};

export const generatePaintProductSeoTitle = (product, productGroup) => {
  return `${productGroup?.title} Paint - ${product.productType}`;
};

export const generateWallcoveringsProductSeoTitle = (product, productGroup) => {
  const descriptor = titleCase(
    product.title.replace(productGroup?.title || '', '').replace(' - ', '')
  );
  return `${descriptor} ${product.productType} | ${productGroup?.title}`;
};

export function getWallcoveringsProductColorway(collection, product) {
  return `${product?.title}`.replace(`${collection.title} - `, '');
}

export function getWallcoveringsVariantName(variant) {
  return `${variant.title}`.replace(`Wallcoverings / `, '');
}

export function excludeVariants(allVariants, toExclude) {
  const variants = (allVariants ? deNodify(allVariants) : []).filter(
    (x) => !toExclude.includes(x.shopifyId ?? x.id)
  );
  return variants;
}

export function getExcludedVariantsFromProductResponse(data) {
  const excludedVariantsMeta =
    get('node.product.excludedVariantsFromPicker.value', data) ??
    get('node.excludedVariantsFromPicker.value', data);
  return excludedVariantsMeta ? JSON.parse(excludedVariantsMeta) : [];
}

export function filterWallcoveringsSizeTags(tag) {
  return WALLCOVERINGS_SIZE_TAGS.includes(tag);
}

export default parseTagFeatures;
