let eventMap: Record<string, any> = {};

const eventBus = {
  on(event, callback) {
    const wrapper = (e) => callback(e.detail);
    eventMap[event] = wrapper;
    document.addEventListener(event, wrapper);
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event) {
    document.removeEventListener(event, eventMap[event]);
  },
};

export default eventBus;
