import { reduce, isNil } from './nodash';

const isRequired = (setting, settings) => settings.indexOf(setting) > -1;

const isVisibleByVisibilitySettings = (
  settings = [],
  { discountCode, jwt }
) => {
  if (settings.length === 0) {
    return false;
  }
  const requirements = reduce(
    (mem, setting) => {
      mem[setting] = isRequired(setting, settings);
      return mem;
    },
    {},
    settings
  );

  const currentState = {
    authenticated: !isNil(jwt),
    unauthenticated: isNil(jwt),
    discountCodeApplied: !isNil(discountCode),
    noDiscountCodeApplied: isNil(discountCode),
  };

  const passesAuth =
    requirements.authenticated === true
      ? currentState.authenticated === true
      : false;
  const passesUnAuth =
    requirements.unauthenticated === true
      ? currentState.unauthenticated === true
      : (!requirements.unauthenticated && !requirements.authenticated) ||
        requirements.unauthenticated === false;
  const authRequirement = passesAuth || passesUnAuth;

  const passesDiscount =
    requirements.discountCodeApplied === true
      ? currentState.discountCodeApplied === true
      : false;
  const passesNoDiscount =
    requirements.noDiscountCodeApplied === true
      ? currentState.noDiscountCodeApplied === true
      : (!requirements.noDiscountCodeApplied &&
          !requirements.discountCodeApplied) ||
        requirements.noDiscountCodeApplied === false;
  const discountCodeRequirement = passesDiscount || passesNoDiscount;

  return authRequirement && discountCodeRequirement;
};

export default isVisibleByVisibilitySettings;
