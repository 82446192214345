import generateHeadingStyles from '../lib/generateHeadingStyles';

const baseSpacing = 30;
const scale = 7.5;
const baseFontSize = 17; // 18
const fontScale = baseSpacing / scale; // 4

const fontSizing = (factor, lineHeight = 0.6) => ({
  size: `${baseFontSize + factor * fontScale}px`,
  height: `${baseSpacing + (factor + lineHeight) * fontScale}px`,
  // maxWidth chosen to be ~50 characters wide
  // see: https://ux.stackexchange.com/a/34125
  maxWidth: `${baseSpacing * (baseFontSize + (factor + 1) * fontScale)}px`,
});

export default {
  global: {
    mobileMenuHeight: '48px',
    mobileAnnouncementBarHeight: '27px',
    baseSpacing,
    colors: {
      focus: 'rgba(0,0,0,0.15)',
      'dark-1': '#333333',
      'light-2': '#F0F0F0',
      brand: '#000',
      placeholder: '#757575',
      border: 'black',
      lightGray: '#F1F1F1',
      porscheBlue: '#007FBA',
      porscheMagenta: '#7B0545',
      porscheYellow: '#D9A500',
      porscheGreen: '#113620',
      text: {
        light: '#000',
        dark: '#fff',
      },
    },
    hover: {
      background: 'black',
      color: 'white',
    },
    font: {
      family: 'akzidenz-grotesk, Helvetica, Arial, sans-serif',
      ...fontSizing(0),
      height: 'normal',
    },
    focus: {
      border: {
        color: 'focus',
        weight: 0,
      },
      outline: {
        color: 'focus',
        size: '2px',
      },
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: `${baseSpacing / 6}px`, // 4
      large: `${baseSpacing / 2}px`, // 12
      xlarge: `${baseSpacing}px`, // 24
    },
    breakpoints: {
      small: {
        value: 768,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '3px',
          large: '5px',
          xlarge: '10px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '5px',
          medium: '15px',
          large: '20px',
          xlarge: '30px',
        },
        size: {
          xxsmall: '20px',
          xsmall: '40px',
          small: '80px',
          medium: '160px',
          large: '320px',
          xlarge: '640px',
          full: '100%',
        },
      },
      medsmall: {
        value: 960,
      },
      medium: {
        value: 1280,
      },
      large: {
        value: 1460,
      },
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '3px',
      xsmall: '5px',
      small: '10px',
      medium: '20px',
      medlarge: '30px',
      large: '40px',
      xlarge: '65px',
      responsiveBreakpoint: 'small',
    },
    // edgeSize: {
    //   none: '0px',
    //   hair: '1px', // for Chart
    //   xxsmall: '3px', // 3
    //   xsmall: `${baseSpacing / 4}px`, // 6
    //   small: `${baseSpacing / 3}px`, // 12
    //   medium: `${baseSpacing}px`, // 24
    //   large: `${baseSpacing}px`, // 48
    //   xlarge: `${baseSpacing * 2}px`, // 96
    //   responsiveBreakpoint: 'small',
    // },
    input: {
      padding: `${baseSpacing / 3}px`,
      weight: 'normal',
      font: {
        weight: 600,
      },
      extend: 'font-size: 1.2rem',
    },
    control: {
      border: {
        radius: 0,
        width: '2px',
        color: 'black',
      },
    },
    drop: {
      zIndex: 1003,
    },
  },
  layer: {
    zIndex: 1003,
    container: {
      zIndex: 1000,
    },
    overlay: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
  },
  paragraph: {
    xsmall: { size: '11px', height: '14px' },
    small: { size: '15px', height: '17px', maxWidth: '450px' },
    medium: { size: '17px', height: '1.53333', maxWidth: '40em' },
    large: { size: '17px', height: '26px', maxWidth: '40em' },
    xlarge: { ...fontSizing(1) },
    xxlarge: { ...fontSizing(1) },
  },
  text: {
    xsmall: { size: '11px', height: '14px' },
    small: { size: '15px', height: '17px' },
    medium: { size: '17px', height: '1.53333' },
    large: { size: '22px', height: '26px' },
    xlarge: { ...fontSizing(2.25) },
    xxlarge: { size: '44px', height: 1 },
  },
  button: {
    size: {
      small: {
        border: {
          radius: 0,
        },
      },
      medium: {
        border: {
          radius: 0,
        },
      },
      large: {
        border: {
          radius: 0,
        },
      },
    },
    border: {
      radius: 0,
    },
    hover: {
      secondary: {
        background: {
          color: 'black',
        },
        color: 'white',
      },
    },
    default: {
      extend: `
      text-transform: uppercase;
      font-weight: 500;
      font-family: "akzidenz-grotesk", Helvetica, Arial, sans-serif;
      font-style: normal;
      letter-spacing: 0.1em;
      text-rendering: optimizeLegibility;
      font-display: swap
    `,
      color: {
        dark: 'white',
        light: 'black',
      },
      background: {
        color: 'white',
      },
      padding: {
        vertical: '20px',
        horizontal: '22px',
      },
      font: {
        weight: 500,
        family: "'akzidenz-grotesk', sans-serif",
      },
    },
    primary: {
      radius: 0,
      extend: `
        text-transform: uppercase;
        font-weight: 500;
        font-family: "akzidenz-grotesk", Helvetica, Arial, sans-serif;
        font-style: normal;
        letter-spacing: 0.1em;
        text-rendering: optimizeLegibility;
        color: #fff;
        > * {
          color: #fff;
          font-weight: 500;
        }
        & > div {justify-content: space-between; height: 100%;}
      `,
      background: {
        color: 'black',
      },
      color: 'white',
      border: {
        radius: 0,
        color: 'brand',
        width: '2px',
      },
      padding: {
        vertical: 'medium',
        horizontal: 'medium',
      },
    },
    secondary: {
      extend: `
      text-transform: uppercase;
      font-weight: 500;
      font-family: "akzidenz-grotesk", Helvetica, Arial, sans-serif;
      font-style: normal;
      letter-spacing: 0.1em;
      text-rendering: optimizeLegibility;
      font-display: swap

      &:hover {
        > div {
          color: #fff;
        }
      }

      & > div {justify-content: space-between; height: 100%;}
    `,
      radius: 0,
      color: 'brand',
      border: {
        radius: 0,
        color: 'brand',
        width: '2px',
      },
      padding: {
        vertical: 'medium',
        horizontal: 'medium',
      },
      background: {
        color: 'white',
      },
    },
  },
  anchor: {
    fontWeight: '100',
    textDecoration: 'underline',
    color: {
      dark: 'white',
      light: 'black',
    },
  },
  heading: {
    weight: 500,
    extend: () => {
      return `
      margin-block-start:0; margin-block-end:0; margin-inline-start:0; margin-inline-end:0;
padding-block-start:0; padding-block-end:0; padding-inline-start:0; padding-inline-end:0;
      text-rendering: optimizeLegibility;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-block: inherit;
      font-display: swap;
    `;
    },
    font: {
      family: "'akzidenz-grotesk',sans-serif",
      transform: 'uppercase',
    },
    level: {
      1: {
        small: {
          size: '44px',
          height: '49px',
          maxWidth: 'none',
        },
        medium: {
          size: '75px',
          height: '80px',
          maxWidth: 'none',
          weight: 500,
        },
        large: {
          size: '132px',
          height: '137px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '191px',
          height: '196px',
          maxWidth: 'none',
        },
      },
      2: {
        small: {
          size: '30px',
          height: '35px',
          maxWidth: 'none',
        },
        medium: {
          size: '36px',
          height: '49px',
          maxWidth: 'none',
        },
        large: {
          size: '59px',
          height: '64px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '75px',
          height: '100px',
          maxWidth: 'none',
          weight: 500,
        },
      },
      3: {
        small: {
          size: '17px',
          height: '22px',
          maxWidth: 'none',
        },
        medium: {
          size: '24px',
          height: '28px',
          maxWidth: 'none',
        },
        large: {
          size: '37px',
          height: '42px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '44px',
          height: '49px',
          maxWidth: 'none',
        },
      },
      4: {
        small: {
          size: '17px',
          height: '22px',
          maxWidth: 'none',
        },
        medium: {
          size: '24px',
          height: '28px',
          maxWidth: 'none',
        },
        large: {
          size: '37px',
          height: '42px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '44px',
          height: '49px',
          maxWidth: 'none',
        },
      },
      5: {
        small: {
          size: '15px',
          height: '16px',
          maxWidth: 'auto',
        },
        medium: {
          size: '15px',
          height: '16px',
          maxWidth: 'auto',
        },
        large: {
          size: '15px',
          height: '16px',
          maxWidth: '227px',
        },
        xlarge: {
          size: '15px',
          height: '16px',
          maxWidth: '227px',
        },
      },
      6: {
        small: {
          size: '8px',
          height: '13px',
          maxWidth: 'auto',
        },
        medium: {
          size: '14px',
          height: '13px',
          maxWidth: 'auto',
        },
        large: {
          size: '8px',
          height: '13px',
          maxWidth: 'auto',
        },
        xlarge: {
          size: '8px',
          height: '13px',
          maxWidth: 'auto',
        },
      },
      7: {
        medium: {
          size: '12px',
          height: '13px',
          maxWidth: 'auto',
        },
      },
    },
  },
  textArea: {
    extend: 'min-height: 170px; padding: 1rem;',
  },
  list: {
    item: {
      pad: 0,
    },
  },
  accordion: {
    border: {
      color: 'transparent',
    },
  },
  select: {
    icons: {
      margin: 'none',
    },
    control: {
      extend: `
        input.lessPad {
          padding-left: 4px;
          padding-right: 4px;
        }
      `,
    },
  },
  tabs: {
    header: {
      extend: `
      & > button {
        display: flex; flex: 1 1 auto;
      }
      & > button > div {
        display: flex; flex: 1 1 auto;
      }`,
    },
  },
  tip: {
    drop: {
      animation: 'none',
      round: 'none',
      background: { color: 'white' },
      border: {
        side: 'all',
        size: 'small',
      },
      width: {
        max: 'medium',
      },
      elevation: 'none',
    },
    content: {
      elevation: 'none',
      background: 'none',
    },
  },
  formField: {
    margin: 'none',
    label: {
      margin: { horizontal: 'none', vertical: 'xsmall' },
    },
    content: {
      pad: 'none',
      margin: 'none',
    },
    border: {
      color: 'transparent',
    },
  },
  page: {
    wide: {
      width: {
        min: 'medium',
        max: 'xlarge',
      },
    },
  },
  checkBox: {
    check: {
      radius: 0,
    },
    border: {
      color: 'black',
    },
  },
};
