import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from 'grommet';
import kebabCase from 'lodash.kebabcase';
import { storyblokEditable } from 'gatsby-source-storyblok';

import DynamicGridItem from './DynamicGridItem';
import ResponsiveDisplay from '../ResponsiveDisplay';
import ResponsiveGrid from '../ResponsiveGrid';
import useMobile from '../useMobile';
import { reverse } from '../../lib/nodash';

import useFillOptions from '../useFillOptions';

const DynamicGrid = ({ blok = { items: [] }, ...rest }) => {
  const {
    columnCount = 1,
    columnWidth = 'auto',
    gap,
    items,
    horizontalAlignment,
    mobileHorizontalAlignment,
    verticalAlignment,
    border = [],
    borderBetween,
    mobileBorderBetween,
    mobileColumnCount,
    mobileGap,
    mobileHorizontalScroll,
    mobileHorizontalScrollColumnWidth = '80vw',
    verticalPad = 'none',
    horizontalPad = 'none',
    mobileReverse = false,
    columnWidths,
    mobileColumnWidths,
    rowHeights,
    fill,
    background,
    backgroundImage,
    hideOnMobile = false,
    hideOnDesktop = false,
    maxHeight,
    minHeight,
    mobileMaxHeight,
    // mobileMinHeight,
    rowsFillContainerHeight,
    stack,
    guidingChild = 0,
    anchor = 'center',
    backgroundRepeat = 'no-repeat',
    backgroundSize = 'cover',
    backgroundPosition = 'center',
    queryParamString,
    anchorId,
    styleProps: stylePropsString,
  } = blok;

  const { fillValue, fillValues } = useFillOptions(fill);
  const isMobile = useMobile();
  const displayAsSlider = isMobile ? mobileHorizontalScroll : false;
  const borders = border.map((x) => ({ side: x, size: 'small' }));
  const colCount = parseInt(columnCount);
  const mobileColCount = mobileColumnCount
    ? parseInt(mobileColumnCount)
    : colCount;

  const baseCols = new Array(items.length).fill(
    displayAsSlider ? mobileHorizontalScrollColumnWidth : 'auto'
  );
  const mobileColumns = displayAsSlider
    ? ['0px', ...baseCols, '1px']
    : mobileColumnWidths
    ? mobileColumnWidths.split(',')
    : { count: mobileColCount, size: columnWidth };
  const columns = columnWidths
    ? columnWidths.split(',')
    : { count: colCount, size: columnWidth };
  const rows = rowHeights ? rowHeights.split(',') : undefined;

  const sortedItems = isMobile && mobileReverse ? reverse(items) : items;
  const styleProps = stylePropsString ? JSON.parse(stylePropsString) : {};

  const xAlign = isMobile
    ? mobileHorizontalAlignment || horizontalAlignment
    : horizontalAlignment;

  return (
    <ResponsiveDisplay
      {...storyblokEditable(blok)}
      mobile={!hideOnMobile}
      desktop={!hideOnDesktop}
      flex={displayAsSlider ? false : true}
      overflow={displayAsSlider ? 'auto' : 'visible'}
      border={borders}
      pad={{ vertical: verticalPad, horizontal: horizontalPad }}
      fill={fillValue}
      background={
        backgroundImage && backgroundImage.filename
          ? {
              image: `url(${backgroundImage.filename})`,
              size: backgroundSize,
              repeat: backgroundRepeat,
              position: kebabCase(backgroundPosition),
            }
          : background
      }
      justify={verticalAlignment}
    >
      {stack && !isMobile ? (
        <Stack
          fill
          anchor={anchor}
          alignSelf="stretch"
          guidingChild={guidingChild}
        >
          {sortedItems.map((x, i) => (
            <DynamicGridItem
              key={x.uuid || x._uid || i}
              blok={{
                horizontalAlignment: xAlign,
                ...x,
                borderBetween: isMobile ? mobileBorderBetween : borderBetween,
                ...rest,
              }}
              totalCols={items.length}
              verticalAlignment={verticalAlignment}
              horizontalAlignment={xAlign}
              {...rest}
              fill={fillValues}
              colCount={isMobile ? mobileColCount : colCount}
              index={i}
            />
          ))}
        </Stack>
      ) : (
        <div
          style={{
            height: fillValues.vertical ? '100%' : 'auto',
            width: fillValues.horizontal ? '100%' : 'auto',
          }}
          id={anchorId}
        >
          <ResponsiveGrid
            fill={fillValue}
            mobileColumns={mobileColumns}
            gap={isMobile ? mobileGap ?? gap : gap}
            className="responsive-grid"
            columns={columns}
            rows={rows}
            height={
              isMobile && mobileMaxHeight
                ? { max: mobileMaxHeight }
                : maxHeight || minHeight
                ? { max: maxHeight, min: minHeight }
                : undefined
            }
            alignContent={verticalAlignment}
            style={
              rowsFillContainerHeight || rest.rowsFillContainerHeight
                ? {
                    gridTemplateRows:
                      rowsFillContainerHeight || rest.rowsFillContainerHeight
                        ? '1fr'
                        : 'auto',
                    ...styleProps,
                  }
                : { minHeight: 'fit-content', ...styleProps }
            }
          >
            {displayAsSlider && <Box />}
            {sortedItems.map((x, i) => (
              <DynamicGridItem
                key={x.uuid || x._uid || i}
                blok={{
                  horizontalAlignment: xAlign,
                  ...x,
                  borderBetween: isMobile ? mobileBorderBetween : borderBetween,
                  queryParamString,
                }}
                index={i}
                colCount={isMobile ? mobileColCount : colCount}
                totalCols={items.length}
                verticalAlignment={verticalAlignment}
                horizontalAlignment={xAlign}
                {...rest}
                fill={fillValue}
              />
            ))}
            {displayAsSlider && <Box />}
          </ResponsiveGrid>
        </div>
      )}
    </ResponsiveDisplay>
  );
};

DynamicGrid.propTypes = {
  blok: PropTypes.shape({
    columnCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    columnWidth: PropTypes.string,
    gap: PropTypes.string,
    items: PropTypes.array,
    horizontalAlignment: PropTypes.oneOf(['start', 'center', 'end']),
    mobileHorizontalAlignment: PropTypes.oneOf(['start', 'center', 'end', '']),
    verticalAlignment: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    border: PropTypes.array,
    borderBetween: PropTypes.bool,
    mobileBorderBetween: PropTypes.bool,
    mobileColumnCount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    mobileHorizontalScroll: PropTypes.bool,
    mobileHorizontalScrollColumnWidth: PropTypes.string,
    verticalPad: PropTypes.string,
    horizontalPad: PropTypes.string,
    mobileReverse: PropTypes.bool,
    columnWidths: PropTypes.string,
    rowHeights: PropTypes.string,
    background: PropTypes.string,
    backgroundImage: PropTypes.object,
    backgroundSize: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundPosition: PropTypes.string,
    fill: PropTypes.array,
    hideOnMobile: PropTypes.bool,
    hideOnDesktop: PropTypes.bool,
    maxHeight: PropTypes.string,
    minHeight: PropTypes.string,
    mobileMaxHeight: PropTypes.string,
    mobileMinHeight: PropTypes.string,
    stack: PropTypes.bool,
    anchor: PropTypes.string,
    rowsFillContainerHeight: PropTypes.bool,
    styleProps: PropTypes.string,
    guidingChild: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    gridTemplateRows: PropTypes.string,
    anchorId: PropTypes.string,
  }),
};

export default memo(DynamicGrid);
