import { createSelector } from 'reselect';

const selectJwt = createSelector(
  (state) => state.industry,
  (industry) => {
    return industry.jwt;
  }
);

export default selectJwt;
