import React, { memo } from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import PropTypes from 'prop-types';

const AnalyticsApplication = () => {
  return (
    <>
      <Script src="https://app.termly.io/resource-blocker/fc0c5244-caf7-446b-a501-861de3cbeb80?autoBlock=on" />
      <Script strategy={ScriptStrategy.idle}>
        {`

  !function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.5.1";
  geq.load("${process.env.GATSBY_GET_EMAILS_ID}");}();
  geq.page();


      `}
      </Script>
      <Script strategy={ScriptStrategy.idle}>{`
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="rOv2RKACmnbsrteHwgWwQG4Y2mfYDblj";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load('${process.env.GATSBY_SEGMENT_WRITE_KEY}');
    analytics.ready(function () {
      if (typeof ga == 'function') {
        ga('require', 'linker');
        ga('linker:autoLink', ['backdrophome.com', 'myshopify.com', 'shopify.com']);
        (window.analyticsReadyFns || []).forEach((fn, i) => {
          fn();
          window.analyticsReadyFns.splice(i, 1)
        })
      }
     });
    analytics.page(document.title);
    // process any analytics tracked before loaded
    if (window.analyticsQueue) {
      window.analyticsQueue.forEach((e, i) => {
        analytics.track(e.action, e.properties, e.options, e.callback)
        window.analyticsQueue.splice(i, 1)
      })
    }
    }}();`}</Script>
      <Script strategy={ScriptStrategy.idle}>{`geq.page();`}</Script>
      <Script src="//container.pepperjam.com/1453776350.js" />
      <Script>
        {`
        function readCookie(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i=0;i < ca.length;i++) {
          	var c = ca[i];
          	while (c.charAt(0)==' ') c = c.substring(1,c.length);
          	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
          }
          return null;
          }

          //Wait 3 seconds for the Pepperjam official script to finish executing
          setTimeout(function(){

          //Grab the pjn-click cookie on the main domain
          var pjn_cookie = readCookie('pjn-click');

          //Don't write null cookies - they taste bad
          if (pjn_cookie !== null) {

          //Write the pjn-click cookie to .domain.com
          document.cookie = "pjn-click=" + pjn_cookie + "; domain=." +
          location.hostname.split('.').reverse()[1] + "." +
          location.hostname.split('.').reverse()[0] + "; path=/"

          }
          }, 3000);
        `}
      </Script>
      <Script strategy={ScriptStrategy.idle}>
        {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.GATSBY_HOTJARD_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      <Script
        strategy={ScriptStrategy.idle}
        src="https://cdn.attn.tv/backdrophome/dtag.js"
      />
    </>
  );
};

AnalyticsApplication.propTypes = {
  pageType: PropTypes.string,
};

export default memo(AnalyticsApplication);
