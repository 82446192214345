import { createSelector } from 'reselect';

const selectActiveHeaderSubMenu = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.activeHeaderSubMenu;
  }
);

export default selectActiveHeaderSubMenu;
