import { createSlice } from '@reduxjs/toolkit';
import { track } from '../../lib/analytics';
import { PRODUCT_LIST_FILTERED } from '../../lib/analytics/segmentActions';

export const colorSlice = createSlice({
  name: 'color',
  initialState: {
    colors: [],
    colorWheel: [],
    isInitialized: false,
    filterQuery: null,
    filterTags: [],
    sorts: [],
    arrangement: [],
  },
  reducers: {
    setColors: (state, action) => {
      state.colors = action.payload;
      state.isInitialized = true;
    },
    setFilterQuery: (state, action) => {
      state.filterQuery = action.payload;
    },
    setFilterTags: (state, action) => {
      state.filterTags = action.payload;
      if (action.payload.length) {
        track(PRODUCT_LIST_FILTERED, {
          filters: [
            {
              type: 'color',
              value: state.filterTags.map((x) => x.name).join(', '),
            },
          ],
        });
      }
    },
    setSorts: (state, action) => {
      state.sorts = action.payload;
    },
    toggleFilterTag: (state, action) => {
      const existing = state.filterTags.findIndex((tag) =>
        action.payload._uid
          ? tag._uid === action.payload._uid
          : tag.id === action.payload.id
      );
      if (existing === -1) {
        state.filterTags.push(action.payload);
      } else {
        state.filterTags = state.filterTags.filter((tag) => {
          if (tag._uid) {
            return tag._uid !== action.payload._uid;
          } else {
            return tag.id !== action.payload.id;
          }
        });
      }
      track(PRODUCT_LIST_FILTERED, {
        filters: [
          {
            type: 'color',
            value: state.filterTags.map((x) => x.name).join(', '),
          },
        ],
      });
    },
    toggleSort: (state, action) => {
      const existing = state.sorts.findIndex(
        (sort) =>
          sort.id === action.payload.id || sort.id === action.payload._uid
      );
      if (existing === -1) {
        state.sorts.push(action.payload);
      } else {
        state.sorts = state.sorts.filter(
          (sort) => sort.id !== action.payload.id
        );
      }
    },
    resetFilterTags: (state) => {
      state.filterTags = [];
    },
    setColorWheel: (state, action) => {
      state.colorWheel = action.payload;
    },
    setArrangement: (state, action) => {
      state.arrangement = action.payload;
    },
  },
});

export const {
  setColors,
  setColorWheel,
  setArrangement,
  setFilterQuery,
  setFilterTags,
  setSorts,
  toggleSort,
  toggleFilterTag,
  resetFilterTags,
} = colorSlice.actions;

export default colorSlice.reducer;
