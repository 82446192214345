import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Grommet, Box, Button } from 'grommet';
import Headroom from 'react-headroom';
import 'lazysizes';
import loadable from '@loadable/component';
import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

import theme from './theme';
import './style.css';
import Favicon from './Favicon';
import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import LayoutMain from './LayoutMain';
import PageOverlay from './PageOverlay';
import SmartLink from './SmartLink';
import Logo from './Logos/Logo';

import ModalApplication from './ModalApplication';
import MembersApplication from './Industry/MembersApplication';
import AnalyticsApplication from './AnalyticsApplication';
import CampaignsApplication from './Campaigns/CampaignsApplication';
import Cart from './Cart/Cart';
import GlobalAnnouncementBar from './Announcements/GlobalAnnouncementBar';
import AudioToggle from './AudioToggle';
import { initStoryblok, useStoryblok } from '../lib/storyblok';
import { setErrors, setOpen } from '../state/cart/cartSlice';
import useLocationUi from './useLocationUi';
import { setNavPinned, setScrolledToTop } from '../state/ui/uiSlice';
import { selectHasAudio } from '../state/ui/audioSelectors';
import { CookieAcknowledgement } from './CookieAcknowledgement';

const ToastsApplication = loadable(() => import('./ToastsApplication'));
const ModalUgcDisplay = loadable(() => import('./ModalUgcDisplay'));
const ModalCartError = loadable(() => import('./Cart/ModalCartError'));
const ModalProductBuyOptions = loadable(() =>
  import('./Product/ModalProductBuyOptions')
);

initStoryblok();

const HeaderContainer = styled(Box)``;

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family:"akzidenz-grotesk";
  src:url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-style:normal;font-weight:500;font-stretch:normal;
  font-display: swap;
  }

  @font-face {
  font-family:"akzidenz-grotesk";
  src:url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
  }

  * { font-family: "akzidenz-grotesk", Helvetica, Arial, sans-serif; }

  .lazyload, .lazyloading {
    opacity: 0;
    transition: opacity 200ms;
  }

  img.lazyloaded {
    opacity: 1;
    transition: opacity 200ms;
  }

  .swipe-slide {
    trasnform: translateZ(0);
  }
  .swiper-wrapper {
   -webkit-transform-style: preserve-3d;
  }

`;

const LogoContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1000;
`;

const LogoContainerInner = styled(Button)`
  width: 80px;
  padding: 15px;
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    width: 158px;
    padding: 20px;
  }
`;

const Layout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const scrolledToTop = useSelector((state) => state.ui.scrolledToTop);
  const announcementBarOpen = useSelector(
    (state) => state.ui.announcementBarOpen
  );
  const hasAudio = useSelector(selectHasAudio);

  const mainMenuActive = useSelector((state) => state.ui.mainMenuActive);

  useStoryblok();

  useLocationUi(location);
  const dark = useSelector((state) => state.ui.isDark);
  const activeGalleryPlacement = useSelector(
    (state) => state.gallery.activePlacementId
  );
  const cartErrors = useSelector((state) => state.cart.errors);
  const productForSelection = useSelector(
    (state) => state.product.productForSelection
  );

  useScrollPosition(
    ({ currPos }) => {
      const isAtTop = currPos.y > -40;

      if (!scrolledToTop && isAtTop) {
        dispatch(setScrolledToTop(true));
        return;
      }
      if (scrolledToTop && !isAtTop) {
        dispatch(setScrolledToTop(false));
        return;
      }
    },
    [scrolledToTop]
  );

  useEffect(() => {
    if (location.state?.cartOpen) {
      dispatch(setOpen(true));
    }
    dispatch(setErrors({}));
  }, [location.state]);

  return (
    <Grommet theme={theme}>
      <GlobalStyle />
      <Helmet>
        <meta
          name="google-site-verification"
          content="ydjDpb_e4Nq_NMBGw1T0pdufyt2DVSQ4jfEjEPnoWmg"
        />
        <meta
          name="facebook-domain-verification"
          content="88xqa8aa29qtnx6dj2szg2iaa1b6sy"
        />
        {location.pathname === '/compass' && (
          <meta name="robots" content="noindex" />
        )}
        <link
          rel="preload"
          href="https://p.typekit.net/p.css?s=1&k=niq1pzc&ht=tk&f=19121.19123&a=12819400&app=typekit&e=css"
          as="style"
        />
        <link
          href="https://p.typekit.net/p.css?s=1&k=niq1pzc&ht=tk&f=19121.19123&a=12819400&app=typekit&e=css"
          rel="stylesheet"
        />
      </Helmet>
      <Favicon />
      <Box
        className={mainMenuActive ? 'menu-active' : void 0}
        style={{ position: 'relative' }}
      >
        <Headroom
          style={{ zIndex: 1003 }}
          onPin={() => dispatch(setNavPinned(true))}
          onUnpin={() => dispatch(setNavPinned(false))}
          className={mainMenuActive ? 'menu-active' : void 0}
        >
          <GlobalAnnouncementBar />
          <HeaderContainer
            fill="horizontal"
            announcementBarOpen={announcementBarOpen}
          >
            <Box style={{ position: 'relative' }}>
              <SiteHeader location={location} />
            </Box>
          </HeaderContainer>
          {hasAudio && !mainMenuActive && (
            <Box style={{ position: 'absolute', left: 0, zIndex: 0 }}>
              <AudioToggle />
            </Box>
          )}
        </Headroom>
        <Box>
          {location.pathname !== '/' && (
            <LogoContainer className="logo-container">
              <LogoContainerInner className="logo-container-inner" pad="medium">
                <SmartLink to="/" a11yTitle="Backdrop Homepage">
                  <Logo fill={dark ? 'white' : 'black'} />
                </SmartLink>
              </LogoContainerInner>
            </LogoContainer>
          )}
          <LayoutMain width={{ max: '100vw' }}>{children}</LayoutMain>
        </Box>
        {activeGalleryPlacement && <ModalUgcDisplay />}
        {cartErrors && cartErrors.length > 0 && <ModalCartError />}
        {productForSelection && <ModalProductBuyOptions />}
        <Cart />
        <ModalApplication />
        <ToastsApplication />
        <MembersApplication />
        <CampaignsApplication location={location} />
        <PageOverlay />
        {location.pathname !== '/pages/gallery' &&
          location.pathname !== '/pages/gallery/' && (
            <SiteFooter location={location} />
          )}
      </Box>
      <AnalyticsApplication />
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  dark: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

export default memo(Layout);
