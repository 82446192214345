import calculateCartDiscounts from './calculateCartDiscounts';
import { reduce, get, filter } from '../nodash';

const calculateSavedOnPaint = (items) => {
  const paintItems = filter(calculateCartDiscounts.isPaint, items);
  return reduce(
    (mem, x) => {
      return (
        mem +
        reduce(
          (mem2, y) => {
            return mem2 + parseFloat(get('allocatedAmount.amount', y));
          },
          0,
          get('node.discountAllocations', x)
        )
      );
    },
    0,
    paintItems
  );
};

export default calculateSavedOnPaint;
