import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, ThemeContext } from 'grommet';

import HeaderMenuItem from './HeaderMenuItem';
import HeaderMenuComponent from './HeaderMenuComponent';

const MobileHeaderSubMenu = ({ title, link, items, ...rest }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box>
      <HeaderMenuItem
        text={title}
        link={link}
        headingLevel={3}
        style={{
          padding: `${theme.global.edgeSize.small} ${theme.global.edgeSize.medium}`,
        }}
      />
      <Box pad={{ horizontal: 'medium', bottom: 'medium' }}>
        {items.map((x, i) => {
          const component =
            x.component === 'MenuSection'
              ? 'MenuSection'
              : x.component === 'MenuItem'
              ? 'SubMenuItem'
              : 'SubMenu';
          return (
            <HeaderMenuComponent
              key={x._uid}
              {...x}
              component={component}
              last={items.length - 1 === i}
              parent={rest}
              sub={true}
              index={i}
            />
          );
        })}
      </Box>
    </Box>
  );
};

MobileHeaderSubMenu.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.object,
  items: PropTypes.array,
};

export default MobileHeaderSubMenu;
