import React, { useMemo, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Heading } from 'grommet';
import {
  Instagram,
  Pinterest,
  Spotify,
  FacebookOption,
  Twitter,
} from 'grommet-icons';
import styled from 'styled-components';
import loadable from '@loadable/component';

import MenuFooter from './MenuFooter';
import SmartLink from './SmartLink';
import IconLogo from './Icons/IconLogo';
import ImgixImage from './ImgixImage';
import useMobile from './useMobile';

const FormKlaviyoSignup = loadable(() => import('./Klaviyo/FormKlaviyoSignup'));

const SignupContainer = styled(Box)`
  form.klaviyo-form {
    display: none;
    input {
      padding-left: 0 !important;
    }
  }
`;

const Footer = ({ location }) => {
  const isMobile = useMobile();
  const currentYear = useMemo(() => new Date().getFullYear());
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <Box
      className="site-footer"
      background="black"
      pad={
        isMobile
          ? { horizontal: 'large', top: 'large', bottom: '100px' }
          : { horizontal: '70px', vertical: 'large' }
      }
    >
      <Box margin={{ vertical: 'large' }}>
        <IconLogo size="145px" />
      </Box>
      <MenuFooter location={location} />
      <Box gap="large" margin={{ vertical: 'xlarge' }}>
        <Box
          justify="between"
          direction="row-responsive"
          gap="medium"
          flex={true}
          align="end"
        >
          <SignupContainer
            className="mailing-list"
            flex={true}
            width={isMobile ? '100%' : { max: `calc(100% * (4 / 12))` }}
          >
            <Heading level={4} style={{ textTransform: 'none' }}>
              Stay in the loop.
            </Heading>
            {loaded && (
              <FormKlaviyoSignup
                formId="TkiVn3"
                style={{ borderBottom: '2px solid #fff' }}
              />
            )}
          </SignupContainer>
          <Box
            className="climate-neutral"
            direction="row"
            justify="center"
            align="center"
            gap="medium"
          >
            <Box width="100px">
              <ImgixImage
                src="https://a.storyblok.com/f/128720/800x372/a65436a533/climate-neutral-white-outline.png"
                srcSetOptions={{ w: 100, auto: ['format', 'compress'] }}
                style={
                  isMobile
                    ? { width: '66px', height: '30.6875px' }
                    : { height: '47px', width: '100px' }
                }
                alt="Climate Neutral Certified logo"
                lazy={true}
              />
            </Box>
            <Text style={{ letterSpacing: '1px' }}>
              Backdrop is a Climate Neutral Certified Company
            </Text>
          </Box>
        </Box>
        <Box direction="row-responsive">
          <Box direction="row" gap="large">
            <SmartLink to="https://www.instagram.com/backdrop/" taget="_blank">
              <Instagram style={{ strokeWidth: 0 }} />
            </SmartLink>
            <SmartLink to="https://www.pinterest.com/backdrop/" taget="_blank">
              <Pinterest style={{ strokeWidth: 0 }} />
            </SmartLink>
            <SmartLink
              to="https://open.spotify.com/user/30omy87tmx717vpqdlej1ko2i"
              taget="_blank"
            >
              <Spotify />
            </SmartLink>
            <SmartLink
              to="https://www.facebook.com/BackdropBackdropBackdrop/"
              taget="_blank"
            >
              <FacebookOption />
            </SmartLink>
            <SmartLink to="https://twitter.com/Backdrop_Home" taget="_blank">
              <Twitter />
            </SmartLink>
          </Box>
        </Box>
        <Box
          direction="row-responsive"
          align="center"
          justify="between"
          gap="medium"
        >
          <Box>
            <Text size="xsmall" style={{ letterSpacing: '1px' }}>
              Backdrop Inc &reg; Backdrop {currentYear}, a division of F.
              Schumacher & Co. All Rights Reserved.
            </Text>
          </Box>
          <Box direction="row" gap="medium">
            <SmartLink plain to="/pages/privacy-policy">
              <Text size="xsmall">Privacy Policy</Text>
            </SmartLink>
            <SmartLink plain to="/pages/terms-of-service">
              <Text size="xsmall">Terms of Use</Text>
            </SmartLink>
            <SmartLink plain to="/pages/warranty">
              <Text size="xsmall">Warranty</Text>
            </SmartLink>
            <SmartLink plain to="/pages/return-policy">
              <Text size="xsmall">Return Policy</Text>
            </SmartLink>
            <a
              href="#"
              className="termly-display-preferences"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Text size="xsmall">Consent Preferences</Text>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  location: PropTypes.object,
};

export default memo(Footer);
