import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  height: auto;
  border-top: ${(p) =>
    p.hasBorder ? `${p.theme.global.borderSize.small} solid` : 'none'};
  border-left: none;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    border-top: none;
    border-left: ${(p) =>
      p.hasBorder ? `${p.theme.global.borderSize.small} solid` : 'none'};
    padding: ${(p) => p.theme.global.edgeSize[p.padSize]};
  }
`;

import HeaderMenuComponent from './HeaderMenuComponent';

const HeaderMenuSection = ({ items = [], index, ...rest }) => {
  const hasBorder = index > 0;
  const hasImages = items.some((item) => {
    return item?.image?.filename;
  });
  const padSize = hasImages ? 'large' : 'xlarge';
  return (
    <StyledBox gap="large" hasBorder={hasBorder} fill padSize={padSize}>
      {items.map((item) => {
        const component =
          item.component === 'Menu'
            ? 'SectionMenu'
            : item.component === 'MenuItem'
            ? 'SectionMenuItem'
            : item.component;
        return (
          <HeaderMenuComponent
            key={item._uid}
            {...item}
            {...rest}
            component={component}
          />
        );
      })}
    </StyledBox>
  );
};

HeaderMenuSection.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  items: PropTypes.array,
  _uid: PropTypes.string,
};

export default memo(HeaderMenuSection);
