import { gql } from '@apollo/client';

export const CART_LINE_ITEMS_FRAGMENT = gql`
  fragment CartLineItems on Checkout {
    lineItems(first: 50) {
      edges {
        node {
          __typename
          id
          quantity
          title
          discountAllocations {
            allocatedAmount {
              amount
            }
            discountApplication {
              allocationMethod
              targetSelection
              targetType
              value {
                __typename
                ... on PricingPercentageValue {
                  percentage
                }
                ... on MoneyV2 {
                  amount
                }
              }
            }
          }
          variant {
            id
            title
            currentlyNotInStock
            product {
              productType
              id
              title
              tags
              handle
              out_of_stock_message: metafield(
                namespace: "accentuate"
                key: "out_of_stock_message"
              ) {
                key
                value
                namespace
              }
            }
            priceV2 {
              amount
            }
            image {
              thumb: url(transform: { maxWidth: 150, maxHeight: 150 })
              original: url
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CHECKOUT = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const GET_CHECKOUT = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  query Checkout($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Checkout {
        id
        webUrl
        completedAt
        customAttributes {
          key
          value
        }
        discountApplications(first: 3) {
          edges {
            node {
              targetSelection
              allocationMethod

              targetType
              value {
                __typename
                ... on PricingPercentageValue {
                  percentage
                }
                ... on MoneyV2 {
                  amount
                }
              }
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        ...CartLineItems
      }
    }
  }
`;

export const CHECKOUT_LINE_ITEMS_UPDATE = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        id
        lineItemsSubtotalPrice {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        ...CartLineItems
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CHEKOUT_LINE_ITEMS_REMOVE = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        id
        lineItemsSubtotalPrice {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        ...CartLineItems
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CHECKOUT_LINE_ITEMS_ADD = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        __typename
        id
        webUrl
        lineItemsSubtotalPrice {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        ...CartLineItems
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const APPLY_DISCOUNT_CODE = gql`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        id
        discountApplications(first: 3) {
          edges {
            node {
              allocationMethod
              targetType
              targetSelection
              value {
                __typename
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_CHECKOUT_ATTRIBUTES = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        id
        customAttributes {
          value
          key
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
