import { createSlice } from '@reduxjs/toolkit';

export const wallcoveringsSlice = createSlice({
  name: 'wallcoverings',
  initialState: {
    filterPanelActive: false,
    activeFilterCategories: [0],
  },
  reducers: {
    toggleFilterPanel: (state) => {
      state.filterPanelActive = !state.filterPanelActive ? true : false;
    },
    closeFilterPanel: (state) => {
      state.filterPanelActive = false;
    },
    setActiveFilterCategories: (state, action) => {
      state.activeFilterCategories = action.payload;
    },
  },
});

export const {
  toggleFilterPanel,
  closeFilterPanel,
  setActiveFilterCategories,
} = wallcoveringsSlice.actions;

export default wallcoveringsSlice.reducer;
