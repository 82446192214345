import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';

const ResponsiveDisplay = styled(Box)`
  min-height: fit-content;
  ${(p) => (p.mobile === false ? 'display: none;' : 'display: inherit;')}

  @media only screen ${(p) =>
    `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    ${(p) => (p.desktop === false ? 'display: none;' : 'display: inherit;')}
  }
`;

ResponsiveDisplay.propTypes = {
  mobile: PropTypes.bool,
  desktop: PropTypes.bool,
  children: PropTypes.object,
};

export default ResponsiveDisplay;
