import React, { memo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ThemeContext } from 'grommet';

import useMobile from './useMobile';
import selectActiveHeaderSubMenu from '../state/ui/selectActiveHeaderSubMenu';
import { setMainMenuActive } from '../state/ui/uiSlice';
import { setOpen } from '../state/cart/cartSlice';
import { LazyMotion, m } from 'framer-motion';

const MotionBox = m(Box);

const loadFeatures = () =>
  import('../lib/framer/motionFeatures.js').then((res) => res.default);

const PageOverlay = () => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const activeSubMenuId = useSelector(selectActiveHeaderSubMenu);
  const mainMenuActive = useSelector((state) => state.ui.mainMenuActive);
  const cartActive = useSelector((state) => state.cart.open);
  const mobileActive = isMobile && mainMenuActive;
  const active = activeSubMenuId || mobileActive || cartActive;
  const announcementBarSize = useSelector(
    (state) => state.ui.announcementBarSize
  );
  const offset = cartActive
    ? 0
    : parseInt(theme.global.mobileMenuHeight) +
      parseInt(announcementBarSize) +
      parseInt(theme.global.borderSize.small);

  const variants = {
    active: {
      pointerEvents: 'all',
      opacity: 0.6,
      zIndex: cartActive ? 1003 : 1001,
      transition: {
        type: 'tween',
        duration: 0.2,
      },
    },
    inactive: {
      pointerEvents: 'none',
      opacity: 0,
      zIndex: cartActive ? 1003 : 1001,
      transition: {
        type: 'tween',
        duration: 0.2,
      },
    },
  };
  return (
    <LazyMotion strict features={loadFeatures}>
      <MotionBox
        className="page-overlay"
        style={{
          position: 'fixed',
          WebkitTapHighlightColor: 'transparent',
          top: `${offset}px`,
        }}
        initial="inactive"
        variants={variants}
        animate={active ? 'active' : 'inactive'}
        height="100vh"
        width="100vw"
        background="white"
        focusIndicator={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setMainMenuActive());
          dispatch(setOpen(false));
        }}
      />
    </LazyMotion>
  );
};

export default memo(PageOverlay);
