import { useMutation } from '@apollo/client';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import gql from 'graphql-tag';

import { get } from '../../lib/nodash';
import checkLimits from '../../lib/cart/checkLimits';
import {
  setErrors as reduxSetErrors,
  setAdjusting as reduxSetAdjusting,
} from '../../state/cart/cartSlice';

import { CHECKOUT_LINE_ITEMS_UPDATE } from '../../queries/cartQueries';

function useUpdateLineItems() {
  const dispatch = useDispatch();
  const checkoutId = useSelector((state) => state.cart.checkoutId);
  const [updateLineItems, { loading }] = useMutation(
    CHECKOUT_LINE_ITEMS_UPDATE,
    {
      client: shopifyClient,
    }
  );

  const adjustLineItemQuantity = async (
    lineItemId,
    variantId,
    quantity,
    isAbsoluteQty = false
  ) => {
    // TODO: debounce and collect events and batch them into one call
    // to allow for faster perceived qty adjustment
    dispatch(reduxSetAdjusting(lineItemId));
    try {
      const { toChange, errors } = await checkLimits(
        [{ variantId, quantity, id: lineItemId }],
        checkoutId,
        isAbsoluteQty
      );
      if (errors.length > 0) {
        dispatch(reduxSetErrors(errors));
      }

      await updateLineItems({
        variables: { lineItems: toChange, checkoutId },
        update: (cache, { data }) => {
          const checkout = get('checkoutLineItemsUpdate.checkout', data);

          cache.writeFragment({
            id: cache.identify(checkout),
            fragment: gql`
              fragment CheckoutLineItemsUpdateFragment on Checkout {
                lineItems
                lineItemsSubtotalPrice
                subtotalPriceV2
              }
            `,
            data: checkout,
          });
        },
      });
      dispatch(reduxSetAdjusting(null));
    } catch (e) {
      dispatch({ type: 'SET_ERROR', payload: e });
    }
  };

  return { updateLineItems, loading, adjustLineItemQuantity };
}

export default useUpdateLineItems;
