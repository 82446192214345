import { get, find, map, filter, isEqual, sum } from '../nodash';
import extractGid from '../extractGid';
import shopifyGetGraphQlId from '../shopifyGetGraphQlId';
import { isSample } from '../product';

const TAPE_VARIANT_ID = 15212869124194;
const TAPE_LIMIT = 2;
const SAMPLE_LIMIT = 15;
const GIFT_CARD_LIMIT = 1;

export const LIMITS = [
  {
    id: TAPE_VARIANT_ID,
    limit: TAPE_LIMIT,
  },
  {
    id: 18851676913762, //$50
    limit: GIFT_CARD_LIMIT,
  },
  {
    id: 18851683172450, //$75
    limit: GIFT_CARD_LIMIT,
  },
  {
    id: 18851787767906, //$100
    limit: GIFT_CARD_LIMIT,
  },
  {
    id: 18851788619874, //$150
    limit: GIFT_CARD_LIMIT,
  },
  {
    id: 18851789176930, //$200
    limit: GIFT_CARD_LIMIT,
  },
  {
    id: 32025966903394, //$250
    limit: GIFT_CARD_LIMIT,
  },
];

export const getVariantCartLimit = (variantId) => {
  return get(
    'limit',
    find((x) => {
      return isEqual(
        extractGid(shopifyGetGraphQlId('ProductVariant', get('id', x))),
        extractGid(variantId)
      );
    }, LIMITS)
  );
};

const overSampleLimit = (cartLineItems, variantData, quantity) => {
  const samplesInCart = filter(isSample, cartLineItems);
  const sampleCount = sum(map('quantity', samplesInCart));
  const variantIsSample = isSample(variantData);
  if (quantity === 0 || !variantIsSample) {
    return false;
  }
  return sampleCount + quantity > SAMPLE_LIMIT;
};

const checkVariantCartLimits = (
  { inCart, quantity, variantData, inventoryPolicy },
  cartLineItems
) => {
  const qtyAdjustment = quantity - inCart;
  if (
    qtyAdjustment > 0 &&
    overSampleLimit(cartLineItems, variantData, qtyAdjustment)
  ) {
    return {
      title: 'Less is More :)',
      body: `We love that you're excited about your new backdrop, but more isn't always better. We have a limit of ${SAMPLE_LIMIT} samples per order. If you need help narrowing things down, we also have a fan deck. Any questions, shoot us a note at <a target="_blank" rel="noopener noreferrer" href="mailto:hello@backdrophome.com">hello@backdrophome.com</a>!`,
      ctas: [
        {
          label: 'Get a Fan Deck',
          href: '/products/fandeck',
          secondary: true,
        },
        {
          label: 'Get a Color Card',
          href: '/products/color-card',
          secondary: true,
        },
      ],
    };
  }

  if (
    qtyAdjustment > 0 &&
    qtyAdjustment > variantData.quantityAvailable &&
    !variantData.currentlyNotInStock &&
    variantData.availableForSale === false &&
    inventoryPolicy !== 'CONTINUE' &&
    variantData.product.productType !== 'Color Consultation' &&
    variantData.product.productType !== 'Giftcard'
  ) {
    return {
      type: 'out-of-stock',
      meta: {
        variantId: variantData.id,
        variantTitle: variantData.title,
        productTitle: variantData.product.title,
        isSample: isSample(variantData),
      },
    };
  }

  const limited = find(
    {
      id: extractGid(get('id', variantData), 'ProductVariant'),
    },
    LIMITS
  );

  const isOverLimit = limited && quantity > limited.limit;

  if (!isOverLimit) {
    return null;
  }

  switch (extractGid(get('id', variantData), 'ProductVariant')) {
    case TAPE_VARIANT_ID:
      return {
        body: `Due to high demand for painter’s tape we’re currently limiting to ${TAPE_LIMIT} roll${
          TAPE_LIMIT > 1 ? 's' : ''
        } per order. If you have any questions, don’t hesitate to send us a note at hello@backdrophome.com.`,
      };
    default:
      return {
        body: `Limit ${limited.limit} per order`,
      };
  }
};

export default checkVariantCartLimits;
