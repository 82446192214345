import { Text } from 'grommet';
import styled from 'styled-components';

const TextTitle = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: ${(p) => p.theme.text[p.size ?? 'small'].size};
  text-align: ${(p) => (p.center ? 'center' : 'left')};
  
  @media only screen ${(p) =>
    p.theme.global.breakpoints.small.value &&
    `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
      font-size: ${(p) => p.theme.text.small.medium};
  };
}
`;

export default TextTitle;
