import gql from 'graphql-tag';

export const CONTENT_NODES_BY_UID = gql`
  query ContentNodesByUid($id: String!) {
    ContentNodes(by_uuids: $id) {
      items {
        uuid
        content
        full_slug
        name
      }
    }
  }
`;

export const CONTENT_NODES_BY_FULL_SLUG = gql`
  query ContentNodesByFullSlug($full_slug: String!) {
    ContentNodes(by_slugs: $full_slug) {
      items {
        uuid
        content
        full_slug
        name
        id
      }
    }
  }
`;

export const CONTENT_NODE = gql`
  query ContentNodeById($id: ID!) {
    ContentNode(id: $id) {
      full_slug
      id
      content
      uuid
      slug
      name
      path
    }
  }
`;

export const CAMPAIGNS = gql`
  {
    CampaignItems {
      items {
        content {
          body
          component
          generateCode
          priceRuleId
          codePrefix
          image {
            alt
            filename
            title
          }
          mailingListCta
          mailingListId
          title
          utmSource
        }
        full_slug
        id
        uuid
        name
        slug
      }
    }
  }
`;

export const CAMPAIGN_BY_QUERY = gql`
  query FindCampaignItemBySource($query: CampaignFilterQuery) {
    wildcards: CampaignItems(
      filter_query_v2: { utmSource: { like: "*...*" } }
    ) {
      items {
        content {
          body
          component
          generateCode
          priceRuleId
          codePrefix
          rememberClose
          global
          image {
            alt
            filename
            title
          }
          media
          mailingListCta
          mailingListId
          mailingListSuccessBody
          mailingListSubscriptionThreshold
          mailingListSuccessBodyUnder
          mailingListSuccessBodyOver
          mailingListSubscriptionRequirement
          title
          utmSource
          emailRequiredForDiscount
          segmentRequirement
          segmentId
        }
        full_slug
        id
        uuid
        name
        slug
      }
    }

    matches: CampaignItems(filter_query_v2: $query) {
      items {
        content {
          body
          component
          generateCode
          priceRuleId
          codePrefix
          rememberClose
          global
          image {
            alt
            filename
            title
          }
          media
          mailingListCta
          mailingListId
          mailingListSuccessBody
          mailingListSubscriptionThreshold
          mailingListSuccessBodyUnder
          mailingListSuccessBodyOver
          mailingListSubscriptionRequirement
          title
          emailRequiredForDiscount
          segmentRequirement
          segmentId
          utmSource
        }
        full_slug
        id
        uuid
        name
        slug
      }
    }
  }
`;
