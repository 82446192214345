import _isEqual from 'lodash.isequal';

export const get = (path = '', obj, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const head = (collection = []) =>
  Array.isArray(collection) ? collection[0] : undefined;
export const tail = (arr = []) => {
  const [, ...t] = arr || [];
  return t;
};
export const take = (qty = 1, arr = []) => [...(arr || [])].splice(0, qty);
export const slice = (pos, length, input) => input.slice(pos, length);

export const sample = (arr = []) => {
  const len = arr == null ? 0 : arr.length;
  return len ? arr[Math.floor(Math.random() * len)] : undefined;
};

export const compact = (collection = []) => (collection || []).filter(Boolean);
export const uniq = (array = []) => [...new Set(array || [])];
export const uniqBy = (predicate, arr) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  return [
    ...(arr || [])
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};
export const intersection = (arr = [], ...args) =>
  arr.filter((item) => args.every((arr = []) => (arr || []).includes(item)));
export const difference = (a, b) => a.filter((x) => !b.includes(x));
export const sortBy = (key, array) => {
  return array.concat().sort((a, b) => {
    return isFunction(key)
      ? key(a) > key(b)
        ? 1
        : key(a) < key(b)
        ? -1
        : 0
      : a[key] > b[key]
      ? 1
      : a[key] < b[key]
      ? -1
      : 0;
  });
};
export const groupBy = (iteratee = (x) => x, collection = []) => {
  const it =
    typeof iteratee === 'function' ? iteratee : ({ [iteratee]: prop }) => prop;

  const array = Array.isArray(collection)
    ? collection
    : Object.values(collection);

  return array.reduce((r, e) => {
    const k = it(e);

    r[k] = r[k] || [];

    r[k].push(e);

    return r;
  }, {});
};

export const includes = (value, collection = []) => {
  return (collection || []).includes(value);
};
export const contains = (value, source = '') => {
  const isNumber = !Number.isNaN(source);
  return (isNumber ? String(source) : source || '').indexOf(value) > -1;
};
export const some = (comparator, collection = []) => {
  const func = getComparatorMatcher(comparator);
  return Array.isArray(collection) ? collection.some(func) : false;
};
export const isEqual = _isEqual;
export const isFunction = (func) => {
  return typeof func === 'function';
};
export const isArray = (x) => Array.isArray(x);
export const isObject = (a) => a instanceof Object;
export const isString = (str) => {
  if (str != null && typeof str.valueOf() === 'string') {
    return true;
  }
  return false;
};
export const isNil = (a) => a == null;
export const isUndefined = (a) => typeof a === 'undefined';

export const concat = (newVal, collection) => collection.concat(newVal);

export const pick = (keys, object) => {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

const getComparatorMatcher = (comparator) => {
  const func = isFunction(comparator)
    ? comparator
    : (item) => {
        return every(
          (x) => x === true,
          keys(comparator).map((k) => item[k] === comparator[k])
        );
      };
  return func;
};

export const filter = (comparator, collection = []) => {
  const func = getComparatorMatcher(comparator);
  return (collection || []).filter(func);
};
export const find = (comparator, collection = []) => {
  const func = getComparatorMatcher(comparator);
  return (collection || []).find(func);
};
export const reject = function (predicate, arr = []) {
  const func = getComparatorMatcher(predicate);
  var complement = function (f) {
    return function (x) {
      return typeof f === 'function' ? !f(x) : x;
    };
  };
  return (arr || []).filter(complement(func));
};

export const every = (comparator, collection) => collection.every(comparator);

export const map = (operator, collection = []) => {
  return (Array.isArray(collection) ? collection : []).map((v) =>
    typeof operator === 'string' ? get(operator, v) : operator(v)
  );
};

export const reduce = (operator, initial, collection = []) =>
  collection.reduce(
    (mem, v) =>
      typeof operator === 'string' ? get(operator, v) : operator(mem, v),
    initial
  );

export const drop = (n = 1, arr = []) => (arr || []).slice(n);
export const dropRight = (n = 1, arr = []) =>
  (arr || []).slice(0, -n || arr.length);

export const mapKeys = (mapper, obj = {}) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [mapper(key, value)]: value,
    }),
    {}
  );
};

export const values = (object) => Object.values(object || {});
export const keys = (object) => Object.keys(object || {});
export const omit = (props = [], obj = {}) => {
  const newObj = { ...obj };
  (props || []).forEach((prop) => delete newObj[prop]);
  return newObj;
};
export const omitBy = (check, obj) => {
  obj = { ...obj };
  Object.entries(obj).forEach(
    ([key, value]) => check(value) && delete obj[key]
  );
  return obj;
};
export const pickBy = (comparator, object) => {
  const obj = {};
  for (const key in object) {
    if (comparator(object[key])) {
      obj[key] = object[key];
    }
  }
  return obj;
};

export const flatten = (collection = []) => (collection || []).flat();
export const reverse = (collection = []) => [...(collection || [])].reverse();
export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const toUpper = (s = '') => (s || '').toUpperCase();
export const upperCase = toUpper;
export const startCase = (text = '') =>
  (text || '')
    .replace(/-/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
export const titleCase = (text = '') =>
  (text || '')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
export const toLower = (text = '') => (text || '').toLowerCase();
export const lowerCase = (text = '') => (text || '').toLowerCase();
export const trim = (str, c = '\\s') =>
  str.replace(new RegExp(`^([${c}]*)(.*?)([${c}]*)$`), '$2');

export const trimEnd = (str = '', c = '\\s') =>
  (str || '').replace(new RegExp(`^(.*?)([${c}]*)$`), '$1');

export const replace = (target, replacement, source = '') =>
  (source || '').replace(target, replacement);

export const merge = (a, b) => ({ ...a, ...b });

export const sum = (collection = []) =>
  (collection || []).reduce((sum, n) => sum + n, 0);

export const range = (start, end, increment) => {
  // if the end is not defined...
  const isEndDef = typeof end !== 'undefined';
  // ...the first argument should be the end of the range...
  end = isEndDef ? end : start;
  // ...and 0 should be the start
  start = isEndDef ? start : 0;

  // if the increment is not defined, we could need a +1 or -1
  // depending on whether we are going up or down
  if (typeof increment === 'undefined') {
    increment = Math.sign(end - start);
  }

  // calculating the lenght of the array, which has always to be positive
  const length = Math.abs((end - start) / (increment || 1));

  // In order to return the right result, we need to create a new array
  // with the calculated length and fill it with the items starting from
  // the start value + the value of increment.
  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      // append the current value to the result array
      result: [...result, current],
      // adding the increment to the current item
      // to be used in the next iteration
      current: current + increment,
    }),
    { current: start, result: [] }
  );

  return result;
};

export const shuffle = (array = []) => {
  let currentIndex = (array || []).length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const multiply = (a, b) => a * b;
export const add = (a, b) => a + b;
export const subtract = (a, b) => a - b;
export const divide = (a, b) => a / b;

export const identity = (a) => a;
export const last = (collection = []) =>
  (collection || [])[(collection || []).length - 1];

export const throttle = (func, timeFrame) => {
  var lastTime = 0;
  return function (...args) {
    var now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
};
