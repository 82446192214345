export default function useFillOptions(fill) {
  const values = Array.isArray(fill)
    ? {
        horizontal: fill.indexOf('horizontal') > -1,
        vertical: fill.indexOf('vertical') > -1,
      }
    : {
        horizontal: fill,
        vertical: fill,
      };

  const fillValue =
    values.horizontal && values.vertical
      ? true
      : values.horizontal
      ? 'horizontal'
      : values.vertical
      ? 'vertical'
      : false;

  return { fillValue, fillValues: values };
}
