import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, ResponsiveContext } from 'grommet';
import { useStoryblokState } from '../lib/storyblok';

import DynamicGrid from './DynamicPage/DynamicGrid';

const MenuFooter = () => {
  const size = useContext(ResponsiveContext);
  const isSmall = size === 'small' || size === 'medsmall';
  const { storyblokEntry } = useStaticQuery(graphql`
    query StoryblokFooterMenu {
      storyblokEntry(slug: { eq: "footer-menu" }) {
        id
        name
        full_slug
        content
        internalId
      }
    }
  `);

  const story = useStoryblokState(storyblokEntry);
  if (!story || !story.content || !story.content.items) {
    return null;
  }

  return (
    <Box direction={isSmall ? 'column' : 'row'} gap="large">
      <Box fill="horizontal">
        <DynamicGrid
          blok={{
            columnWidths: story.content.items
              .map((com) => {
                const submenus = com.items.filter(
                  (x) => x.component === 'Menu'
                );
                return submenus.length > 1 ? '40%' : 'auto';
              })
              .join(','),
            mobileColCount: 1,
            gap: 'large',
            items: story.content.items,
          }}
        />
      </Box>
    </Box>
  );
};

MenuFooter.propTypes = {
  location: PropTypes.object,
};

export default memo(MenuFooter);
