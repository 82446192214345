import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Main } from 'grommet';

const LayoutMain = ({ children, ...rest }) => {
  return (
    <Main height="auto" overflow="visible" className="layout-main" {...rest}>
      {children}
    </Main>
  );
};

LayoutMain.propTypes = {
  children: PropTypes.node,
  offsetSampleCart: PropTypes.bool,
};

export default memo(LayoutMain);
