import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, merge, omit, map, isObject, isEqual, isNil } from '../lib/nodash';
import { render } from 'storyblok-rich-text-react-renderer';
import Mustache from 'mustache';

import MarkdownContent from './MarkdownContent';
import formatCurrency from '../lib/formatCurrency';
import richTextResolvers from '../lib/richTextResolvers';
import selectMember from '../state/industry/selectMember';
import selectDiscountCode from '../state/cart/selectDiscountCode';

function useRichText(body, templateVars, props) {
  const isRichText = isObject(body) && isEqual('doc', get('type', body));

  const member = useSelector(selectMember);
  const memberCredit = useSelector((state) => state.industry.memberCredit);
  const discountCode = useSelector(selectDiscountCode);
  const discountCodeAmount = useSelector(
    (state) => state.cart.discountCodeAmount
  );
  const referredByMember = useSelector(
    (state) => state.industry.referredByMember
  );

  const baseTemplateVars = {
    firstName: get('first_name', member),
    lastName: get('last_name', member),
    email: get('email', member),
    id: get('member_id', member),
    credit: memberCredit && formatCurrency(memberCredit),
    referredByMember: referredByMember,
    referredByMemberComplete: !isNil(get('referral_code', referredByMember)),
    referredByMemberIncomplete: isNil(get('referral_code', referredByMember)),
    discountCode,
    discountCodeAmount,
  };
  const finalTemplateVars = merge(baseTemplateVars, templateVars);

  const childProps = {
    ...omit(
      ['component', 'horizontalAlignment', 'verticalAlignment', 'textAlign'],
      props
    ),
    textAlign: get('horizontalAlignment', props) || get('textAlign', props),
  };
  const bodyComponent = isObject(body)
    ? body
    : { type: 'doc', content: [{ content: body }] };
  const injectedBody = useMemo(
    () => ({
      ...bodyComponent,
      content: map((x) => {
        return {
          ...x,
          attrs: {
            ...x.attrs,
            ...childProps,
            body: map(
              (z) => ({ ...z, ...omit(['size'], childProps) }),
              get('attrs.body', x)
            ),
          },
          content: map((y) => {
            return {
              ...y,
              ...childProps,
              text: Mustache.render(y.text || '', finalTemplateVars),
            };
          }, get('content', x)),
        };
      }, get('content', bodyComponent)),
    }),
    [childProps, body, finalTemplateVars]
  );
  const rendered = useMemo(
    () => render(injectedBody, richTextResolvers),
    [injectedBody, richTextResolvers]
  );

  return isRichText ? rendered : <MarkdownContent>{body}</MarkdownContent>;
}

export default useRichText;
