import { createSelector } from 'reselect';

const selectCheckoutId = createSelector(
  (state) => state.cart,
  (cart) => {
    return cart.checkoutId;
  }
);

export default selectCheckoutId;
