import { encode } from 'base-64';

const shopifyGetGraphQlId = (resource, legacyId) => {
  return legacyId
    ? String(legacyId).indexOf('gid://') > -1
      ? legacyId
      : encode(`gid://shopify/${resource}/${legacyId}`)
    : null;
};

export default shopifyGetGraphQlId;
