import { useSelector } from 'react-redux';

function useMenuOffset() {
  const scrolledToTop = useSelector((state) => state.ui.scrolledToTop);
  const navHeight = useSelector((state) => state.ui.navHeight);
  const announcementBarSize = useSelector(
    (state) => state.ui.announcementBarSize
  );
  const buybarHeight = useSelector((state) => state.ui.buybarHeight);
  const sampleCartHeight = useSelector((state) => state.ui.sampleCartHeight);
  const offsetFromTop = announcementBarSize + sampleCartHeight;
  return {
    scrolledToTop,
    offsetFromTop,
    announcementBarSize,
    navHeight,
    sampleCartHeight,
    buybarHeight,
  };
}

export default useMenuOffset;
