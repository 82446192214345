import loadable from '@loadable/component';

import DynamicImageContentModule from '../components/DynamicPage/DynamicImageContentModule';
import DynamicTextContentModule from '../components/DynamicPage/DynamicTextContentModule';

const BackdropMenu = loadable(() => import('./BackdropMenu'));
const BackdropMenuItem = loadable(() => import('./BackdropMenuItem'));
const DynamicHero = loadable(() => import('./DynamicPage/DynamicHero'));
const DynamicCollectionDescription = loadable(() =>
  import('./Collection/DynamicCollectionDescription')
);
const DynamicShopifyMetafieldText = loadable(() =>
  import('./DynamicPage/DynamicShopifyMetafieldText')
);
const DynamicShopifyMetafieldImagesModule = loadable(() =>
  import('./DynamicShopifyMetafieldImagesModule')
);
const DynamicEntityTitle = loadable(() =>
  import('./DynamicPage/DynamicEntityTitle')
);
const DynamicProductTitle = loadable(() =>
  import('./Product/DynamicProductTitle')
);
const DynamicCard = loadable(() => import('./DynamicPage/DynamicCard'));
const DynamicButtonAddSample = loadable(() =>
  import('./Product/DynamicButtonAddSample')
);
const DynamicButton = loadable(() => import('./DynamicPage/DynamicButton'));
const DynamicGridBreaker = loadable(() =>
  import('./DynamicPage/DynamicGridBreaker')
);
const DynamicGrid = loadable(() => import('./DynamicPage/DynamicGrid'));
const PressItem = loadable(() => import('./DynamicPage/DynamicPressItem'));
const StarRating = loadable(() => import('./Reviews/StarRating'));
const IconFeature = loadable(() => import('./DynamicPage/IconFeature'));
const DynamicIconFeatureTable = loadable(() =>
  import('./DynamicPage/DynamicIconFeatureTable')
);
const DynamicFormiumForm = loadable(() =>
  import('./DynamicPage/DynamicFormiumForm')
);
const NumberedBullet = loadable(() => import('./NumberedBullet'));
const DynamicNumberedHeading = loadable(() =>
  import('./DynamicPage/DynamicNumberedHeading')
);
const DynamicMultiImageContentModule = loadable(() =>
  import('./DynamicPage/DynamicMultiImageContentModule')
);
const DynamicUgcGallery = loadable(() =>
  import('./DynamicPage/DynamicUgcGallery')
);
const DynamicContentSlider = loadable(() =>
  import('./DynamicPage/DynamicContentSlider')
);
const DynamicProductBadges = loadable(() =>
  import('./Product/DynamicProductBadges')
);
const CollapsibleText = loadable(() =>
  import('./DynamicPage/DynamicCollapsibleText')
);
const DynamicCollectionProductsImagesSlider = loadable(() =>
  import('./DynamicPage/DynamicCollectionProductsImagesSlider')
);
const CollectionImage = loadable(() => import('./Collection/CollectionImage'));
const DynamicBrowsePaintProducts = loadable(() =>
  import('./DynamicPage/DynamicBrowsePaintProducts')
);
const DynamicBrowseWallcoveringsProducts = loadable(() =>
  import('./DynamicPage/DynamicBrowseWallcoveringsProducts')
);
const AuthenticationVisibility = loadable(() =>
  import('./AuthenticationVisibility')
);
const SliderReviewQuotes = loadable(() =>
  import('./Reviews/SliderReviewQuotes')
);
const ReviewsPdp = loadable(() => import('./Reviews/ReviewsPdp'));
const EntityCollectionImage = loadable(() =>
  import('./Product/EntityCollectionImage')
);
const DynamicProductCard = loadable(() =>
  import('./Product/DynamicProductCard')
);
const DynamicProductDescription = loadable(() =>
  import('./Product/DynamicProductDescription')
);
const DynamicProductSpecsContent = loadable(() =>
  import('./Product/DynamicProductSpecsContent')
);
const DynamicProductAddToCartButton = loadable(() =>
  import('./Product/DynamicProductAddToCartButton')
);
const PaintCardPartnership = loadable(() =>
  import('./Paint/PaintCardPartnership')
);
const DynamicProductImagesSlider = loadable(() =>
  import('./DynamicPage/DynamicProductImagesSlider')
);
const FormLoginIndustryMember = loadable(() =>
  import('./Industry/FormLoginIndustryMember')
);
const IndustryMemberRegistration = loadable(() =>
  import('./Industry/IndustryMemberRegistration')
);
const FormForgotPassword = loadable(() =>
  import('./Industry/FormForgotPassword')
);
const DynamicRelatedProducts = loadable(() =>
  import('./DynamicPage/DynamicRelatedProducts')
);
const DynamicPaintRelatedProducts = loadable(() =>
  import('./DynamicPage/DynamicPaintRelatedProducts')
);
const DynamicGalleryFilterToggleButton = loadable(() =>
  import('./DynamicPage/DynamicGalleryFilterToggleButton')
);
const PlainText = loadable(() => import('./DynamicPage/DynamicPlainText'));
const FormPartnerLeadDiscount = loadable(() =>
  import('./Industry/FormPartnerLeadDiscount')
);
const MemberDiscountCode = loadable(() =>
  import('./Industry/MemberDiscountCode')
);
const MemberReferralLink = loadable(() =>
  import('./Industry/MemberReferralLink')
);
const MemberAccountCredit = loadable(() =>
  import('./Industry/MemberAccountCredit')
);
const FormColorConsultation = loadable(() =>
  import('./Services/FormColorConsultation')
);
const FormKlaviyoSignup = loadable(() => import('./Klaviyo/FormKlaviyoSignup'));
const WallcoveringsRelatedProducts = loadable(() =>
  import('./Wallcoverings/WallcoveringsRelatedProducts')
);
const WallcoveringsAlternateColorwaysList = loadable(() =>
  import('./Wallcoverings/WallcoveringsAlternateColorwaysList')
);
const DynamicRelatedProductsTabs = loadable(() =>
  import('./Product/DynamicRelatedProductsTabs')
);
const DynamicHeroCollage = loadable(() =>
  import('./DynamicPage/DynamicHeroCollage')
);
const DynamicStockistList = loadable(() =>
  import('./DynamicPage/DynamicStockistList')
);
const DynamicBreadcrumbList = loadable(() =>
  import('./DynamicPage/DynamicBreadcrumbList')
);
const DynamicVideoContentModule = loadable(() =>
  import('./DynamicPage/DynamicVideoContentModule')
);
const DynamicCollectionHeader = loadable(() =>
  import('./DynamicPage/DynamicCollectionHeader')
);
const BlogPostGrid = loadable(() => import('./Blog/BlogPostGrid'));
const DynamicTable = loadable(() => import('./DynamicPage/DynamicTable'));
const Separator = loadable(() => import('./Separator'));
const ButtonsProductCartOptions = loadable(() =>
  import('./DynamicPage/DynamicProductCartButtons')
);
const DynamicAccordion = loadable(() =>
  import('./DynamicPage/DynamicAccordion')
);
const DynamicAccordionItem = loadable(() =>
  import('./DynamicPage/DynamicAccordionItem')
);
const HorizontalDivider = loadable(() =>
  import('./DynamicPage/DynamicHorizontalDivider')
);
const DynamicSoundOnScroll = loadable(() =>
  import('./DynamicPage/DynamicSoundOnScroll')
);
const DynamicSoundOnAddToCart = loadable(() =>
  import('./DynamicPage/DynamicSoundOnAddToCart')
);
const DynamicEmbeddedContent = loadable(() =>
  import('./DynamicPage/DynamicEmbeddedContent')
);
const DynamicCollectionSpotifyPlaylist = loadable(() =>
  import('./DynamicPage/DynamicCollectionSpotifyPlaylist')
);
const DynamicParallax = loadable(() => import('./DynamicPage/DynamicParallax'));
const DynamicAnimator = loadable(() => import('./DynamicPage/DynamicAnimator'));
const PaintCartButtons = loadable(() =>
  import('./DynamicPage/DynamicPaintCartButtons')
);

export default {
  TextContentModule: DynamicTextContentModule,
  ImageContentModule: DynamicImageContentModule,
  MultiImageContentModule: DynamicMultiImageContentModule,
  FormiumForm: DynamicFormiumForm,
  Button: DynamicButton,
  UgcGallery: DynamicUgcGallery,
  Menu: BackdropMenu,
  MenuItem: BackdropMenuItem,
  Hero: DynamicHero,
  PressItem: PressItem,
  StarRating: StarRating,
  Grid: DynamicGrid,
  CollectionProductsImagesSlider: DynamicCollectionProductsImagesSlider,
  CollectionDescription: DynamicCollectionDescription,
  CollectionImage: CollectionImage,
  ShopifyMetafieldText: DynamicShopifyMetafieldText,
  ShopifyMetafieldImagesModule: DynamicShopifyMetafieldImagesModule,
  EntityTitle: DynamicEntityTitle,
  EntityCollectionImage: EntityCollectionImage,
  IconFeature: IconFeature,
  IconFeatureTable: DynamicIconFeatureTable,
  RelatedProducts: DynamicRelatedProducts,
  PaintRelatedProducts: DynamicPaintRelatedProducts,
  ReviewsSlider: SliderReviewQuotes,
  ProductPdpReviews: ReviewsPdp,
  ProductCard: DynamicProductCard,
  ProductDescription: DynamicProductDescription,
  ProductTitle: DynamicProductTitle,
  ProductAddToCartButton: DynamicProductAddToCartButton,
  LoginForm: FormLoginIndustryMember,
  RegisterForm: IndustryMemberRegistration,
  ForgotPasswordForm: FormForgotPassword,
  MemberDiscountCode: MemberDiscountCode,
  MemberReferralLink: MemberReferralLink,
  MemberAccountCredit: MemberAccountCredit,
  EmbeddedContent: DynamicEmbeddedContent,
  AddSampleButton: DynamicButtonAddSample,
  ProductCartButtons: ButtonsProductCartOptions,
  PaintCartButtons: PaintCartButtons,
  PartnerLeadDiscountForm: FormPartnerLeadDiscount,
  ColorConsultationForm: FormColorConsultation,
  Card: DynamicCard,
  CollapsibleText: CollapsibleText,
  KlaviyoForm: FormKlaviyoSignup,
  ProductImagesSlider: DynamicProductImagesSlider,
  PartnershipPaintCard: PaintCardPartnership,
  ContentSlider: DynamicContentSlider,
  CollageHero: DynamicHeroCollage,
  PaintBrowser: DynamicBrowsePaintProducts,
  WallcoveringsBrowser: DynamicBrowseWallcoveringsProducts,
  NumberedBullet: NumberedBullet,
  NumberedHeading: DynamicNumberedHeading,
  ProductBadges: DynamicProductBadges,
  GridBreaker: DynamicGridBreaker,
  ProductSpecsContent: DynamicProductSpecsContent,
  PlainTextModule: PlainText,
  WallcoveringsRelatedProducts: WallcoveringsRelatedProducts,
  WallcoveringsAlternateColorways: WallcoveringsAlternateColorwaysList,
  AuthenticationVisibility: AuthenticationVisibility,
  StockistList: DynamicStockistList,
  BreadcrumbList: DynamicBreadcrumbList,
  VideoContentModule: DynamicVideoContentModule,
  CollectionHeader: DynamicCollectionHeader,
  RelatedProductsTabs: DynamicRelatedProductsTabs,
  BlogPostGrid: BlogPostGrid,
  Table: DynamicTable,
  Separator: Separator,
  GalleryFilterToggleButton: DynamicGalleryFilterToggleButton,
  Accordion: DynamicAccordion,
  AccordionItem: DynamicAccordionItem,
  HorizontalDivider: HorizontalDivider,
  SoundOnScroll: DynamicSoundOnScroll,
  SoundOnAddToCart: DynamicSoundOnAddToCart,
  CollectionSpotifyPlaylist: DynamicCollectionSpotifyPlaylist,
  Parallax: DynamicParallax,
  Animator: DynamicAnimator,
};
