import React from 'react';
import PropTypes from 'prop-types';
import {
  NODE_PARAGRAPH,
  NODE_IMAGE,
  NODE_HEADING,
  NODE_HR,
  MARK_BOLD,
  MARK_LINK,
  NODE_OL,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { isObject } from './nodash';
import { Paragraph, Heading, Text, Box } from 'grommet';

import ImgixImage from '../components/ImgixImage';
import SmartLink from '../components/SmartLink';
import OrderedList from '../components/OrderedList';
import MarkdownContent from '../components/MarkdownContent';

const BoldText = (children, otherProps = {}) => (
  <Text weight={500} {...otherProps}>
    {children}
  </Text>
);
BoldText.propTypes = {
  children: PropTypes.node,
};

const ElParagraph = (children = [], otherProps = {}) => {
  const proppedChildren = React.Children.map(children, (x) =>
    isObject(x) ? { ...x, props: { ...x.props, size: otherProps.size } } : x
  );
  return <Paragraph {...otherProps}>{proppedChildren}</Paragraph>;
};
ElParagraph.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};

const ElHeading = (children, { level }) => (
  <Heading level={level}>{children}</Heading>
);

const ElImage = (children, props) => (
  <ImgixImage
    fit="contain"
    {...props}
    srcSetOptions={{ auto: ['format', 'compress'], cs: 'srgb' }}
    style={{ maxWidth: '100%' }}
  />
);

const ElLink = (children, { href, target, linktype }) => {
  return (
    <SmartLink
      to={
        linktype === 'email'
          ? `mailto:${href}`
          : linktype === 'story'
          ? `${href.substring(0, 1) === '/' ? '' : '/'}${href}`
          : href
      }
      target={target}
    >
      {children}
    </SmartLink>
  );
};

const ElHr = () => (
  <Box
    fill="horizontal"
    border={{ side: 'bottom', size: 'small', color: 'black' }}
  />
);

const ElOl = (children) => <OrderedList>{children}</OrderedList>;
const ElUl = (children) => (
  <ul>
    {children.map((x, i) => {
      return (
        <Box
          as="li"
          key={i}
          margin={{ vertical: 'xsmall' }}
          style={{ display: 'list-item' }}
        >
          {x.props.children.map((z) => ({
            ...z,
            props: { ...z.props, margin: 'none' },
          }))}
        </Box>
      );
    })}
  </ul>
);

const ElMarkdown = (str) => <MarkdownContent>{str}</MarkdownContent>;
const ElDynamicComponent = (name, props) => {
  return (
    <div>
      <StoryblokComponent blok={{ ...props, component: name }} {...props} />
    </div>
  );
};

const richTextResolvers = {
  defaultStringResolver: ElMarkdown,
  defaultBlokResolver: ElDynamicComponent,
  nodeResolvers: {
    [NODE_PARAGRAPH]: ElParagraph,
    [NODE_HEADING]: ElHeading,
    [NODE_IMAGE]: ElImage,
    [NODE_HR]: ElHr,
    [NODE_OL]: ElOl,
    [NODE_UL]: ElUl,
  },
  markResolvers: {
    [MARK_BOLD]: BoldText,
    [MARK_LINK]: ElLink,
  },
};

export default richTextResolvers;
