import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';
import useFillOptions from '../useFillOptions';
import useRichText from '../useRichText';
import useMenuOffset from '../useMenuOffset';
import useMobile from '../useMobile';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const DynamicTextContentModule = ({ blok, ...rest }) => {
  const {
    superHeading,
    title,
    body,
    verticalAlignment = 'center',
    horizontalAlignment = 'start',
    mobileHorizontalAlignment,
    headingLevel = 1,
    sizeAsHeadingLevel,
    mobileSizeAsHeadingLevel,
    headingStyle = 'capitalized',
    verticalPad = 'large',
    horizontalPad = 'large',
    queryParamString,
    fill = [],
    border = [],
    size,
    gap,
    offsetForMenu,
    truncateHeading,
    paragraphVerticalMargin,
    isSticky,
  } = blok;
  const { fillValue } = useFillOptions(fill);
  const { navHeight } = useMenuOffset();
  const isMobile = useMobile();
  const adjustedFontStyle = useAdjustedHeadingSize(
    isMobile
      ? mobileSizeAsHeadingLevel ?? sizeAsHeadingLevel
      : sizeAsHeadingLevel
  );

  const parsedTitle = title
    .replace(/{{br}}/g, '<br/>')
    .replace(/{{ br }}/g, '<br/>');

  const xAlign = isMobile
    ? mobileHorizontalAlignment || horizontalAlignment
    : horizontalAlignment;
  const isNormalCase = headingStyle === 'normal';
  const richText = useRichText(body, rest.templateVars || blok.templateVars, {
    ...rest,
    margin: paragraphVerticalMargin
      ? { vertical: paragraphVerticalMargin }
      : undefined,
    verticalAlignment,
    horizontalAlignment: xAlign,
    size,
    queryParamString,
  });

  const containerVAlignment =
    verticalAlignment === 'split' ? 'between' : verticalAlignment;
  const textContainerProps =
    verticalAlignment === 'split' ? { flex: true, justify: 'center' } : {};

  const borders = border.map((x) => ({ side: x, size: 'small' }));
  const isNilBody =
    body && body.content?.length === 1 && !body.content[0].content;

  return (
    <Box
      key={blok._uid}
      {...storyblokEditable(blok)}
      flex={rest.flex}
      background={rest.background}
      margin={
        offsetForMenu && !isMobile ? { top: `${navHeight}px` } : undefined
      }
      pad={{ vertical: verticalPad, horizontal: horizontalPad }}
      justify={containerVAlignment}
      align={xAlign}
      border={borders}
      fill={fillValue}
      gap={gap}
    >
      {((title && title !== '') || (superHeading && superHeading !== '')) && (
        <Box>
          {superHeading && (
            <Heading textAlign={xAlign} level={5}>
              {superHeading}
            </Heading>
          )}
          {title && (
            <Heading
              textAlign={xAlign}
              level={headingLevel}
              truncate={truncateHeading}
              style={
                isNormalCase
                  ? { ...adjustedFontStyle, textTransform: 'none' }
                  : adjustedFontStyle
              }
              dangerouslySetInnerHTML={{ __html: parsedTitle }}
            />
          )}
        </Box>
      )}
      {body && body !== '' && !isNilBody && (
        <Box align={xAlign} {...textContainerProps}>
          {richText}
        </Box>
      )}
    </Box>
  );
};

DynamicTextContentModule.propTypes = {
  blok: PropTypes.shape({
    superHeading: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    headingLevel: PropTypes.oneOf(['1', '2', '3', '4', '5', 1, 2, 3, 4, 5, '']),
    sizeAsHeadingLevel: PropTypes.oneOf([
      '1',
      '2',
      '3',
      '4',
      '5',
      1,
      2,
      3,
      4,
      5,
      '',
    ]),
    mobileSizeAsHeadingLevel: PropTypes.oneOf([
      '1',
      '2',
      '3',
      '4',
      '5',
      1,
      2,
      3,
      4,
      5,
      '',
    ]),
    headingStyle: PropTypes.oneOf(['capitalized', 'normal']),
    verticalAlignment: PropTypes.oneOf([
      'center',
      'start',
      'end',
      'between',
      'around',
      'split',
    ]),
    horizontalAlignment: PropTypes.oneOf(['center', 'start', 'end']),
    templateVars: PropTypes.object,
    verticalPad: PropTypes.string,
    horizontalPad: PropTypes.string,
    fill: PropTypes.array,
    border: PropTypes.array,
    size: PropTypes.string,
    color: PropTypes.string,
    gap: PropTypes.string,
    queryParamString: PropTypes.string,
    offsetForMenu: PropTypes.bool,
    mobileHorizontalAlignment: PropTypes.string,
    truncateHeading: PropTypes.bool,
    style: PropTypes.string,
    _uid: PropTypes.string,
  }),
};

export default memo(DynamicTextContentModule);
