import {
  encodeDelimitedArray,
  decodeDelimitedArray,
  withDefault,
} from 'serialize-query-params';

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayParam = withDefault(
  {
    encode: (array) => encodeDelimitedArray(array, ','),
    decode: (arrayStr) => decodeDelimitedArray(arrayStr, ','),
  },
  []
);

/** Uses a tilde to delimit entries. e.g. ['a', 'b'] => qp?=a~b */
export const TildeArrayParam = withDefault(
  {
    encode: (array) => encodeDelimitedArray(array, '~'),
    decode: (arrayStr) => decodeDelimitedArray(arrayStr, '~'),
  },
  []
);
