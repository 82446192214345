import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isString } from '../lib/nodash';
import { Box, Text } from 'grommet';

import NumberedBullet from './NumberedBullet';

const OrderedList = ({ children, start = 1, ...rest }) => {
  return (
    <Box {...rest}>
      {children.map((x, i) => {
        return (
          <Box
            direction="row"
            as="li"
            key={`li-${x.key}`}
            gap="small"
            align="top"
            margin={{ vertical: 'xsmall' }}
          >
            <NumberedBullet number={start + i} />
            <Text>
              {x.props.children.map((z) => {
                return Array.isArray(z)
                  ? z.map((y) => y)
                  : isString(z)
                  ? z
                  : {
                      ...z,
                      props: { ...z.props, margin: 'none' },
                    };
              })}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

OrderedList.propTypes = {
  children: PropTypes.node.isRequired,
  start: PropTypes.number.isRequired,
};

export default memo(OrderedList);
