exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaigns-campaign-route-js": () => import("./../../../src/pages/campaigns/CampaignRoute.js" /* webpackChunkName: "component---src-pages-campaigns-campaign-route-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns/[...].js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-discount-js": () => import("./../../../src/pages/discount/[...].js" /* webpackChunkName: "component---src-pages-discount-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-pages-calculator-js": () => import("./../../../src/pages/pages/calculator.js" /* webpackChunkName: "component---src-pages-pages-calculator-js" */),
  "component---src-pages-pages-gallery-js": () => import("./../../../src/pages/pages/gallery/[...].js" /* webpackChunkName: "component---src-pages-pages-gallery-js" */),
  "component---src-pages-pages-my-account-js": () => import("./../../../src/pages/pages/my-account.js" /* webpackChunkName: "component---src-pages-pages-my-account-js" */),
  "component---src-pages-pages-ordernow-js": () => import("./../../../src/pages/pages/ordernow.js" /* webpackChunkName: "component---src-pages-pages-ordernow-js" */),
  "component---src-pages-pages-reviews-js": () => import("./../../../src/pages/pages/reviews.js" /* webpackChunkName: "component---src-pages-pages-reviews-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-paint-collection-page-js": () => import("./../../../src/templates/paintCollectionPage.js" /* webpackChunkName: "component---src-templates-paint-collection-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-wallcoverings-collection-page-js": () => import("./../../../src/templates/wallcoveringsCollectionPage.js" /* webpackChunkName: "component---src-templates-wallcoverings-collection-page-js" */)
}

