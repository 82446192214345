export const PRODUCT_ADDED = 'Product Added'; //
export const PAINT_ADDED = 'Paint Added'; //
export const PRODUCT_VIEWED = 'Product Viewed';
export const PRODUCT_ADDED_FROM_CHECKOUT = 'Product Added From Checkout'; //
export const PRODUCT_REMOVED = 'Product Removed';
export const PRODUCT_LIST_FILTERED = 'Product List Filtered'; //
export const PRODUCT_LIST_FILTER_ENABLED = 'Product List Filter Enabled'; //
export const PRODUCT_LIST_VIEWED = 'Product List Viewed';
export const CART_VIEWED = 'Cart Viewed'; //
export const PRODUCT_LIST_PRODUCT_HOVERED = 'Product List Product Hovered'; //
export const SAMPLE_ADDED = 'Sample Added'; //
export const PDP_SCROLL_FEATURED_IMAGES =
  'Product Detail Page Featured Images Scrolled';
export const SIMILAR_PRODUCT_CLICKED = 'Similar Product Clicked'; //
export const PDP_GALLERY_INTERACTION = 'PDP Gallery Interaction'; //
export const UGC_GALLERY_THUMBNAIL_CLICK = 'UGC Gallery Thumbnail Clicked'; //
export const UGC_GALLERY_ITEM_DETAILS = 'UGC Gallery Item Details Viewed'; //
export const UGC_PRODUCT_CLICK_THROUGH =
  'UGC Gallery Item Click-through to Product'; //
export const GALLERY_FILTER_USED = 'Gallery Filter Used'; //
export const SAMPLE_BAR_CHECKOUT = 'Sample Bar Checkout Clicked'; //
export const ADDED_ALL_SUGGESTED_TO_CART =
  'All Suggested Products Added To Cart'; //
export const SUGGESTED_PRODUCT_ADDED = 'Suggested Product Added To Cart'; //
export const NAV_ITEM_CLICKED = 'Navigation Item Clicked';
export const HOMEPAGE_CTA_CLICKED = 'Homepage CTA Clicked';
export const FOOTER_LINK_CLICKED = 'Footer Link Clicked';
export const HOME_PAGE_SCROLLED = 'Home Page Scrolled';
export const PDP_SCROLLED = 'Product Detail Page Scrolled';
export const PDP_CALCULATE = 'Product Detail Page Calculator Button Clicked'; //
export const CAMPAIGN_LANDED = 'Campaign Landed'; //
export const FORMIUM_FORM_SUBMITTED = 'Formium Form Submitted'; //
export const PROMOTION_VIEWED = 'Promotion Viewed';
export const COUPON_APPLIED = 'Coupon Applied';
export const CHECKOUT_STARTED = 'Checkout Started';
export const PAINTING_SERVICES_REQUESTED = 'Painting Services Requested';
export const WALLCOVERINGS_ADDED = 'Wallcoverings Added'; //
// ^ replaced with FORMIUM_FORM_SUBMITTED for more flexibility
