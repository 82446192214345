import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from 'grommet';
import { Volume, VolumeMute } from 'grommet-icons';

import { setAudioEnabled } from '../state/ui/uiSlice';
import { selectAudioEnabled } from '../state/ui/audioSelectors';

export default function AudioToggle() {
  const dispatch = useDispatch();
  const audioEnabled = useSelector(selectAudioEnabled);

  return (
    <Box>
      <Button
        title={audioEnabled ? 'Disable audio' : 'Enable audio'}
        size="xsmall"
        pad="small"
        onClick={() => dispatch(setAudioEnabled(!audioEnabled))}
        icon={audioEnabled ? <VolumeMute /> : <Volume />}
        aria-label={audioEnabled ? 'Disable audio' : 'Enable audio'}
        alignSelf="start"
        fill={false}
        primary
      />
    </Box>
  );
}
