import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Layer, Box, Heading, Paragraph, Button } from 'grommet';
import SmartLink from './SmartLink';

import ModalCloseButton from './ModalCloseButton';

const Modal = ({
  title,
  body,
  ctas = [],
  onClose,
  children,
  position = 'center',
  responsive = false,
  margin,
  full,
  border = { side: 'all', size: 'small', color: 'black' },
  maxHeight,
}) => {
  return (
    <Layer
      onClickOutside={onClose}
      onEsc={onClose}
      responsive={responsive}
      modal={true}
      margin={margin ? margin : responsive ? undefined : 'medium'}
      position={position}
      style={{ width: full ? '100%' : 'fit-content', maxHeight }}
      full={full}
    >
      <Box border={border} fill>
        <ModalCloseButton onClose={onClose} />
        {title && (
          <Box pad={{ horizontal: 'large', top: 'large' }}>
            <Heading margin="none" level={3}>
              {title}
            </Heading>
          </Box>
        )}
        {children ? (
          children
        ) : (
          <Box pad={{ horizontal: 'large', bottom: 'large' }}>
            <Paragraph dangerouslySetInnerHTML={{ __html: body }} />
          </Box>
        )}
        {Boolean(ctas.length) && (
          <Box
            direction="row-responsive"
            gap="small"
            pad={{ horizontal: 'large', bottom: 'large' }}
            align="center"
            justify="center"
          >
            {ctas.map(({ href, ...rest }, i) => (
              <SmartLink key={`cta-${i}`} to={href}>
                <Button {...rest} />
              </SmartLink>
            ))}
          </Box>
        )}
      </Box>
    </Layer>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  responsive: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  border: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  position: PropTypes.string,
  full: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxHeight: PropTypes.string,
  ctas: PropTypes.arrayOf(PropTypes.object),
};

export default memo(Modal);
