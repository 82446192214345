import { useState, useEffect } from 'react';

export default function useGoogleAnalyticsProperties() {
  const [sessionId, setSessionId] = useState(null);
  const [sessionNumber, setSessionNumber] = useState(null);

  async function getValues() {
    const sessionIdPromise = new Promise((resolve) => {
      window.gtag(
        'get',
        `G-${process.env.GATSBY_GA4_MEASUREMENT_ID}`,
        'session_id',
        resolve
      );
    });
    const sessionNumPromise = new Promise((resolve) => {
      window.gtag(
        'get',
        `G-${process.env.GATSBY_GA4_MEASUREMENT_ID}`,
        'session_number',
        resolve
      );
    });

    Promise.all([sessionIdPromise, sessionNumPromise]).then(function (
      session_data
    ) {
      setSessionId(session_data[0]);
      setSessionNumber(session_data[1]);
    });
  }

  useEffect(() => {
    if (window && typeof window.gtag === 'function') {
      getValues();
    }
  }, [getValues, typeof window]);

  return { sessionId, sessionNumber };
}
