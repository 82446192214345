import { createSelector } from '@reduxjs/toolkit';

export const selectHasAudio = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.hasAudio;
  }
);

export const selectAudioEnabled = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.audioEnabled;
  }
);

export const selectAudioPlaying = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.audioPlaying;
  }
);
