import engine from 'store/src/store-engine';
import cookieStorage from 'store/storages/cookieStorage';
import expiredPlugin from 'store/plugins/expire';
const storages = [cookieStorage];
const plugins = [expiredPlugin];

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const isBrowser = typeof window !== 'undefined';
const cookieStore = inIframe()
  ? {
      get: () => null,
      set: (x) => x,
      remove: () => null,
    }
  : isBrowser
  ? engine.createStore(storages, plugins)
  : { get: () => null };

export default cookieStore;
