import ImgixClient from '@imgix/js-core';

const client = new ImgixClient({
  domain: 'backdrop-reviews.imgix.net',
});

export const staticClient = new ImgixClient({
  domain: 'backdrop-static.imgix.net',
});

export const shopifyImageClient = new ImgixClient({
  domain: 'backdrop-shopify.imgix.net',
  secureURLToken: process.env.GATSBY_IMGIX_TOKEN,
});

export const accentuateImageClient = new ImgixClient({
  domain: 'backdrop-accentuate.imgix.net',
  secureURLToken: process.env.GATSBY_ACCENTUATE_IMGIX_TOKEN,
});

export const storyblokImageClient = new ImgixClient({
  domain: 'backdrop-storyblok.imgix.net',
  secureURLToken: process.env.GATSBY_STORYBLOK_IMGIX_TOKEN,
});

export const getClientForUrl = (url = '') => {
  return url
    ? url.indexOf('shopify') > -1
      ? shopifyImageClient
      : url.indexOf('accentuate.io') > -1
      ? accentuateImageClient
      : url.indexOf('backdrop-static-content') > -1
      ? staticClient
      : url.indexOf('storyblok') > -1
      ? storyblokImageClient
      : url.indexOf('backdrop') > -1
      ? client
      : storyblokImageClient
    : null;
};

export const getKeyFromUrl = (url = '') =>
  url
    ? decodeURIComponent(url)
        .replace('https://backdrop-reviews.s3.amazonaws.com/', '')
        .replace('https://backdrop-static-content.s3.amazonaws.com/', '')
    : undefined;
