import { useState, useRef, useEffect } from 'react';
import { isFunction } from '../lib/nodash';

export default function useHover(onOver, onOut) {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => {
    if (onOver && isFunction(onOver)) {
      onOver();
    }
    setValue(true);
  };
  const handleMouseOut = () => {
    if (onOut && isFunction(onOut)) {
      onOut();
    }
    setValue(false);
  };
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);
        return () => {
          node.removeEventListener('mouseenter', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}
