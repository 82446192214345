import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { get, head, isNil } from '../../lib/nodash';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { isAfter, parseISO } from 'date-fns';

import { useStoryblokState } from '../../lib/storyblok';
import { CONTENT_NODES_BY_FULL_SLUG } from '../../queries/storyblokQueries';
import AnnouncementBar from './AnnouncementBar';
import { storyblokClient } from '../../gatsby-theme-apollo/client';
import {
  closeAnnouncementBar,
  setAnnouncementBarSize,
} from '../../state/ui/uiSlice';

const GlobalAnnouncementBar = () => {
  const dispatch = useDispatch();
  const announcementBarOpen = useSelector(
    (state) => state.ui.announcementBarOpen
  );
  const { data } = useQuery(CONTENT_NODES_BY_FULL_SLUG, {
    client: storyblokClient,
    variables: { full_slug: 'announcements/global-announcements' },
  });
  const story = head(get('ContentNodes.items', data));
  const liveStory = useStoryblokState(story);
  const announcements = (get('content.announcements', liveStory) || []).filter(
    (x) => {
      return (
        isNil(x.visibleAfter) ||
        x.visibleAfter === '' ||
        isAfter(new Date(), parseISO(x.visibleAfter))
      );
    }
  );
  const interval = get('content.interval', liveStory);

  const handleClose = useCallback(() => {
    dispatch(closeAnnouncementBar());
  }, [dispatch, closeAnnouncementBar]);

  return (
    <AnnouncementBar
      isOpen={announcementBarOpen}
      announcements={announcements}
      onClose={handleClose}
      style={{ zIndex: 25 }}
      interval={parseFloat(interval) * 1000}
      onAnimationComplete={(def, height) => {
        dispatch(setAnnouncementBarSize(height));
      }}
    />
  );
};

GlobalAnnouncementBar.propTypes = {
  announcements: PropTypes.array,
};

export default memo(GlobalAnnouncementBar);
