import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { Close } from 'grommet-icons';
import styled from 'styled-components';

const ModalClose = styled(Box)`
  position: absolute;
  right: 0.4em;
  top: 0.4em;
  cursor: pointer;
  z-index: 10;
  height: 28px;
  width: 28px;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    height: 40px;
    width: 40px;
  }
`;

const ModalCloseButton = ({ onClose }) => {
  return (
    <ModalClose
      background="white"
      justify="center"
      alignContent="center"
      align="center"
      alignSelf="center"
      round="100px"
      onClick={onClose}
    >
      <Close size="16px" color="black" />
    </ModalClose>
  );
};

ModalCloseButton.propTypes = {
  onClose: PropTypes.func,
};

export default memo(ModalCloseButton);
