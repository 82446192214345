import React, { useRef, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import { Menu, Close } from 'grommet-icons';
import { LazyMotion, m as motionM } from 'framer-motion';
import styled from 'styled-components';

import { get } from '../lib/nodash';
import { useStoryblokState } from '../lib/storyblok';
import CartButton from './Cart/CartButton';
import MobileMainMenu from './MobileMainMenu';
import useCart from './Cart/useCart';
import useMobile from './useMobile';
import {
  toggleMainMenuActive,
  setActiveHeaderSubMenu,
  setActiveHeaderSubMenuItem,
  setMainMenuActive,
} from '../state/ui/uiSlice';
import HeaderMenuComponent from './HeaderMenuComponent';
import HeaderSubMenu from './HeaderSubMenu';
import ResponsiveDisplay from './ResponsiveDisplay';
import selectActiveHeaderSubMenu from '../state/ui/selectActiveHeaderSubMenu';
import selectDiscountCode from '../state/cart/selectDiscountCode';
import selectJwt from '../state/industry/selectJwt';
import useMember from './Industry/useMember';
import useHover from './useHover';

const loadFeatures = () =>
  import('../lib/framer/motionFeatures.js').then((res) => res.default);

const Container = motionM(Box);

const OuterContainer = styled(Box)`
  z-index: 10;
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    z-index: 1002;
  }
`;
const FlexContainer = styled(Box)`
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

const InnerContainer = styled(Box)`
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: ${(p) => p.theme.global.mobileMenuHeight};
  padding-left: ${(p) => p.theme.global.edgeSize.medium};
  padding-right: ${(p) => p.theme.global.edgeSize.medium};
  padding-top: ${(p) => p.theme.global.edgeSize.small};
  padding-bottom: ${(p) => p.theme.global.edgeSize.small};
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0;
    height: auto;
  }
`;

const CartContainer = styled(Box)`
  width: 100%;
  padding-right: 0;
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    padding-right: ${(p) => p.theme.global.edgeSize.medium};
    width: auto;
  }
`;

const ToggleCartButton = styled(Button)`
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    flex-shrink: 1;
    flex-grow: 1;
  }
`;

const SiteHeader = ({ location }) => {
  const { headerMenu } = useStaticQuery(graphql`
    query HeaderMenu {
      headerMenu: storyblokEntry(full_slug: { eq: "menus/header-menu-v2" }) {
        full_slug
        content
        id
        slug
        uuid
        internalId
      }
    }
  `);
  const story = useStoryblokState(headerMenu);
  const menu = get('content', story) || [];
  const dispatch = useDispatch();
  const navRef = useRef(null);
  const [containerRef] = useHover(null, () => {
    dispatch(setActiveHeaderSubMenu(null));
    dispatch(setActiveHeaderSubMenuItem(null));
  });
  const isMobile = useMobile();
  const { cartItemCount, toggleOpen } = useCart();
  const discountCode = useSelector(selectDiscountCode);
  const member = useMember();
  const jwt = useSelector(selectJwt);

  const toggleMenu = useCallback(() => {
    dispatch(toggleMainMenuActive());
  }, [dispatch, toggleMainMenuActive]);

  const closeMenu = useCallback(() => {
    dispatch(setMainMenuActive(false));
  }, [dispatch, setMainMenuActive]);

  const activeSubMenuId = useSelector(selectActiveHeaderSubMenu);
  const mainMenuActive = useSelector((state) => state.ui.mainMenuActive);

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box>
        <OuterContainer as="nav" ref={containerRef}>
          <Box
            direction="row"
            fill="horizontal"
            border={{ side: 'bottom', size: 'small' }}
          >
            <Box flex={true}>
              <FlexContainer>
                <Container
                  ref={navRef}
                  className="nav-header"
                  style={{
                    WebkitTransition: 'background-color .2s ease-in-out',
                    MozTransition: 'background-color .2s ease-in-out',
                    OTransition: 'background-color .2s ease-in-out',
                    transition: 'background-color .2s ease-in-out',
                  }}
                >
                  <InnerContainer
                    background="white"
                    direction="row"
                    align="center"
                    justify="between"
                    fill="horizontal"
                  >
                    <Box>
                      <ResponsiveDisplay mobile={false} desktop={true}>
                        <Box direction="row" align="center" gap="small">
                          {menu.items.map((x) => {
                            return <HeaderMenuComponent key={x._uid} {...x} />;
                          })}
                        </Box>
                      </ResponsiveDisplay>
                    </Box>

                    <CartContainer
                      direction="row"
                      align="center"
                      justify="between"
                      gap={isMobile ? 'medium' : undefined}
                    >
                      <ResponsiveDisplay mobile={true} desktop={false}>
                        <Button
                          plain
                          icon={
                            mainMenuActive ? (
                              <Close color="brand" />
                            ) : (
                              <Menu color="brand" />
                            )
                          }
                          onClick={toggleMenu}
                        />
                      </ResponsiveDisplay>
                      <ToggleCartButton
                        onClick={() => {
                          closeMenu();
                          toggleOpen();
                        }}
                        justify="start"
                        align="center"
                        gap="small"
                        focusIndicator={false}
                        reverse
                        plain
                        color="transparent"
                        icon={
                          <div>
                            <CartButton
                              round={true}
                              label={`${String(cartItemCount)}`}
                            />
                          </div>
                        }
                        label={
                          <ResponsiveDisplay mobile={false} desktop={true}>
                            Cart
                          </ResponsiveDisplay>
                        }
                      />
                    </CartContainer>
                  </InnerContainer>
                </Container>
                <ResponsiveDisplay mobile={false} desktop={true}>
                  <Box flex={true} fill="horizontal" style={{ zIndex: 100 }}>
                    {menu.items
                      .filter((m) => m.component === 'Menu')
                      .map((m) => {
                        return (
                          <HeaderSubMenu
                            isActive={activeSubMenuId === m._uid}
                            key={m._uid}
                            {...m}
                          />
                        );
                      })}
                  </Box>
                </ResponsiveDisplay>
              </FlexContainer>
            </Box>
          </Box>
        </OuterContainer>
        {isMobile && (
          <MobileMainMenu
            menu={menu}
            discountCode={discountCode}
            member={member}
            jwt={jwt}
          />
        )}
      </Box>
    </LazyMotion>
  );
};

SiteHeader.propTypes = {
  location: PropTypes.object.isRequired,
};

export default memo(SiteHeader);
