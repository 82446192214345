import { createSlice } from '@reduxjs/toolkit';
import { reject } from '../../lib/nodash';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    checkoutId: null,
    errors: [],
    open: false,
    sampleCartOpen: false,
    adjusting: null,
    discountCode: null,
    discountCodeAmount: null,
    failedDiscountCode: null,
    samplesArrangement: [],
    campaignDiscounts: {},
    projectType: undefined,
    cartReady: false,
    cartChecked: false,
    clientId: null,
  },
  reducers: {
    setCheckoutId: (state, action) => {
      state.checkoutId = action.payload;
      state.cartReady = true;
      state.cartChecked = true;
      state.errors = [];
    },
    clearCheckout: (state) => {
      state.checkoutId = null;
      state.errors = [];
    },
    setErrors: (state, action) => {
      state.errors = action.payload || [];
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    setOpen: (state, action) => {
      state.open = action.payload || false;
    },
    setAdjusting: (state, action) => {
      state.adjusting = action.payload;
    },
    setDiscountCode: (state, action) => {
      state.discountCode = action.payload;
    },
    setDiscountCodeAmount: (state, action) => {
      state.discountCodeAmount = action.payload;
    },
    setProjectType: (state, action) => {
      state.projectType = action.payload;
    },
    addToSamplesArrangement: (state, action) => {
      state.samplesArrangement = [
        ...(state.samplesArrangement || []),
        action.payload,
      ];
    },
    removeFromSamplesArrangement: (state, action) => {
      state.samplesArrangement = reject(
        (x) => x === action.payload,
        state.samplesArrangement
      );
    },
    resetSamplesArrangement: (state) => {
      state.samplesArrangement = [];
    },
    addCampaignCode: (state, action) => {
      const { campaign, amount, code } = action.payload;
      if (state.campaignDiscounts) {
        state.campaignDiscounts[campaign] = { amount, code };
      } else {
        state.campaignDiscounts = {
          [campaign]: { amount, code },
        };
      }
    },
    setCartReady: (state, action) => {
      state.cartChecked = true;
      state.cartReady = action.payload;
    },
    setFailedDiscountCode: (state, action) => {
      state.discountCode = null;
      state.discountCodeAmount = null;
      state.failedDiscountCode = action.payload;
    },
    resetDiscountCode: (state) => {
      state.discountCode = null;
      state.discountCodeAmount = null;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
});

export const {
  setCheckoutId,
  clearCheckout,
  setErrors,
  toggleOpen,
  setOpen,
  setAdjusting,
  setDiscountCode,
  setDiscountCodeAmount,
  setProjectType,
  addToSamplesArrangement,
  removeFromSamplesArrangement,
  resetSamplesArrangement,
  addCampaignCode,
  setCartReady,
  setFailedDiscountCode,
  resetDiscountCode,
  setClientId,
} = cartSlice.actions;

export default cartSlice.reducer;
