import gql from 'graphql-tag';

export const MY_MEMBER_CREDIT = gql`
  query MemberCredit {
    myMemberCredit {
      balance
      first_name
      member_id
      last_characters
      referral_code
      short_referral_url
      updated_at
      code
      id
      discount_percent
    }
  }
`;

export const CURRENT_MEMBER = gql`
  query CurrentMember {
    currentMember {
      user_id
      member_id
      referral_code
      short_referral_url
      updated_at
      first_name
      last_name
      company_name
      gift_card_code
      email
      industry_type
      shopify_customer_id
      type
      allows_sms_contact
      member_group {
        id
        name
      }
      discount_percent
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        confirmed
        blocked
        role {
          id
          name
          description
          type
        }
      }
    }
  }
`;

export const MY_MULTIPASS = gql`
  query UserMultipass($returnTo: String) {
    myMultipass(returnTo: $returnTo) {
      token
      url
    }
  }
`;

export const REGISTER_MEMBER = gql`
  mutation RegisterMember(
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $allows_sms_contact: Boolean
    $business_address1: String!
    $business_address2: String
    $business_city: String!
    $business_state: String!
    $business_zip: String!
    $member_group: ID
    $shopify_tags: [String]
    $shopify_note: String
    $password: String!
    $mailing_list_id: String
    $receive_fandeck: Boolean
  ) {
    createMailingListSubscription(
      input: { email: $email, listId: $mailing_list_id }
    ) {
      message
    }
    registerMember(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone: $phone
        business_address1: $business_address1
        business_address2: $business_address2
        business_city: $business_city
        business_state: $business_state
        business_zip: $business_zip
        member_group: $member_group
        shopify_tags: $shopify_tags
        shopify_note: $shopify_note
        allows_sms_contact: $allows_sms_contact
        password: $password
        receive_fandeck: $receive_fandeck
      }
    ) {
      member {
        id
        first_name
        last_name
        email
        phone
      }
      token
    }
  }
`;

export const GET_FINAL_MEMBER_JWT = gql`
  query FinalMemberJwt($token: String!) {
    finalMemberJwt(token: $token) {
      token
      member {
        id
        first_name
        referral_code
      }
    }
  }
`;

export const CHECK_MEMBER_USER_STATUS = gql`
  query CheckMemberUserStatus($email: String!) {
    checkMemberUserStatus(email: $email) {
      has_set_password
      user_exists
    }
  }
`;

export const CREATE_CUSTOMER_AND_SOLICIT_MEMBER_DISCOUNT = gql`
  mutation CreateCustomerAndSolicitMemberDiscount(
    $discountInput: MemberLeadInput
    $customerInput: CreateShopifyCustomerInput
    $email: String
    $mailing_list_id: String
  ) {
    createMailingListSubscription(
      input: { email: $email, listId: $mailing_list_id }
    ) {
      message
    }
    createShopifyCustomer(input: $customerInput)
    solicitMemberDiscount(input: $discountInput) {
      id
      first_name
      last_name
      email
      referral_code
    }
  }
`;
