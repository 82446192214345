import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import styled from 'styled-components';

import SmartLink from './SmartLink';
import ImgixImage from './ImgixImage';
import getStoryblokLinkUrl from '../lib/getStoryblokLinkUrl';

const TextLink = styled(SmartLink)`
  padding-top: ${(p) => p.theme.global.breakpoints.small.edgeSize.small};
  padding-bottom: ${(p) => p.theme.global.breakpoints.small.edgeSize.small};

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: 0;
  } ;
`;

function HeaderSectionMenuItem({ link, text, _uid, image }) {
  const url = getStoryblokLinkUrl(link);
  return (
    <Box>
      {image?.filename ? (
        <Box fill gap="medium">
          <SmartLink to={url}>
            <ImgixImage
              alignSelf="start"
              key={image.id}
              src={image.filename}
              fit={'cover'}
              fill={true}
              title={image.alt || image.name || image.title}
              alt={image.name || image.alt || image.title}
              sizes="(max-width: 768px) 80vw, 33vw"
              lazy={true}
              srcSetOptions={{
                fit: 'crop',
                crop: 'entropy',
                auto: ['compress', 'format'],
                ar: '1:1',
                q: '80',
              }}
            />
          </SmartLink>
          <SmartLink to={url} plain>
            <Text size="medium" weight={500} style={{ letterSpacing: '1px' }}>
              {text}
            </Text>
          </SmartLink>
        </Box>
      ) : (
        <TextLink plain to={url} key={_uid}>
          <Text>{text}</Text>
        </TextLink>
      )}
    </Box>
  );
}

HeaderSectionMenuItem.propTypes = {
  link: PropTypes.object,
  text: PropTypes.string,
  _uid: PropTypes.string,
  image: PropTypes.object,
  index: PropTypes.number,
};

export default HeaderSectionMenuItem;
