import PropTypes from 'prop-types';

import useRichText from '../useRichText';

const Announcement = ({ content }) => {
  const rendered = useRichText(
    content,
    {},
    { size: 'small', margin: '0', style: { maxWidth: 'none' } }
  );
  return rendered;
};

Announcement.propTypes = {
  component: PropTypes.string,
  content: PropTypes.object,
};

export default Announcement;
