import { createSlice } from '@reduxjs/toolkit';
import { reject, isNil } from '../../lib/nodash';
import { track } from '../../lib/analytics';
import { PRODUCT_LIST_FILTER_ENABLED } from '../../lib/analytics/segmentActions';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    scrolledToTop: true,
    sampleCartActive: false,
    announcementBarOpen: true,
    announcementBarSize: 37,
    activeModal: null,
    buybarHeight: 0,
    filterPanelActive: false,
    hasFilter: false,
    toasts: [],
    navHeight: 58,
    navPinned: false,
    isDark: false,
    sampleCartHeight: 60,
    sampleCartCustomScrollThreshold: false,
    isScrolledPastSampleCartCustomScrollThreshold: false,
    activeHeaderSubMenu: null,
    activeHeaderSubMenuItem: null,
    activeHeaderSubMenuSubItem: null,
    activeSubmenus: {},
    mainMenuActive: false,
    galleryListRef: null,
    hasAudio: false,
    audioEnabled: true,
    audioPlaying: false,
  },
  reducers: {
    setScrolledToTop: (state, action) => {
      state.scrolledToTop = action.payload;
    },
    setActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
    setBuybarHeight: (state, action) => {
      state.buybarHeight = action.payload;
    },
    setSampleCartActive: (state, action) => {
      state.sampleCartActive = action.payload;
    },
    toggleFilterPanel: (state) => {
      state.filterPanelActive = !state.filterPanelActive;
      if (state.filterPanelActive) {
        track(PRODUCT_LIST_FILTER_ENABLED);
      }
    },
    closeFilterPanel: (state) => {
      state.filterPanelActive = false;
    },
    enableFilter: (state) => {
      state.hasFilter = true;
    },
    disableFilter: (state) => {
      state.hasFilter = false;
    },
    popToast: (state, action) => {
      state.toasts = [
        ...reject({ id: action.payload.id }, state.toasts),
        action.payload,
      ];
    },
    closeToast: (state, action) => {
      state.toasts = reject({ id: action.payload.id }, state.toasts);
    },
    closeAnnouncementBar: (state) => {
      state.announcementBarOpen = false;
    },
    setAnnouncementBarSize: (state, action) => {
      state.announcementBarSize = action.payload;
    },
    setNavHeight: (state, action) => {
      state.navHeight = action.payload;
    },
    setDark: (state, action) => {
      state.isDark = action.payload;
    },
    setSampleCartHeight: (state, action) => {
      state.sampleCartHeight = action.payload;
    },
    setActiveHeaderSubMenu: (state, action) => {
      state.activeHeaderSubMenu = action.payload;
    },
    setActiveHeaderSubMenuItem: (state, action) => {
      state.activeHeaderSubMenuItem = action.payload;
    },
    setActiveHeaderSubMenuSubItem: (state, action) => {
      state.activeHeaderSubMenuSubItem = action.payload;
    },
    setMainMenuActive: (state, action) => {
      state.mainMenuActive = action.payload;
    },
    toggleMainMenuActive: (state) => {
      state.mainMenuActive = !state.mainMenuActive;
    },
    toggleActiveSubmenu: (state, action) => {
      state.activeSubmenus[action.payload] = !(isNil(
        state.activeSubmenus[action.payload]
      )
        ? false
        : state.activeSubmenus[action.payload]);
    },
    resetActiveSubmenus: (state) => {
      state.activeSubmenus = {};
    },
    setGalleryListRef: (state, action) => {
      state.galleryListRef = action.payload;
    },
    setIsScrolledPastSampleCartCustomScrollThreshold: (state, action) => {
      state.isScrolledPastSampleCartCustomScrollThreshold = action.payload;
    },
    setSampleCartCustomScrollThreshold: (state, action) => {
      state.sampleCartCustomScrollThreshold = action.payload;
    },
    setNavPinned: (state, action) => {
      state.navPinned = action.payload;
    },
    setHasAudio: (state, action) => {
      state.hasAudio = action.payload;
    },
    setAudioPlaying: (state, action) => {
      state.audioPlaying = action.payload;
    },
    setAudioEnabled: (state, action) => {
      state.audioEnabled = action.payload;
    },
  },
});

export const {
  setScrolledToTop,
  setActiveModal,
  setBuybarHeight,
  setSampleCartActive,
  toggleFilterPanel,
  closeFilterPanel,
  enableFilter,
  disableFilter,
  popToast,
  closeToast,
  closeAnnouncementBar,
  setAnnouncementBarSize,
  setNavHeight,
  setNavPinned,
  setDark,
  setSampleCartHeight,
  setActiveHeaderSubMenu,
  setActiveHeaderSubMenuItem,
  setActiveHeaderSubMenuSubItem,
  setMainMenuActive,
  toggleMainMenuActive,
  toggleActiveSubmenu,
  setGalleryListRef,
  setIsScrolledPastSampleCartCustomScrollThreshold,
  setSampleCartCustomScrollThreshold,
  resetActiveSubmenus,
  setHasAudio,
  setAudioPlaying,
  setAudioEnabled,
} = uiSlice.actions;

export default uiSlice.reducer;
