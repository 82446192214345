import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import {
  storyblokInit,
  // useStoryblokState,
} from 'gatsby-source-storyblok';

import { popToast } from '../state/ui/uiSlice';
import { updateStory, setEditMode } from '../state/storyblok/storyblokSlice';
import { CONTENT_NODES_BY_FULL_SLUG } from '../queries/storyblokQueries';
import { draftStoryblokClient } from '../gatsby-theme-apollo/client';

import dynamicComponents from '../components/dynamicComponents';

export function useStoryblokState(originalStory = {}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const parsedStory = useMemo(() => {
    return typeof originalStory?.content === 'string'
      ? { ...originalStory, content: JSON.parse(originalStory?.content) }
      : originalStory;
  }, [originalStory]);

  const [uuid, setUuid] = useState(parsedStory);

  const story =
    useSelector((state) => {
      return state.storyblok.stories[uuid];
    }) || parsedStory;

  useQuery(CONTENT_NODES_BY_FULL_SLUG, {
    variables: {
      full_slug: originalStory?.full_slug || location.pathname.replace('/', ''),
    },
    client: draftStoryblokClient,
    skip: !location.search.includes('_storyblok'),
    onCompleted: (data) => {
      const entry = (data?.ContentNodes?.items || [])[0];
      if (entry) {
        setUuid(entry.uuid);
        dispatch(updateStory(entry));
      }
    },
  });

  return story;
}

export function useStoryblok() {
  const location = useLocation();
  const dispatch = useDispatch();

  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  function initEventListeners() {
    const { StoryblokBridge } = window;

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        preventClicks: true,
      });

      storyblokInstance.on(['published'], () => {
        dispatch(
          popToast({
            title: 'Build Triggered',
            body: 'Content has been saved and a build has been triggered.',
            id: 'storyblok-saved',
          })
        );
      });

      storyblokInstance.on(['input'], (event) => {
        dispatch(updateStory(event.story));
      });

      storyblokInstance.on(['enterEditmode'], (event) => {
        // loading the draft version on initial view of the page
        if (event.storyId) {
          dispatch(setEditMode(true));
        }
      });
    }
  }

  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // call a function once the bridge is loaded
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // load bridge only inside the storyblok editor
    if (location.search.includes('_storyblok')) {
      // first load the bridge and then attach the events
      addBridge(initEventListeners);
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment
}

export function initStoryblok() {
  storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
    components: dynamicComponents,
    // use: [apiPlugin],
    bridge: false, // see https://github.com/storyblok/storyblok-js/issues/65
  });
}
