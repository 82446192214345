export const MADE_TO_ORDER_TYPES = ['Exterior Semi-Gloss', 'Exterior Standard'];

export const COLOR_PAINT_PRODUCT_TYPES = [
  'Interior Standard',
  'Interior Semi-Gloss',
  'Exterior Standard',
  'Exterior Semi-Gloss',
  'Cabinet & Door',
];

export const PAINT_PRODUCT_TYPES = [...COLOR_PAINT_PRODUCT_TYPES, 'Primer'];

export const SUPPLY_PRODUCT_TYPES = [
  'Supplies',
  'Tools',
  'Toolskit',
  'Prepkit',
];

export const APPAREL_TYPES = ['Apparel'];

export const isPaintType = (type) => PAINT_PRODUCT_TYPES.indexOf(type) > -1;
export const isApparelType = (type) => APPAREL_TYPES.indexOf(type) > -1;
