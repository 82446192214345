import { createSlice } from '@reduxjs/toolkit';
import { head, difference, uniq } from '../../lib/nodash';
import cookieStore from '../../lib/cookieStore';

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: {
    activeProductId: null,
    activeProductGroup: null,
    step: 'form', // Active view of app
    sqFt: {}, // ID map of sqFt per product
    gallonOverrides: {}, // ID map of mutable gallon values per item
    selectedProductIds: [],
    calculatedProducts: [], // ids for products
    customerName: null,
    browsePaint: false,
    calcVariables: {
      people: 1,
      areaType: 'room',
      doors: 0,
      windows: 0,
      length: 0,
      width: 0,
      height: 0,
    },
    activeDiscounts: [],
    discountPerGallon: 0,
    centsOffSupplies: 0,
    centsOffPaint: 0,
    gallonsToPaintDiscount: 4,
    ordersToken: null,
    skippedCalc: false,
    continueTo: null,
  },
  reducers: {
    setPeople: (state, action) => {
      state.calcVariables.people = action.payload;
    },
    setProductId: (state, action) => {
      state.activeProductId = action.payload;
    },
    returnToForm: (state) => {
      state.step = 'form';
      state.calculatedProducts = [];
    },
    submitCalculationValues: (state, action) => {
      const { payload } = action;
      const calculatedProducts = uniq([
        ...(state.calculatedProducts || []),
        payload.productId,
      ]);

      const remaining = difference(
        state.selectedProductIds || [],
        calculatedProducts || []
      );

      const moreProds = remaining.length > 0;
      const step = moreProds ? 'form' : 'results';
      const activeProductId = moreProds
        ? head(remaining)
        : state.activeProductId;

      state.calculatedProducts = calculatedProducts;
      state.step = step;
      state.activeProductId = activeProductId;
      state.calcVariables = {
        ...state.calcVariables,
        ...payload.calcVariables,
      };
      state.sqFt = Object.assign(state.sqFt || {}, {
        [payload.productId]: payload.calculations.sqFt,
      });
      state.gallonOverrides = {};
      state.hasPeopleCount = true;
    },
    overrideGallons: (state, action) => {
      const { payload } = action;
      state.gallonOverrides = {
        ...state.gallonOverrides,
        ...payload.values,
      };
    },
    browseProducts: (state, action) => {
      const { payload } = action;
      state.activeProductId = undefined;
      state.step = 'choose';
      state.browsePaint = true;
      if (payload && payload.continueTo) {
        state.continueTo = payload.continueTo;
      }
    },
    closeBrowseProducts: (state) => {
      state.activeProductId = undefined;
      state.step = 'form';
      state.browsePaint = false;
      state.gallons = {};
    },
    confirmSelectedProducts: (state, action) => {
      state.selectedProductIds = action.payload;
      state.activeProductId = action.payload[0];
      state.browsePaint = false;
      state.step = state.continueTo || 'form';
    },
    checkoutSelectedProducts: (state, action) => {
      const { payload } = action;
      const defaultSqFt = payload.reduce((mem, x) => {
        mem[x] = state.sqFt['_generic'] || 1;
        return mem;
      }, {});

      state.activeProductId = head(payload);
      state.calculatedProducts = payload;
      state.sqFt = defaultSqFt;
      state.selectedProductIds = payload;
      state.continueTo = undefined;
      state.skippedCalc = true;
      state.step = 'results';
    },
    navigateToStep: (state, action) => {
      state.activeProductId = head(state.selectedProductIds);
      state.step = action.payload;
      if (action.payload === 'form') {
        state.calculatedProducts = [];
        state.sqFt = {};
        state.calcVariables = {
          people: 1,
          areaType: 'room',
          doors: 0,
          windows: 0,
          length: 0,
          width: 0,
          height: 0,
        };
      }
      if (action.payload === 'choose') {
        state.calculatedProducts = [];
        state.selectedProductIds = [];
        state.sqFt = {};
      }
    },
    reset: (state) => {
      state.step = 'form';
      state.continueTo = undefined;
      state.calcVariables = {
        people: cookieStore.get('backdrop-people-count') || 1,
        areaType: 'room',
        doors: 0,
        windows: 0,
        length: 0,
        width: 0,
        height: 0,
      };
      state.activeProductId = null;
      state.selectedProductIds = [];
      state.calculatedProducts = [];
      state.browsePaint = false;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
  },
});

export const {
  setPeople,
  setProductId,
  submitCalculationValues,
  returnToForm,
  updateCheckoutQuantities,
  overrideGallons,
  browseProducts,
  closeBrowseProducts,
  confirmSelectedProducts,
  checkoutSelectedProducts,
  navigateToStep,
  reset,
  setCustomerName,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
