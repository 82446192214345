import { decode } from 'base-64';
import { contains, isNil, isEqual } from './nodash';

const extractGid = (gid, gIdCategory) => {
  if (gid === '_generic' || isNil(gid) || isEqual('undefined', gid)) {
    return gid;
  }
  const parsedGid = contains('gid://', gid) ? gid : decode(gid);
  return gIdCategory
    ? parseInt(parsedGid.split(`gid://shopify/${gIdCategory}/`)[1])
    : parsedGid;
};

export default extractGid;
