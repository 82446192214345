import { get, isEqual } from '../../lib/nodash';

const useCmsProductFieldShopifyId = (relatedProductField) => {
  const shopifyId = relatedProductField
    ? isEqual('bd-shopify-product-picker', relatedProductField.plugin)
      ? get('item.shopifyId', relatedProductField)
      : relatedProductField
    : null;
  return shopifyId;
};

export default useCmsProductFieldShopifyId;
