import {
  map,
  get,
  find,
  isEqual,
  filter,
  isNil,
  reject,
  some,
} from '../nodash';

import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { GET_CHECKOUT } from '../../queries/cartQueries';
import { GET_PRODUCT_VARIANTS_BY_IDS } from '../../queries/productVariantQueries';
import { isSample, isMemo } from '../../lib/product';
import checkVariantCartLimits from '../../lib/cart/checkVariantCartLimits';
import encodeGid from '../../lib/encodeGid';
import extractGid from '../../lib/extractGid';
import deNodify from '../../lib/deNodify';

const getQtyChange = (item, cartLineItems, lineItems, isAbsoluteQty) => {
  const { id, quantity } = item;
  if (!id) {
    return quantity;
  }
  const qtyInCart = get('quantity', find({ id }, cartLineItems)) || 0;
  const desiredQty = isAbsoluteQty
    ? get('quantity', find({ id }, lineItems)) || 0
    : qtyInCart + get('quantity', find({ id }, lineItems));
  return desiredQty - qtyInCart;
};

const checkLimits = async (
  lineItems,
  checkoutId,
  isAbsoluteQty,
  options = {}
) => {
  const { data: checkoutData } = await shopifyClient.query({
    query: GET_CHECKOUT,
    variables: { id: checkoutId },
  });
  const cartLineItems = deNodify(get('node.lineItems', checkoutData));
  const qtyChanges = map(
    (x) => getQtyChange(x, cartLineItems, lineItems, isAbsoluteQty),
    lineItems
  );

  const isAdding =
    lineItems.filter((x) => !x.id).length > 0 || some((x) => x > 0, qtyChanges);

  const onlyRemoving = !isAdding && qtyChanges.every((x) => x <= 0);

  if (onlyRemoving) {
    // Skip request for variant details
    return {
      toChange: map((x) => {
        const gid = encodeGid(x.variantId);
        const qtyInCart =
          get(
            'quantity',
            find((y) => isEqual(gid, get('variant.id', y)), cartLineItems)
          ) || 0;

        return {
          ...x,
          quantity: isAbsoluteQty ? x.quantity : qtyInCart + x.quantity,
        };
      }, lineItems),
      errors: [],
    };
  }
  const { data: variantData } = await shopifyClient.query({
    query: GET_PRODUCT_VARIANTS_BY_IDS,
    fetchPolicy: 'network-only',
    variables: {
      ids: map(encodeGid, map('variantId', lineItems)),
    },
  });

  const variantsDetails = map((x) => {
    const gid = extractGid(x.variantId);
    const qtyInCart =
      get(
        'quantity',
        find((y) => isEqual(gid, get('variant.id', y)), cartLineItems)
      ) || 0;
    return {
      inventoryPolicy: options.inventoryPolicies?.[x.variantId],
      inCart: qtyInCart,
      quantity: isAbsoluteQty ? x.quantity : qtyInCart + x.quantity,
      variantData: find(
        (x) => extractGid(x.id) === extractGid(gid),
        get('nodes', variantData)
      ),
      variantId: x.variantId,
    };
  }, lineItems);

  const checked = map((x) => {
    return {
      variantId: x.variantId,
      quantity: x.quantity,
      cartAddError: checkVariantCartLimits(x, cartLineItems),
    };
  }, variantsDetails);

  const acceptableVariantIds = map(
    'variantId',
    filter((x) => isNil(get('cartAddError', x)), checked)
  );

  const toChange = map(
    (x) => ({ ...x, variantId: encodeGid(x.variantId) }),
    filter((x) => acceptableVariantIds.indexOf(x.variantId) > -1, lineItems)
  );

  const errors = map(
    'cartAddError',
    reject((x) => isNil(get('cartAddError', x)), checked)
  );

  const samples = filter(
    (x) => isSample(x) || isMemo(x),
    map('variantData', variantsDetails)
  );

  return {
    toChange,
    errors,
    samples,
    variantData: deNodify(variantData.nodes),
  };
};

export default checkLimits;
