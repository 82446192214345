import styled from 'styled-components';
import generateGridColumnsStyle from '../lib/generateGridColumnsStyle';
import { Grid } from 'grommet';

const ResponsiveGrid = styled(Grid)`
  ${(p) => generateGridColumnsStyle({ ...p, columns: p.mobileColumns })}

  @media only screen and (min-width: ${(p) =>
    p.theme.global.breakpoints.small.value + 1}px) {
    ${(p) => generateGridColumnsStyle(p)}
  }
`;

export default ResponsiveGrid;
