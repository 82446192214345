import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Button } from 'grommet';
import { FormDown } from 'grommet-icons';

import { isObject } from '../lib/nodash';
import { getProductPath } from '../lib/product';
import SmartLink from './SmartLink';
import TextTitle from './TextTitle';
import DynamicIcon from './Icons/DynamicIcon';
import MemberAccountCredit from './Industry/MemberAccountCredit';

const HeaderSubMenuItemLink = ({
  link,
  text,
  secondaryText,
  member,
  icon,
  _uid,
  activeItem,
  isSubmenu,
  product,
}) => {
  const template = useCallback(
    (string) => {
      return string
        .replace(/{{ firstName }}/gm, member?.firstName ? member.firstName : '')
        .replace(/{{ referralCode }}/gm, member ? member.referral_code : '')
        .replace(/{{ credit }}/gm, '');
    },
    [member]
  );
  const isActiveSubmenu =
    _uid && activeItem && _uid === activeItem && isSubmenu;
  const url = product?.item
    ? getProductPath(product.item)
    : link.cached_url?.charAt(0) === '/'
    ? link.cached_url
    : `/${link.cached_url}`;
  return (
    <Box>
      <SmartLink
        plain
        to={url}
        as={Button}
        reverse={isActiveSubmenu}
        icon={
          isActiveSubmenu ? (
            <FormDown color="black" />
          ) : icon ? (
            isObject(icon) ? (
              icon
            ) : (
              <Box flex={false} overflow="hidden" align="center">
                <DynamicIcon
                  type={icon}
                  color="brand"
                  size="medium"
                  // style={{ width: '40px' }}
                />
              </Box>
            )
          ) : null
        }
        justify="start"
        label={
          <Box direction="row-responsive" gap="small" justify="between">
            <TextTitle>{text}</TextTitle>
            {secondaryText && <Text>{template(secondaryText)}</Text>}
            {secondaryText === '{{ credit }}' && (
              <Text>
                $<MemberAccountCredit layout="inline" />
              </Text>
            )}
          </Box>
        }
      ></SmartLink>
    </Box>
  );
};

HeaderSubMenuItemLink.propTypes = {
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  text: PropTypes.string,
  secondaryText: PropTypes.string,
  member: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  activeItem: PropTypes.string,
  _uid: PropTypes.string,
  isSubmenu: PropTypes.bool,
  onActivate: PropTypes.func,
  product: PropTypes.object,
};

export default memo(HeaderSubMenuItemLink);
