import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { omit } from '../../lib/nodash';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { Box } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';

const DynamicGridItem = ({ blok, index, colCount, ...rest }) => {
  const {
    totalCols,
    horizontalAlignment,
    verticalAlignment,
    borderBetween,
    isSticky,
    stickyOffset,
  } = blok;
  const hasVBorderSeparator =
    borderBetween &&
    index > 0 &&
    index % colCount > 0 &&
    (index + 1) % colCount < colCount;

  const rowCount = Math.ceil(totalCols / colCount);

  const lastRowThreshold = rowCount * colCount - colCount;
  const inLastRow = index + 1 > lastRowThreshold;
  const hasHBorderSeparator = borderBetween && rowCount > 1 && !inLastRow;

  const borderSides = [];
  if (colCount > 1 && hasVBorderSeparator) {
    borderSides.push('left');
  }
  if (hasHBorderSeparator) {
    borderSides.push('bottom');
  }
  const borders = borderSides.map((x) => ({
    size: 'small',
    color: 'black',
    side: x,
  }));

  return (
    <Box
      {...storyblokEditable(blok)}
      justify={verticalAlignment}
      align={horizontalAlignment}
      flex={false}
      border={borders.length ? borders : undefined}
      className="dynamic-grid-item"
      fill={rest.fill}
      style={{
        position: isSticky ? 'sticky' : undefined,
        top: isSticky ? stickyOffset ?? 0 : undefined,
      }}
    >
      {blok.reactComponent ? (
        blok.reactComponent
      ) : (
        <StoryblokComponent
          key={blok.uuid}
          blok={omit(['borderBetween', 'mobileBorderBetween'], blok)}
          flex={true}
          {...omit(['fill'], rest)}
        />
      )}
    </Box>
  );
};

DynamicGridItem.propTypes = {
  colCount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  blok: PropTypes.object.isRequired,
  borderBetween: PropTypes.bool,
  totalCols: PropTypes.number.isRequired,
  horizontalAlignment: PropTypes.oneOf(['start', 'center', 'end']),
  verticalAlignment: PropTypes.oneOf(['start', 'center', 'end']),
};

export default memo(DynamicGridItem);
