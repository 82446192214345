import React from 'react';
import Helmet from 'react-helmet';
import usePrefersColorScheme from './usePrefersColorScheme';

const Favicon = () => {
  const colorScheme = usePrefersColorScheme();
  const iconSrc =
    colorScheme === 'dark'
      ? '//cdn.shopify.com/s/files/1/0051/6295/8946/files/harvestmoon_-_Blob1_Web_32x32.png?v=1580766609'
      : '//cdn.shopify.com/s/files/1/0051/6295/8946/files/Darkarts_-_Blob5_Web_32x32.png?v=1540315402';
  return (
    <Helmet>
      <link rel="shortcut icon" href={iconSrc} type="image/png" />
    </Helmet>
  );
};

export default Favicon;
