import { createSlice } from '@reduxjs/toolkit';

export const storyblokSlice = createSlice({
  name: 'storyblok',
  initialState: {
    stories: {},
    editMode: false,
  },
  reducers: {
    updateStory: (state, action) => {
      state.stories[action.payload.uuid] = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
  },
});

export const { updateStory, setEditMode } = storyblokSlice.actions;

export default storyblokSlice.reducer;
