import { useContext } from 'react';
import { ThemeContext } from 'grommet';
import useMobile from './useMobile';

export default function useAdjustedHeadingSize(size) {
  const theme = useContext(ThemeContext);
  const isMobile = useMobile();
  return size
    ? {
        fontSize:
          theme.heading.level[isMobile ? parseInt(size) + 1 : size].medium.size,
        lineHeight:
          theme.heading.level[isMobile ? parseInt(size) + 1 : size].medium
            .height,
      }
    : {};
}
