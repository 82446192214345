import fetch from 'isomorphic-fetch';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cookieStore from '../lib/cookieStore';
import backdropCache from '../lib/backdropCache';

const storefrontApiUri =
  'https://backdrop-home.myshopify.com/api/2023-07/graphql.json';
const storefrontApiToken = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN;

export const shopifyClient = new ApolloClient({
  uri: storefrontApiUri,
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': storefrontApiToken,
  },
});

export const storyblokClient = new ApolloClient({
  uri:
    process.env.GATSBY_STORYBLOK_GRAPHQL_API_URL ||
    'https://gapi.storyblok.com/v1/api',
  cache: new InMemoryCache(),
  headers: {
    Token: process.env.GATSBY_STORYBLOK_TOKEN,
    Version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
  },
});

export const draftStoryblokClient = new ApolloClient({
  uri:
    process.env.GATSBY_STORYBLOK_GRAPHQL_API_URL ||
    'https://gapi.storyblok.com/v1/api',
  cache: new InMemoryCache(),
  headers: {
    Token: process.env.GATSBY_STORYBLOK_TOKEN,
    Version: 'draft',
  },
});

const getToken = () => {
  const token = cookieStore.get('backdrop-api-token');
  return token && token !== 'null' ? `Bearer ${token}` : '';
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
      'X-Backdrop-Api-Key': process.env.GATSBY_BACKDROP_API_KEY,
    },
  };
});

const backdropApiUri = `${process.env.GATSBY_BACKDROP_API_URI}/graphql`;

const httpLink = createHttpLink({
  uri: backdropApiUri,
  fetch,
});

export const backdropApiClient = new ApolloClient({
  uri: backdropApiUri,
  cache: backdropCache,
  link: authLink.concat(httpLink),
});

export default backdropApiClient;
