import gql from 'graphql-tag';

export const SHOP_METAFIELDS = gql`
  query StorefrontMetafields {
    shop {
      mto_disclaimer: metafield(key: "mto_disclaimer", namespace: "globals") {
        value
      }
      wallcoverings_trad_disclaimer: metafield(
        key: "wallcoverings_traditional_roll_cart_disclaimer"
        namespace: "globals"
      ) {
        value
      }
    }
  }
`;

export default SHOP_METAFIELDS;
