import { createSelector } from 'reselect';

const selectDiscountCode = createSelector(
  (state) => state.cart,
  (cart) => {
    return cart.discountCode;
  }
);

export default selectDiscountCode;
