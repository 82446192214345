import { useSelector } from 'react-redux';

import selectMember from '../../state/industry/selectMember';

function useMember() {
  const member = useSelector(selectMember);

  return member;
}

export default useMember;
