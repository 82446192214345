import { map, toLower } from './nodash';
import snakeCase from 'lodash.snakecase';
export const INTERIOR_STANDARD = 'Interior Standard';
export const INTERIOR_SEMIGLOSS = 'Interior Semi-Gloss';
export const EXTERIOR_STANDARD = 'Exterior Standard';
export const EXTERIOR_SEMIGLOSS = 'Exterior Semi-Gloss';
export const CABINET_DOOR = 'Cabinet & Door';

export const COLOR_PAINT_PRODUCT_TYPES = [
  INTERIOR_STANDARD,
  INTERIOR_SEMIGLOSS,
  EXTERIOR_STANDARD,
  EXTERIOR_SEMIGLOSS,
  CABINET_DOOR,
];

export const PAINT_PRODUCT_TYPES = [
  ...COLOR_PAINT_PRODUCT_TYPES,
  'Primer',
  'paint',
];

export const APPAREL_TYPES = ['Apparel'];

export const SUPPLY_PRODUCT_TYPES = [
  'Supplies',
  'Tools',
  'Toolskit',
  'Prepkit',
  'Fandeck',
  ...APPAREL_TYPES,
];
export const WALLCOVERING_PRODUCT_TYPES = ['Wallcoverings'];

export const isPaintType = (type) =>
  map(snakeCase, PAINT_PRODUCT_TYPES).indexOf(snakeCase(type)) > -1;
export const isApparelType = (type) =>
  map(toLower, APPAREL_TYPES).indexOf(toLower(type)) > -1;
export const isWallcoveringType = (type) =>
  map(toLower, WALLCOVERING_PRODUCT_TYPES).indexOf(toLower(type)) > -1;

export const NON_RETURNABLE_TYPES = ['Donation', ...APPAREL_TYPES];
