import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { getClientForUrl, getKeyFromUrl } from '../lib/imgix';
import { Image, ImageProps } from 'grommet';
import 'lazysizes';

type Props = {
  src: string;
  options?: any;
  srcSetOptions?: any;
  srcSetRanges?: any;
  lazy?: boolean;
  lazySrc?: string;
  sizes?: string;
  pixelSwap?: boolean;
  alignSelf?: 'start' | 'center' | 'end' | 'stretch';
} & ImageProps & {
    className?: string;
  };

const ImgixImage = ({
  src,
  options = { q: 80 },
  srcSetOptions,
  srcSetRanges = {},
  lazy,
  lazySrc,
  sizes,
  pixelSwap,
  alignSelf,
  ...rest
}: Props) => {
  const client = getClientForUrl(src);
  const srcSet =
    useMemo(
      () =>
        client
          ? srcSetOptions
            ? client.buildSrcSet(
                getKeyFromUrl(src) ?? src,
                srcSetOptions,
                srcSetRanges
              )
            : null
          : null,
      [src, srcSetOptions, srcSetRanges]
    ) ?? undefined;
  const sourceUrl = useMemo(
    () => (client ? client.buildURL(getKeyFromUrl(src) ?? src, options) : src),
    [client, src, options]
  );
  const pixelHero = useMemo(
    () =>
      pixelSwap && client
        ? client.buildURL(getKeyFromUrl(src) ?? src, {
            q: 90,
            maxw: 1500,
            px: 400,
            auto: ['compress', 'format'],
          })
        : undefined,
    [src, pixelSwap]
  );

  return src ? (
    <>
      <Image
        alignSelf={alignSelf}
        srcSet={lazy ? undefined : srcSet}
        src={
          pixelSwap
            ? pixelHero
            : lazy
            ? lazySrc
              ? lazySrc
              : undefined
            : sourceUrl
        }
        data-src={sourceUrl}
        data-srcset={srcSet}
        data-sizes={sizes}
        sizes={sizes}
        {...rest}
        className={`${rest.className ? rest.className : ''} ${
          pixelSwap || lazy ? 'lazyload' : ''
        }`}
      />
      {lazy && (
        <noscript>
          <Image
            alignSelf={alignSelf}
            srcSet={lazy ? undefined : srcSet}
            src={sourceUrl}
            sizes={sizes}
            {...rest}
            className={rest.className}
          />
        </noscript>
      )}
    </>
  ) : null;
};

ImgixImage.propTypes = {
  src: PropTypes.string,
  options: PropTypes.object,
  srcSetOptions: PropTypes.object,
  srcSetRanges: PropTypes.object,
  pixelSwap: PropTypes.bool,
  lazy: PropTypes.bool,
  lazySrc: PropTypes.string,
  sizes: PropTypes.string,
  alignSelf: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
};

export default memo(ImgixImage);
