// General use Application-level Modal

import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, isNil, get, includes, head } from '../lib/nodash';
import { Box } from 'grommet';
import loadable from '@loadable/component';
import { StoryblokComponent } from 'gatsby-source-storyblok';

import { setActiveModal } from '../state/ui/uiSlice';

import Modal from './Modal';

const FormRestockNotification = loadable(() =>
  import('./Product/FormRestockNotification')
);
const FormGiftCard = loadable(() => import('./Product/FormGiftCard'));
const DataStoryblokStory = loadable(() =>
  import('./DynamicPage/DataStoryblokStory')
);
const ChooseTouchUpKitPaint = loadable(() =>
  import('./Paint/ChooseTouchUpKitPaint')
);
const ModalCampaign = loadable(() => import('./Campaigns/ModalCampaign'));

const getComponent = (value, meta) => {
  switch (value) {
    case 'product-restock-notification':
      return <FormRestockNotification {...meta} />;
    case 'gift-card-form':
      return <FormGiftCard width="large" pad="large" {...meta} />;
    case 'choose-touch-up-kit-paint':
      return <ChooseTouchUpKitPaint />;
    case 'campaign':
      return <ModalCampaign {...meta} />;
    default:
      return value;
  }
};

const ModalApplication = () => {
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.ui.activeModal);
  const content = get('content', activeModal) || get('body', activeModal);

  if (isNil(activeModal)) {
    return null;
  }
  const handleClose = () => {
    dispatch(setActiveModal(null));
    if (activeModal.meta?.withCloseMemory) {
      localStorage.setItem(activeModal.meta.withCloseMemory, 'closed');
    }
  };

  const full =
    includes('horizontal', activeModal.fill) &&
    includes('vertical', activeModal.fill)
      ? true
      : head(activeModal.fill);

  const body =
    isEqual('content', get('type', activeModal)) && get('value', activeModal);
  const component = get('component', activeModal) ? (
    <>
      {content.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </>
  ) : (
    isEqual('component', get('type', activeModal)) &&
    getComponent(get('value', activeModal), get('meta', activeModal))
  );

  if (!component && !body && !activeModal.story) {
    return null;
  }
  return (
    <Modal
      body={body}
      title={get('title', activeModal)}
      onClose={handleClose}
      position={activeModal.position}
      full={full}
      responsive={activeModal.responsive || false}
    >
      <Box fill overflow="auto">
        {activeModal.story ? (
          <div>
            <DataStoryblokStory id={activeModal.story} />
          </div>
        ) : (
          component
        )}
      </Box>
    </Modal>
  );
};

export default memo(ModalApplication);
