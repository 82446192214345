import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { storyblokEditable } from 'gatsby-source-storyblok';

import useCmsProductFieldShopifyId from './useCmsProductFieldShopifyId';
const ImageContentModule = loadable(() => import('./ImageContentModule'));
const ImageContentModuleWithProduct = loadable(() =>
  import('./ImageContentModuleWithProduct')
);

const DynamicImageContentModule = ({ blok, ...rest }) => {
  const shopifyId = useCmsProductFieldShopifyId(blok.relatedProduct);

  return shopifyId ? (
    <ImageContentModuleWithProduct
      {...storyblokEditable(blok)}
      key={blok._uid}
      {...rest}
      {...blok}
    />
  ) : (
    <ImageContentModule
      {...storyblokEditable(blok)}
      key={blok._uid}
      {...rest}
      {...blok}
    />
  );
};

DynamicImageContentModule.propTypes = {
  blok: PropTypes.shape({
    image: PropTypes.object,
    fit: PropTypes.string,
    relatedProduct: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    _uid: PropTypes.string,
  }),
};

export default DynamicImageContentModule;
