import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Markdown, Text, Paragraph } from 'grommet';
import loadable from '@loadable/component';

const SmartLink = loadable(() => import('./SmartLink'));
const ImgixImage = loadable(() => import('./ImgixImage'));
const OrderedList = loadable(() => import('./OrderedList'));

const Strong = ({ children, ...rest }) => (
  <Text {...rest} weight={500}>
    {children}
  </Text>
);
Strong.propTypes = {
  children: PropTypes.node,
};

const MarkdownContent = ({ children, forceBlock = true, ...rest }) =>
  children ? (
    <Markdown
      options={{ forceBlock }}
      components={{
        a: SmartLink,
        img: ImgixImage,
        strong: Strong,
        ol: OrderedList,
        p: (c) => <Paragraph {...c} {...rest} />,
      }}
      {...rest}
    >
      {children}
    </Markdown>
  ) : null;

MarkdownContent.propTypes = {
  children: PropTypes.node.isRequired,
  forceBlock: PropTypes.bool,
};

export default memo(MarkdownContent);
