import React, { useContext, memo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Text, ThemeContext } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import { m } from 'framer-motion';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';

import { resetActiveSubmenus } from '../state/ui/uiSlice';

import MobileHeaderSubMenu from './MobileHeaderSubMenu';
import HeaderMenu from './HeaderMenu';
import HeaderMenuItem from './HeaderMenuItem';
import isVisibleByVisibilitySettings from '../lib/isVisibleByVisibilitySettings';
import { use100vh } from 'react-div-100vh';
import HeaderMenuSection from './HeaderMenuSection';

const MotionBox = m(Box);

const Container = styled(MotionBox)`
  left: 0;
  position: fixed;
  left: 0;
  top: ${(p) =>
    parseInt(p.theme.global.mobileMenuHeight) +
    parseInt(p.announcementBarSize) +
    parseInt(p.theme.global.borderSize.small)}px;
  zindex: 1002;
  height: 100vh;
`;

const mobileMenuVariants = {
  inactive: {
    x: '-100%',
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: 'circIn',
    },
  },
  active: {
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.25,
      ease: 'circOut',
    },
  },
};

const level2Variants = {
  visible: {
    x: 0,
    transition: {
      type: 'tween',
    },
  },
  invisible: {
    x: '100%',
    transition: {
      type: 'tween',
    },
  },
};

const outerVariants = {
  level1: {
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.25,
      ease: 'circOut',
    },
  },
  level2: {
    x: '-100%',
    transition: {
      type: 'tween',
      duration: 0.25,
      ease: 'circOut',
    },
  },
};

const MobileMainMenu = ({ menu, discountCode, member, jwt }) => {
  const theme = useContext(ThemeContext);
  const overflowRef = useRef(null);
  const overflowRef2 = useRef(null);
  const dispatch = useDispatch();
  const viewportHeight = use100vh();
  const mainMenuActive = useSelector((state) => state.ui.mainMenuActive);
  const activeSubmenus = useSelector((state) => state.ui.activeSubmenus);
  const hasActiveSubmenu = useSelector((state) =>
    Object.values(state.ui.activeSubmenus).some((x) => x === true)
  );
  const announcementBarSize = useSelector(
    (state) => state.ui.announcementBarSize
  );

  const level2Items = menu.items
    .filter((x) => x.component === 'Menu')
    .map((x) => x.items.map((z) => ({ ...z, parent: x })))
    .flat();
  const activeLevel1 = menu.items.find((x) =>
    Object.keys(activeSubmenus).includes(x._uid)
  );

  useEffect(() => {
    if (mainMenuActive && overflowRef.current && overflowRef2.current) {
      disableBodyScroll(overflowRef.current);
      disableBodyScroll(overflowRef2.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [mainMenuActive, overflowRef, overflowRef2]);

  const overflowHeight = `${
    viewportHeight -
    announcementBarSize -
    parseInt(theme.global.mobileMenuHeight)
  }px`;

  return (
    <Container
      className="mobile-menu"
      animate={mainMenuActive ? 'active' : 'inactive'}
      variants={mobileMenuVariants}
      initial="inactive"
      transition={{
        type: 'tween',
        ease: 'easeOut',
        duration: 0.2,
      }}
      fill="vertical"
      background="white"
      width={{ max: '80vw' }}
      overflow={{ horizontal: 'hidden' }}
      border={{ side: 'right', size: 'small' }}
      announcementBarSize={announcementBarSize}
    >
      <MotionBox
        variants={outerVariants}
        initial="level1"
        animate={hasActiveSubmenu ? 'level2' : 'level1'}
      >
        <Grid columns={{ count: 2, size: '100%' }}>
          <div
            ref={overflowRef}
            style={{
              overflow: 'auto',
              height: overflowHeight,
            }}
          >
            <Box flex={true}>
              {menu.items
                .filter((x) => {
                  return isVisibleByVisibilitySettings(x.visibility || [], {
                    discountCode,
                    member,
                    jwt,
                  });
                })
                .map((x) => {
                  return (
                    <HeaderMenu
                      key={x._uid}
                      {...x}
                      border={{ side: 'bottom', size: 'small' }}
                      pad="medium"
                      plain
                      headingLevel={3}
                      style={{
                        display: 'block',
                        padding: `${theme.global.edgeSize.small} ${theme.global.edgeSize.medium}`,
                        borderBottom: '2px solid #000',
                        textDecoration: 'none',
                      }}
                    />
                  );
                })}
            </Box>
          </div>
          <div
            ref={overflowRef2}
            style={{
              overflow: 'auto',
              height: overflowHeight,
            }}
          >
            <MotionBox variants={level2Variants}>
              <Button
                label={
                  <Box
                    justify="between"
                    align="center"
                    direction="row"
                    fill="horizontal"
                  >
                    <Text size="13px">Main Menu</Text>
                    {activeLevel1 && (
                      <Text size="13px" color="dark-4">
                        {activeLevel1.title}
                      </Text>
                    )}
                  </Box>
                }
                justify="start"
                icon={<FormPrevious size="medium" color="brand" />}
                style={{
                  textTransform: 'none',
                  letterSpacing: '0',
                  padding: `${theme.global.edgeSize.small} ${theme.global.edgeSize.medium}`,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(resetActiveSubmenus());
                }}
              />
              <Box
                border={{ side: 'top', size: 'small' }}
                gap="large"
                pad="xlarge"
              >
                {level2Items
                  .filter((x) => {
                    return !!activeSubmenus[x.parent?._uid];
                  })
                  .filter((x) => {
                    return isVisibleByVisibilitySettings(x.visibility || [], {
                      discountCode,
                      member,
                      jwt,
                    });
                  })
                  .map((x) => {
                    return (
                      <MotionBox
                        key={x._uid}
                        animate={
                          activeSubmenus[x.parent?._uid]
                            ? {
                                opacity: 1,
                                display: 'inherit',
                                transition: {
                                  duration: 0,
                                },
                              }
                            : {
                                opacity: 0,
                                transition: {
                                  duration: 0.2,
                                },
                                transitionEnd: { display: 'none' },
                              }
                        }
                      >
                        {x.component === 'Menu' ? (
                          <MobileHeaderSubMenu key={x._uid} {...x} plain />
                        ) : x.component === 'MenuSection' ? (
                          <HeaderMenuSection key={x._uid} {...x} />
                        ) : (
                          <HeaderMenuItem
                            key={x._uid}
                            {...x}
                            plain
                            headingLevel={3}
                            style={{
                              padding: `${theme.global.edgeSize.small} ${theme.global.edgeSize.medium}`,
                            }}
                          />
                        )}
                      </MotionBox>
                    );
                  })}
              </Box>
            </MotionBox>
          </div>
        </Grid>
      </MotionBox>
    </Container>
  );
};

MobileMainMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  discountCode: PropTypes.string,
  member: PropTypes.object,
  jwt: PropTypes.string,
};

export default memo(MobileMainMenu);
