exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if (
    location.state?.preventScroll &&
    prevRouterProps &&
    location.pathname === prevRouterProps.location?.pathname
  ) {
    return false;
  }

  return true;
};

exports.onRouteUpdate = () => {
  window.analytics?.page(document.title);

  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('backdrop-route-update', {
        url: window.location.toString(),
      })
    );
    window.onPageChange?.({
      url: window.location.toString(),
    });
    window.dataLayer?.push({
      event: 'gatsby-route-change',
      url: window.location.toString(),
    });
  }, 50);
};

exports.onInitialClientRender = () => {
  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('backdrop-route-update', {
        url: window.location.toString(),
      })
    );
    window.onPageChange?.({
      url: window.location.toString(),
    });
    window.dataLayer?.push({
      event: 'gatsby-route-change',
      url: window.location.toString(),
    });
  }, 50);
};
