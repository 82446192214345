export const COOKIE_GENERIC_PRODUCT_QTY = `bd_genericProductCalculatedQuantity`;
export const GALLON_DISCOUNT_THRESHOLD = 4;
export const GALLON_DISCOUNT_AMOUNT = 5;
export const SHOPIFY_WEIGHT_UNIT_POUNDS = 'POUNDS';
export const SHOPIFY_PERCENTAGE_VALUE_TYPE = 'PricingPercentageValue';
export const GALLON_RESTOCK_FEE = 10;
export const WALLCOVERINGS_ROLL_RESTOCK_FEE = 30;
export const BACKDROP_WALLCOVERINGS_DESIGNERS = [
  'backdrop',
  'backdrop x xavier donnelly',
];
export const WALLCOVERINGS_SIZE_TAGS = [
  'small-scale',
  'mid-scale',
  'large-scale',
  'mural-panel',
];

export const SUPPLY_RESTOCK_FEE = 10;

export const MAX_WEIGHT_PER_PACKAGE = 50;
export const STANDARD_PACKAGE_TYPE = 'Backdrop Delivery Box';
export const STANDARD_PACKAGE_DIMENSIONS = {
  width_value: 14,
  height_value: 9,
  length_value: 6,
  unit: 'inch',
};

export const NON_DISPLAY_TAGS = [
  'Instagram',
  'PDP',
  'Gallery',
  'partnership:coming-soon',
  'partnership:dunkin',
  'PAINT',
  'PAINT - INTERIOR STANDARD',
  'PAINT - EXTERIOR STANDARD',
  'PAINT - INTERIOR SEMI-GLOSS',
  'PAINT - EXTERIOR SEMI-GLOSS',
  'type:standard',
  'type:semi-gloss',
  'type:semigloss',
  'recommended:primer',
  'Press',
  'invert',
  'Invert',
  'Featured PDP Image',
];

export const DOMAIN = process.env.GATSBY_DOMAIN;
