import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Text, Button } from 'grommet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import SmartLink from './SmartLink';
import {
  setActiveHeaderSubMenu,
  setActiveHeaderSubMenuItem,
} from '../state/ui/uiSlice';
import useHover from './useHover';
import useMobile from './useMobile';
import useAdjustedHeadingSize from './useAdjustedHeadingSize';
import useVisibilitySettings from './useVisibilitySettings';
import selectMember from '../state/industry/selectMember';
import formatCurrency from '../lib/formatCurrency';
import ImgixImage from './ImgixImage';
import getStoryblokLinkUrl from '../lib/getStoryblokLinkUrl';

const HeaderMenuItem = ({
  text,
  dark,
  fixed,
  link,
  visibility,
  secondaryText,
  headingLevel = 5,
  style,
  parent,
  iconImage,
  product,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [ref, isHovered] = useHover(
    () => {
      if (!isMobile) {
        dispatch(setActiveHeaderSubMenu(null));
      }
    },
    () => {
      dispatch(setActiveHeaderSubMenuItem(null));
    }
  );
  const member = useSelector(selectMember);
  const memberCredit = useSelector((state) => state.industry.memberCredit);
  const linkStyle = useAdjustedHeadingSize(headingLevel);

  const url = getStoryblokLinkUrl(link, product);
  const isVisible = useVisibilitySettings(visibility);

  const template = useCallback((string) => {
    return string
      ?.replace(/{{ firstName }}/gm, member?.firstName ? member.firstName : '')
      .replace(/{{ referralCode }}/gm, member ? member.referral_code : '')
      .replace(
        /{{ credit }}/gm,
        memberCredit ? formatCurrency(memberCredit) : ''
      );
  });

  const isSubSubMenu = parent?.parent !== undefined;
  const icon =
    isSubSubMenu && iconImage ? (
      <ImgixImage
        src={iconImage?.filename}
        srcSetOptions={{ w: 24 }}
        lazy={true}
      />
    ) : undefined;

  return isVisible ? (
    <Box ref={ref}>
      <SmartLink
        to={url}
        as={Button}
        compAs="a"
        style={style}
        color={isHovered ? 'brand' : 'transparent'}
        justify="start"
        icon={icon}
        label={
          <Box direction="row-responsive">
            <Text margin="0" style={linkStyle}>
              {template(text)}
            </Text>
            {secondaryText && (
              <Text
                margin="0"
                style={linkStyle}
                color={isMobile ? 'black' : dark && !fixed ? 'white' : 'black'}
              >
                {template(secondaryText)}
              </Text>
            )}
          </Box>
        }
      />
    </Box>
  ) : null;
};

HeaderMenuItem.propTypes = {
  image: PropTypes.object,
  items: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string,
  component: PropTypes.string,
  iconImage: PropTypes.object,
  dark: PropTypes.bool,
  fixed: PropTypes.bool,
  link: PropTypes.object,
  parent: PropTypes.object,
  _uid: PropTypes.string,
  visibility: PropTypes.array,
  secondaryText: PropTypes.string,
  headingLevel: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  product: PropTypes.object,
};

export default memo(HeaderMenuItem);
