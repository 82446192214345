import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import { FormNextLink, Add, Close } from 'grommet-icons';

const IconWaves = loadable(() => import('./IconWaves'));
const IconLeaf = loadable(() => import('./IconLeaf'));
const IconPaintRoller = loadable(() => import('./IconPaintRoller'));
const IconParticles = loadable(() => import('./IconParticles'));
const IconSprayBottle = loadable(() => import('./IconSprayBottle'));
const IconClimateNeutral = loadable(() => import('./IconClimateNeutral'));
const IconGreenwise = loadable(() => import('./IconGreenwise'));
const IconSheen = loadable(() => import('./IconSheen'));
const IconCoverage = loadable(() => import('./IconCoverage'));
const IconClock = loadable(() => import('./IconClock'));
const IconCube = loadable(() => import('./IconCube'));
const IconQuestionMark = loadable(() => import('./IconQuestionMark'));
const IconQuestionMarkOpen = loadable(() => import('./IconQuestionMarkOpen'));
const IconBook = loadable(() => import('./IconBook'));
const IconBrush = loadable(() => import('./IconBrush'));
const IconFandeck = loadable(() => import('./IconFandeck'));
const IconAward = loadable(() => import('./IconAward'));
const IconWallet = loadable(() => import('./IconWallet'));
const IconCheck = loadable(() => import('./IconCheck'));
const IconCheckCircle = loadable(() => import('./IconCheckCircle'));
const Loading = loadable(() => import('../Loading'));

const PlusIcon = (props) => <Add size="18%" {...props} />;
const CloseIcon = (props) => <Close size="18%" {...props} />;

const getIcon = (type) => {
  switch (type) {
    case 'rightArrow':
      return FormNextLink;
    case 'plus':
      return PlusIcon;
    case 'waves':
      return IconWaves;
    case 'leaf':
      return IconLeaf;
    case 'paintRoller':
      return IconPaintRoller;
    case 'particles':
      return IconParticles;
    case 'sprayBottle':
      return IconSprayBottle;
    case 'close':
      return CloseIcon;
    case 'climateNeutral':
      return IconClimateNeutral;
    case 'greenwise':
      return IconGreenwise;
    case 'sheen':
      return IconSheen;
    case 'coverage':
      return IconCoverage;
    case 'clock':
      return IconClock;
    case 'loading':
      return Loading;
    case 'cube':
      return IconCube;
    case 'questionMark':
      return IconQuestionMark;
    case 'questionMarkOpen':
      return IconQuestionMarkOpen;
    case 'book':
      return IconBook;
    case 'brush':
      return IconBrush;
    case 'fandeck':
      return IconFandeck;
    case 'award':
      return IconAward;
    case 'wallet':
      return IconWallet;
    case 'checkmark':
      return IconCheck;
    case 'checkmark-circle':
      return IconCheckCircle;
    default:
      return FormNextLink;
  }
};

const DynamicIcon = ({ type, ...rest }) => {
  const Icon = getIcon(type);
  return type ? <Icon {...rest} /> : null;
};

DynamicIcon.propTypes = {
  type: PropTypes.string,
};

export default DynamicIcon;
