import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Link as GatsbyLink, navigate } from 'gatsby';
import { Anchor, Box } from 'grommet';
import { normalizeColor, focusStyle } from '../lib/styleUtils';
import styled, { css } from 'styled-components';
import { track as trackFunc } from '../lib/analytics';

const disabledStyle = `
  opacity: 0.3;
  cursor: default;
  text-decoration: none;
`;

const sizeStyle = (props) => {
  if (props.size) {
    const size = props.size || 'medium';
    const data = props.theme.text[size];
    return css`
      font-size: ${data.size};
      line-height: ${data.height};
    `;
  }
  return css`
    font-size: inherit;
    line-height: inherit;
  `;
};

const StyledLink = styled(GatsbyLink)`
  box-sizing: border-box;
  ${(props) => sizeStyle(props)}
  color: ${(props) =>
    normalizeColor(props.colorProp || props.theme.anchor.color, props.theme)};
  font-weight: ${(props) =>
    props.weight ||
    (props.theme.anchor.fontWeight && props.theme.anchor.fontWeight)};
  text-decoration: ${(props) =>
    props.hasIcon || props.plain ? 'none' : props.theme.anchor.textDecoration};
  cursor: pointer;
  outline: none;
  width: ${(p) => (p.fill ? '100%' : 'auto')};
  height: ${(p) => (p.fill ? '100%' : 'auto')};

  ${(props) =>
    !props.disabled &&
    props.theme.anchor.hover &&
    css`
      &:hover {
        ${props.theme.anchor.hover.textDecoration &&
        `text-decoration: ${
          props.plain ? 'none' : props.theme.anchor.hover.textDecoration
        };`}
        ${props.theme.anchor.hover.fontWeight &&
        `font-weight: ${props.theme.anchor.hover.fontWeight};`}
      ${props.theme.anchor.hover.extend}
      }
    `}
  ${(props) =>
    props.hasIcon &&
    !props.hasLabel &&
    `
    padding: ${props.theme.global.edgeSize.small};
  `}
  ${(props) => props.disabled && disabledStyle}
  ${(props) => props.focus && focusStyle}
  ${(props) => props.theme.anchor.extend}
  ${(props) => props.plain && `font-weight: inherit;`}
`;

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const SmartLink = ({
  children,
  to,
  href,
  activeClassName,
  target,
  plain,
  as,
  compAs,
  options,
  track,
  onClick,
  forwardQueryParams,
  queryParamString,
  state = {},
  textAlign,
  ariaLabel,
  a11yTitle,
  ...other
}) => {
  const location = useLocation();
  const queryString = queryParamString || location.search;
  const destination =
    to || href
      ? `${to || href}${forwardQueryParams ? queryString : ''}`
      : undefined;
  if (!destination) {
    return <Box {...other}>{children}</Box>;
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = target !== '_blank' && /^\/(?!\/)/.test(destination);

  const splitDest = destination.split('?');
  // Removes trailing slash
  const cleanedDestination = internal
    ? destination === '/'
      ? destination
      : `${splitDest[0].replace(/\/?$/, '/')}${
          splitDest[1] ? `?${splitDest[1]}` : ''
        }`
    : destination;

  if (as) {
    const Comp = as;
    return (
      <Comp
        as={compAs}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          } else {
            e.stopPropagation();
            e.preventDefault();
            navigate(cleanedDestination, { ...options, state });
          }
        }}
        plain={plain}
        href={cleanedDestination}
        {...other}
      >
        {children}
      </Comp>
    );
  }
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledLink
        plain={plain}
        to={cleanedDestination}
        state={state}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          if (track && track.event) {
            trackFunc(track.event, {
              ...(track.properties || {}),
              location: location.pathname,
            });
          }
          return e;
        }}
        activeClassName={activeClassName}
        target={target}
        aria-label={ariaLabel || a11yTitle}
        {...other}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <Anchor
      target={target}
      href={cleanedDestination}
      style={plain ? { textDecoration: 'none' } : undefined}
      a11yTitle={a11yTitle}
      ariaLabel={ariaLabel}
      {...other}
    >
      {children}
    </Anchor>
  );
};

SmartLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  activeClassName: PropTypes.string,
  target: PropTypes.string,
  plain: PropTypes.bool,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  compAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  options: PropTypes.object,
  track: PropTypes.object,
  onClick: PropTypes.func,
  forwardQueryParams: PropTypes.bool,
  queryParamString: PropTypes.string,
  state: PropTypes.object,
};

export default memo(SmartLink);
