import React, { memo } from 'react';
import PropTypes from 'prop-types';

import HeaderMenu from './HeaderMenu';
import HeaderMenuSection from './HeaderMenuSection';
import HeaderMenuItem from './HeaderMenuItem';
import HeaderSubMenuItem from './HeaderSubMenuItem';
import HeaderSectionMenu from './HeaderSectionMenu';
import HeaderSectionMenuItem from './HeaderSectionMenuItem';
import HeaderMenuPaintByColor from './HeaderMenuPaintByColor';
import useVisibilitySettings from './useVisibilitySettings';

const HeaderMenuComponent = ({ component, ...rest }) => {
  const isVisible = useVisibilitySettings(rest.visibility || []);
  return isVisible ? (
    component === 'MenuSection' ? (
      <HeaderMenuSection {...rest} component={component} />
    ) : component === 'MenuItem' ? (
      <HeaderMenuItem {...rest} component={component} />
    ) : component === 'SectionMenu' ? (
      <HeaderSectionMenu {...rest} component={component} />
    ) : component === 'SectionMenuItem' ? (
      <HeaderSectionMenuItem {...rest} component={component} />
    ) : component === 'SubMenuItem' ? (
      <HeaderSubMenuItem {...rest} component={component} />
    ) : component === 'SubMenu' ? (
      <HeaderSubMenuItem text={rest.title} {...rest} component={component} />
    ) : component === 'MenuPaintByColor' ? (
      <HeaderMenuPaintByColor {...rest} component={component} />
    ) : (
      <HeaderMenu {...rest} component={component} />
    )
  ) : null;
};

HeaderMenuComponent.propTypes = {
  component: PropTypes.string,
};

export default memo(HeaderMenuComponent);
