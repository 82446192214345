import React, { memo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Text, Box } from 'grommet';
import { get, isNil } from '../../lib/nodash';

import { MY_MEMBER_CREDIT } from '../../queries/industryQueries';
import client from '../../gatsby-theme-apollo/client';
import formatCurrency from '../../lib/formatCurrency';

const MemberAccountCredit = ({ layout }) => {
  const { data } = useQuery(MY_MEMBER_CREDIT, {
    client,
  });

  const credit = get('myMemberCredit.balance', data);

  if (isNil(credit)) return null;
  switch (layout) {
    case 'inline':
      return credit;
    case 'copyable':
      return (
        <Box
          direction="row"
          fill="horizontal"
          pad="small"
          border={{ side: 'all', size: 'small' }}
        >
          <Text
            width="100%"
            style={{
              height: '100%',
              padding: '20px 30px',
              fontWeight: 500,
              fontSize: '0.93333rem',
              letterSpacing: '1px',
            }}
          >
            ACCOUNT CREDIT: {formatCurrency(credit)}
          </Text>
        </Box>
      );
    default:
      return <Text>{formatCurrency(credit)}</Text>;
  }
};

MemberAccountCredit.propTypes = {
  layout: PropTypes.oneOf(['inline', 'copyable']),
};

export default memo(MemberAccountCredit);
