import { Button } from 'grommet';
import styled from 'styled-components';

const CartButton = styled(Button)`
  border-radius: 100%;
  text-align: center;
  background: transparent;
  padding: 0;
  width: 28px;
  height: 28px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => p.theme.global.borderSize.small} solid
    ${(p) =>
      p.dark
        ? p.theme.global.colors.text.dark
        : p.theme.global.colors.text.light};
  color: ${(p) =>
    p.dark
      ? p.theme.global.colors.text.dark
      : p.theme.global.colors.text.light};
`;

export default CartButton;
