import { InMemoryCache } from '@apollo/client';
import { isEqual, uniqBy } from './nodash';

const backdropCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        reviews: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        ugcGalleryFilter: {
          keyArgs: false,
          merge(existing = {}, incoming) {
            const colorTags = !isEqual(
              incoming.colorTags,
              existing.colorTags || []
            );
            const ugcTags = !isEqual(incoming.ugcTags, existing.ugcTags || []);
            const sorter = !isEqual(incoming.sorter, existing.sorter);
            const colors = !isEqual(incoming.colors, existing.colors || []);
            const tagGroups = !isEqual(
              incoming.tagGroups,
              existing.tagGroups || []
            );
            const relatedProducts = !isEqual(
              incoming.relatedProducts,
              existing.relatedProducts || []
            );

            if (
              colorTags ||
              ugcTags ||
              colors ||
              relatedProducts ||
              sorter ||
              tagGroups
            ) {
              return incoming;
            } else {
              return {
                ...existing,
                ...incoming,
                placementsForGallery: uniqBy('__ref', [
                  ...(existing.placementsForGallery || []),
                  ...incoming.placementsForGallery,
                ]),
              };
            }
          },
        },
      },
    },
    GalleryPage: {
      fields: {
        placementsForGallery: {
          keyArgs: false,
        },
      },
    },
    NonFeaturedReviews: {
      fields: {
        reviews: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

export default backdropCache;
