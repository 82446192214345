import deNodify from '../deNodify';
import { get, map, isEqual } from '../nodash';

const parseCheckoutDiscounts = (discountAllocations) => {
  const allocations = deNodify(discountAllocations);
  return map(
    (x) => ({
      ...x,
      value: get('value.percentage', x) || get('value.amount', x),
      valueType: isEqual(get('value.__typename', x), 'PricingPercentageValue')
        ? 'percent'
        : 'money',
    }),
    allocations
  );
};

export default parseCheckoutDiscounts;
