// Pulled from https://github.com/grommet/grommet/blob/master/src/js/components/Grid/StyledGrid.js
// Used to render static CSS to prevent style flashes

import { css } from 'styled-components';

const SIZE_MAP = {
  flex: '1fr',
  full: '100%',
  '1/2': '50%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/3': '33.33%',
  '2/3': '66.66%',
};

const gapSizes = (props) => {
  const result = [];
  if (typeof props.gap === 'string') {
    const size = props.theme.global.edgeSize[props.gap] || props.gap;
    result[0] = size;
    result[1] = size;
  } else if (props.gap) {
    if (props.gap.row)
      result[0] = props.theme.global.edgeSize[props.gap.row] || props.gap.row;
    if (props.gap.column)
      result[1] =
        props.theme.global.edgeSize[props.gap.column] || props.gap.column;
  }
  return result;
};

const getRepeatCount = (count) =>
  typeof count === 'number' ? count : `auto-${count || 'fit'}`;

const getRepeatSize = (size, props) => {
  if (size === 'flex') return '1fr';
  const gaps = gapSizes(props);
  let min;
  let max;
  let minFill;
  if (Array.isArray(size)) {
    const [minSize = 'auto', maxSize = 'auto'] = size;
    min = normalizeSize(minSize, props);
    if (min.search(/px/) !== -1) minFill = true;
    max = normalizeSize(maxSize, props);
    if (gaps[1] !== undefined) {
      // account for the column gap when using fractional sizes, e.g. 1/3
      if (minSize.indexOf('/') !== -1)
        min = `calc(${min} - (${gaps[1]} * (1 - ${minSize})))`;
      if (maxSize.indexOf('/') !== -1)
        max = `calc(${max} - (${gaps[1]} * (1 - ${maxSize})))`;
    }
  } else {
    min = normalizeSize(size, props);
    if (min?.search(/px/) !== -1) minFill = true;
    max = '1fr';
    if (gaps[1] !== undefined) {
      // account for column gap with fractional sizes, e.g. 1/3
      if (size.indexOf('/') !== -1)
        min = `calc(${min} - (${gaps[1]} * (1 - ${size})))`;
    }
  }
  if (minFill) {
    // ensure we never go beyond the container width,
    // for mobile/narrow situations
    min = `min(${min}, 100%)`;
  }
  return `minmax(${min}, ${max})`;
};

const normalizeSize = (size, props) =>
  SIZE_MAP[size] || props.theme.global.size[size] || size;

const generateGridColumnsStyle = (props) => {
  if (Array.isArray(props.columns)) {
    return css`
      grid-template-columns: ${props.columns
        .map((s) => {
          if (Array.isArray(s)) {
            return `minmax(${normalizeSize(s[0], props)}, ${normalizeSize(
              s[1],
              props
            )})`;
          }
          return normalizeSize(s, props);
        })
        .join(' ')};
    `;
  }
  if (typeof props.columns === 'object') {
    return css`
      grid-template-columns: repeat(
        ${getRepeatCount(props.columns.count)},
        ${getRepeatSize(props.columns.size, props)}
      );
    `;
  }
  return css`
    grid-template-columns: repeat(
      auto-fill,
      ${getRepeatSize(props.columns, props)}
    );
  `;
};

export default generateGridColumnsStyle;
