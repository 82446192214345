import { useSelector } from 'react-redux';

import selectDiscountCode from '../state/cart/selectDiscountCode';
import selectJwt from '../state/industry/selectJwt';
import useMember from './Industry/useMember';
import isVisibleByVisibilitySettings from '../lib/isVisibleByVisibilitySettings';

const useVisibilitySettings = (settings) => {
  const settingsArray = Array.isArray(settings) ? settings : [settings];
  const discountCode = useSelector(selectDiscountCode);
  const member = useMember();
  const jwt = useSelector(selectJwt);
  return isVisibleByVisibilitySettings(settingsArray, {
    discountCode,
    member,
    jwt,
  });
};

export default useVisibilitySettings;
