import { createSlice } from '@reduxjs/toolkit';
import { get } from '../../lib/nodash';

export const industrySlice = createSlice({
  name: 'industry',
  initialState: {
    jwt: null,
    compassModalOpen: false,
    compassStep: 'apply',
    temp_token: null,
    user: null,
    member: null,
    memberCredit: null,
    referredByMember: null,
    referralCode: null,
    shopifyCustomerId: null,
    anonymousId: null,
    email: null,
    authChecked: false,
  },
  reducers: {
    setJwt: (state, action) => {
      state.jwt = action.payload;
      state.authChecked = true;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMember: (state, action) => {
      state.member = action.payload;
    },
    setMemberCredit: (state, action) => {
      state.memberCredit = action.payload;
    },
    setMemberReferral: (state, action) => {
      state.referredByMember = get('payload.member', action)
        ? get('payload.member', action)
        : { first_name: get('payload.solicitation.referred_by_name', action) };
    },
    handleLogin: (state, action) => {
      state.user = get('payload.data.login.user', action);
      state.jwt = get('payload.data.login.jwt', action);
      state.authChecked = true;
    },
    logout: (state) => {
      state.jwt = null;
      state.user = null;
      state.shopifyCustomerId = null;
      state.referralCode = null;
      state.member = null;
      state.memberCredit = null;
      state.referredByMember = null;
      state.shopifyCustomerId = null;
    },
    setTemporaryToken: (state, action) => {
      state.temp_token = action.payload;
    },
    setCompassModalOpen: (state, action) => {
      state.compassModalOpen = action.payload || false;
    },
    setCompassStep: (state, action) => {
      state.compassStep = action.payload;
    },
    setShopifyCustomerId: (state, action) => {
      state.shopifyCustomerId = action.payload;
    },
    setAnonymousId: (state, action) => {
      state.anonymousId = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setAuthChecked: (state, action) => {
      state.authChecked = action.payload;
    },
  },
});

export const {
  setJwt,
  logout,
  setTemporaryToken,
  setCompassModalOpen,
  setCompassStep,
  setUser,
  setMember,
  setMemberCredit,
  setMemberReferral,
  setShopifyCustomerId,
  setAnonymousId,
  setEmail,
  handleLogin,
  setAuthChecked,
} = industrySlice.actions;

export default industrySlice.reducer;
