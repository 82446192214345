import React from 'react';
import { Blank } from 'grommet-icons';

const IconLogo = (props) => (
  <Blank
    {...props}
    viewBox="0 0 458 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <g id="Group-Copy-4">
          <path
            d="M32.2900207,36.4680022 C29.1707005,34.9083421 25.1025871,34.9949899 17.9194858,34.9949899 L11.5075498,34.9949899 L11.5075498,53.0870471 L24.322757,53.0870471 C28.1352595,53.0870471 32.4633163,53.3469904 35.3183608,49.9720593 C36.0981908,49.1055814 37.224612,47.5459213 37.224612,44.6042291 C37.224612,42.8712734 36.7047253,38.7165122 32.2900207,36.4680022 Z M32.8099074,14.4724623 C29.8638828,10.8375878 24.4960526,11.1841789 20.4279391,11.1841789 L11.5075498,11.1841789 L11.5075498,26.4211917 L15.4933479,26.4211917 C18.5216879,26.4211917 21.5500279,26.4211917 24.6693482,26.2478961 C27.5243926,26.0789329 30.2971217,25.7280094 32.3723361,23.475167 C33.0698508,22.7863171 34.2785874,21.3133048 34.2785874,18.7182037 C34.2785874,18.0250214 34.1052918,16.1187702 32.8099074,14.4724623 Z M40.8638189,60.1878329 C35.4916563,63.216173 30.0371784,62.9562296 24.1494615,62.9562296 L0.169687333,62.9562296 L0.169687333,1.83488306 L22.074247,1.83488306 C25.1025871,1.83488306 28.2219073,1.83488306 31.2502473,2.09482641 C33.1521662,2.26812198 38.1734052,2.78800868 42.1592033,7.02941769 C43.45892,8.41144984 46.0540212,11.7040656 46.0540212,16.8119525 C46.0540212,18.5449081 45.6207822,24.6059206 40.5128954,27.8075562 C39.0442155,28.7606818 37.6578509,29.107273 35.9248953,29.4495317 C36.7047253,29.6228273 38.6109765,29.9694184 40.5995432,31.0958396 C43.7188634,32.7421475 48.913398,36.8102609 48.913398,45.6440025 C48.913398,51.7916627 46.3139645,57.0685127 40.8638189,60.1878329 Z"
            id="Fill-1"
          ></path>
          <path
            d="M79.7990005,12.8261544 L71.3118501,40.9693545 L88.1951707,40.9693545 L79.7990005,12.8261544 Z M94.7760699,62.9562296 L91.2278432,51.0118327 L68.2835101,51.0118327 L64.7309509,62.9562296 L52.4356304,62.9562296 L73.1314535,1.83488306 L86.1199563,1.83488306 L106.98041,62.9562296 L94.7760699,62.9562296 Z"
            id="Fill-2"
          ></path>
          <path
            d="M159.662263,54.1268205 C156.807218,57.7660274 150.139671,64.5158897 137.844351,64.5158897 C135.769136,64.5158897 130.834545,64.3425942 125.54903,61.9164562 C118.625872,58.6281728 109.276576,50.3186504 109.276576,32.2222608 C109.276576,28.9339774 109.276576,20.1868836 114.900018,12.1373045 C117.846042,7.89156313 124.86018,0.275222952 138.27759,0.275222952 C149.966376,0.275222952 156.114036,6.07629207 158.886765,9.97110994 C160.875332,12.9128022 162.088401,15.9454746 163.123842,19.1471102 L151.785979,22.0931349 C151.699331,21.3133048 151.348408,19.3204058 150.226319,17.2451914 C147.891161,12.9128022 143.298829,10.6642922 138.450885,10.75094 C134.989306,10.75094 132.563168,11.7907134 131.350099,12.3972479 C126.848747,14.6457579 121.654212,20.5334748 121.654212,31.9623174 C121.654212,33.6952731 121.74086,40.9693545 125.293419,46.4195001 C126.502156,48.2391036 127.975168,49.7987637 129.708124,50.9251849 C133.342998,53.4336382 136.977873,53.6935816 138.624181,53.6935816 C143.389809,53.6935816 146.938036,51.7916627 149.88406,48.1524558 C151.092797,46.6794435 152.045922,44.8641724 152.301533,44.4309335 C152.648125,43.6511034 152.9124,42.9579212 153.432287,41.4025935 L164.250263,45.9039458 C162.954878,49.0189336 161.741809,51.4407392 159.662263,54.1268205"
            id="Fill-3"
          ></path>
          <polyline
            id="Fill-4"
            points="210.138929 62.9562296 193.862143 34.2151598 183.906313 45.6440025 183.906313 62.9562296 172.564118 62.9562296 172.564118 1.83488306 183.906313 1.83488306 183.906313 29.8827706 208.405973 1.83488306 221.827715 1.83488306 201.829407 24.8615316 223.209747 62.9562296 210.138929 62.9562296"
          ></polyline>
          <path
            d="M263.297344,16.1187702 C262.257571,15.2522924 260.182356,13.6969646 256.889741,12.8261544 C253.948048,12.0506567 252.388388,12.1373045 247.020558,12.1373045 L240.959546,12.1373045 L240.959546,52.6538082 L243.987886,52.6538082 C250.135546,52.6538082 253.774753,52.5671604 256.547482,52.1382538 C258.10281,51.8783105 262.430866,51.1807958 265.550187,47.3726257 C269.358357,42.5246823 269.185061,33.6086253 269.185061,32.8287953 C269.185061,23.7351104 266.412332,18.8914993 263.297344,16.1187702 Z M263.986194,61.3142541 C258.709344,62.9562296 254.463603,62.9562296 249.009125,62.9562296 L229.530703,62.9562296 L229.530703,1.83488306 L251.435263,1.83488306 C254.207992,1.83488306 257.582923,2.00817862 260.269004,2.52806533 C263.297344,3.13459981 269.878243,5.12316645 274.98613,11.7040656 C277.672212,15.2522924 281.307086,22.0931349 281.307086,32.915443 C281.307086,45.037468 276.628106,57.2418083 263.986194,61.3142541 Z"
            id="Fill-5"
          ></path>
          <path
            d="M320.588859,13.5236691 C318.59596,12.2239523 316.260802,11.7040656 311.412859,11.7040656 L302.145878,11.7040656 L302.145878,30.7492485 L306.218324,30.7492485 C312.885871,30.7492485 317.040632,30.7492485 319.809029,29.2805685 C321.541984,28.3274429 324.401361,26.0789329 324.401361,21.1400093 C324.401361,19.5846815 324.228066,15.9454746 320.588859,13.5236691 Z M324.483677,62.9562296 L312.452632,40.2718399 L302.145878,40.2718399 L302.145878,62.9562296 L290.808016,62.9562296 L290.808016,1.83488306 L315.827563,1.83488306 C318.59596,1.83488306 326.823167,2.09482641 332.277645,9.28226006 C335.830204,13.9525756 336.0035,19.233758 336.0035,21.226657 C336.0035,22.1797827 335.912519,25.2081227 334.877078,28.0674996 C333.923953,30.5759529 330.977928,35.8571353 323.96379,38.0233299 L337.558827,62.9562296 L324.483677,62.9562296 Z"
            id="Fill-6"
          ></path>
          <path
            d="M386.454172,18.8048515 C383.248204,13.6103169 378.40026,10.75094 372.256932,10.75094 C368.44443,10.75094 365.671701,11.964009 364.462964,12.5705435 C360.134907,14.9057012 354.767077,20.6201226 354.767077,32.0489652 C354.767077,34.3884554 354.849392,45.6440025 363.423191,51.2674436 C367.144713,53.6935816 370.866235,53.8668771 372.425895,53.8668771 C373.638964,53.8668771 376.411693,53.7802293 379.440033,52.3938648 C383.941386,50.3186504 389.829103,45.1241158 390.089046,33.6086253 C390.175694,28.5873863 389.222568,23.2195561 386.454172,18.8048515 Z M393.117386,55.7731284 C391.735354,57.2418083 388.182795,60.7943674 382.732649,62.7829341 C380.822066,63.4761163 377.273839,64.5158897 372.083637,64.5158897 C363.423191,64.5158897 356.842292,61.6565129 351.12787,55.0799461 C345.413449,48.5856947 342.991643,40.9693545 342.991643,32.4822041 C342.991643,30.4936375 343.073959,25.8146572 344.811247,20.6201226 C348.792712,8.41144984 359.268429,0.275222952 372.339248,0.275222952 C384.114681,0.275222952 391.12882,6.68282655 393.983864,9.97110994 C397.189832,13.6103169 401.691184,20.7067703 401.604537,32.3955563 C401.604537,36.2037264 401.171298,47.0260346 393.117386,55.7731284 Z"
            id="Fill-7"
          ></path>
          <path
            d="M443.204137,16.1187702 C440.604704,11.6174178 435.496817,11.6174178 431.082113,11.6174178 L422.335019,11.6174178 L422.335019,32.8287953 L430.562226,32.8287953 C431.337723,32.8287953 432.208534,32.7421475 432.984031,32.7421475 C438.876081,32.4822041 441.471182,30.6626007 442.944194,28.2407951 C443.550729,27.2010217 444.58617,24.9481793 444.58617,21.8331915 C444.58617,19.9312727 444.152931,17.8517259 443.204137,16.1187702 Z M448.225377,38.7165122 C442.944194,42.0914433 437.229773,42.1780911 431.255408,42.1780911 L422.335019,42.1780911 L422.335019,62.9562296 L410.910509,62.9562296 L410.910509,1.83488306 L431.511019,1.83488306 C432.984031,1.83488306 434.457044,1.74823527 435.930056,1.83488306 C438.611805,2.00817862 446.319125,2.52806533 451.773603,8.93566893 C456.188308,14.0392234 456.361603,19.7579771 456.361603,22.1797827 C456.361603,31.7023741 451.427012,36.6369654 448.225377,38.7165122 Z"
            id="Fill-8"
          ></path>
        </g>
      </g>
    </g>
  </Blank>
);

export default IconLogo;
