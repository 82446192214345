import React, { useMemo, memo } from 'react';
import { Box, Text } from 'grommet';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextTitle from './TextTitle';
import SmartLink from './SmartLink';

const ColumnDiv = styled.div`
  columns: 1;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    columns: 2;
    column-fill: balanced;
  }
`;

function HeaderMenuPaintByColor({ title }) {
  const { colorFilters } = useStaticQuery(graphql`
    query HeaderMenuPaintByColorFilters {
      colorFilters: storyblokEntry(
        full_slug: { eq: "filters/default-paint-filters" }
      ) {
        content
        id
        slug
        uuid
        internalId
      }
    }
  `);

  const filterStory = JSON.parse(colorFilters.content);
  const filterGroups = useMemo(
    () => filterStory?.filterGroups || [],
    [filterStory]
  );

  const filterGroup = filterGroups[0];

  return (
    <Box gap="large">
      {title && <TextTitle>{title}</TextTitle>}

      <ColumnDiv>
        {filterGroup.filters.map((item) => (
          <SmartLink
            plain
            key={item._uid}
            to={`/collections/paint/?filter=${item.slug.replace('color:', '')}`}
            style={{ breakInside: 'avoid', display: 'block' }}
          >
            <Box
              key={item._uid}
              className="type"
              wrap={false}
              align="center"
              direction="row"
              gap="medium"
              pad={{ bottom: 'medium' }}
            >
              <Box align="center" flex={false}>
                <Box
                  round={'100%'}
                  background={item.hexValue || 'light-2'}
                  height={'24px'}
                  width={'24px'}
                  border={{ side: 'all', size: 'xsmall' }}
                  flex={false}
                />
              </Box>

              <Text size="medium" textAlign="center">
                {item.name}
              </Text>
            </Box>
          </SmartLink>
        ))}
      </ColumnDiv>
    </Box>
  );
}

HeaderMenuPaintByColor.propTypes = {
  title: PropTypes.string,
};

export default memo(HeaderMenuPaintByColor);
