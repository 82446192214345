import { createSlice } from '@reduxjs/toolkit';

export const collectionSlice = createSlice({
  name: 'collection',
  initialState: {
    zoom: 3,
    minZoom: 1,
    maxZoom: 3,
  },
  reducers: {
    setCollectionZoom: (state, action) => {
      state.zoom = parseInt(action.payload);
    },
    incrementZoom: (state, action) => {
      const newZoom = parseInt(state.zoom) + parseInt(action.payload);
      if (newZoom <= state.maxZoom && newZoom >= state.minZoom) {
        state.zoom += parseInt(action.payload);
      }
    },
  },
});

export const { setCollectionZoom, incrementZoom } = collectionSlice.actions;

export default collectionSlice.reducer;
