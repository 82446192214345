import { getProductPath } from './product';

const getStoryblokLinkUrl = (link, product) => {
  const url = product?.item
    ? getProductPath(product.item)
    : link?.linktype === 'story'
    ? `/${link.cached_url}`
    : link?.linktype === 'url'
    ? link?.url
    : link?.linktype === 'email'
    ? `mailto:${link.email}`
    : link?.url;
  return url;
};

export default getStoryblokLinkUrl;
