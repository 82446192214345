import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, ThemeContext } from 'grommet';
import useMobile from './useMobile';

const NumberedBullet = ({ number, size = 'medium' }) => {
  const isMobile = useMobile();
  const theme = useContext(ThemeContext);

  const textSize = `${
    parseInt(theme.text[size].size) * (isMobile ? 0.7 : 1)
  }px`;
  const discSize = `${
    parseInt(theme.text[size].size) * (isMobile ? 1 : 1.5)
  }px`;

  return (
    <Box
      round="full"
      background="brand"
      width={discSize}
      height={discSize}
      justify="center"
      align="center"
      flex={false}
      style={{ marginTop: isMobile ? '0.3rem' : 0 }}
    >
      <Text size={textSize} weight={500}>
        {number}
      </Text>
    </Box>
  );
};

NumberedBullet.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  style: PropTypes.object,
};

export default memo(NumberedBullet);
