import gql from 'graphql-tag';

export const GET_PRODUCT_VARIANTS_BY_IDS = gql`
  query ProductVariantsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      __typename
      ... on ProductVariant {
        id
        currentlyNotInStock
        quantityAvailable
        availableForSale
        title
        sku
        priceV2 {
          amount
        }
        product {
          id
          title
          availableForSale
          productType
          handle
          images(first: 15) {
            edges {
              node {
                originalSrc
              }
            }
          }
          sample_out_of_stock_message: metafield(
            namespace: "accentuate"
            key: "sample_out_of_stock_message"
          ) {
            key
            value
            id
          }
          out_of_stock_message: metafield(
            namespace: "accentuate"
            key: "out_of_stock_message"
          ) {
            key
            value
            id
          }
        }
      }
    }
  }
`;
