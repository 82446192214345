import { find, filter, includes, get, toLower } from './nodash';
import deNodify from './deNodify';

export const findImage = (images, matcher) => {
  const match = find(
    (x) => {
      const src =
        get('image.originalSrc', x) ||
        get('src', x) ||
        get('originalSrc', x) ||
        '';
      return toLower(src).includes(toLower(matcher));
    },
    deNodify(images)?.media ? deNodify(images).media : deNodify(images)
  );
  return (
    get('image.originalSrc', match) ||
    get('src', match) ||
    get('originalSrc', match)
  );
};

export const findImages = (images, matcher) => {
  return filter(
    (x) => {
      return includes(
        toLower(matcher),
        toLower(get('src', x) || get('originalSrc', x))
      );
    },
    deNodify(images)?.media ? deNodify(images).media : deNodify(images)
  );
};

export default findImage;
