import { createSlice } from '@reduxjs/toolkit';
import { reject, includes } from '../../lib/nodash';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    productForSelection: null,
    products: [],
    shopifyProducts: [],
    productGroups: [],
    paintCollections: [],
    wallcoveringsCollections: [],
    selectedProducts: [],
    isInitialized: false,
    maxSelected: 1,
  },
  reducers: {
    init: (state, action) => {
      if (action.payload.productGroups)
        state.productGroups = action.payload.productGroups;
      if (action.payload.products) state.products = action.payload.products;
      if (action.payload.shopifyProducts)
        state.shopifyProducts = action.payload.shopifyProducts;
      if (action.payload.paintCollections)
        state.paintCollections = action.payload.paintCollections;
      if (action.payload.wallcoveringsCollections)
        state.wallcoveringsCollections =
          action.payload.wallcoveringsCollections;
      state.isInitialized = true;
    },
    activateProductVariantSelector: (state, action) => {
      state.productForSelection = action.payload;
    },
    deactivateProductVariantSelector: (state) => {
      state.productForSelection = null;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
      state.isInitialized = true;
    },
    setProductGroups: (state, action) => {
      state.productGroups = action.payload;
      state.isInitialized = true;
    },
    setShopifyProducts: (state, action) => {
      state.shopifyProducts = action.payload;
      state.isInitialized = true;
    },
    setPaintCollections: (state, action) => {
      state.paintCollections = action.payload;
      state.isInitialized = true;
    },
    toggleSelectProduct: (state, action) => {
      if (includes(action.payload, state.selectedProducts)) {
        state.selectedProducts = reject(
          (x) => x === action.payload,
          state.selectedProducts
        );
      } else {
        if (state.selectedProducts.length + 1 > state.maxSelected) {
          if (state.maxSelected === 1) {
            state.selectedProducts = [action.payload];
          }
        } else {
          state.selectedProducts.push(action.payload);
        }
      }
    },
  },
});

export const {
  activateProductVariantSelector,
  deactivateProductVariantSelector,
  setProducts,
  setShopifyProducts,
  setProductGroups,
  setPaintCollections,
  init,
  toggleSelectProduct,
} = productSlice.actions;

export default productSlice.reducer;
