import { useMemo, useCallback } from 'react';
import { useMutation, useApolloClient, useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { get, filter, map, isEqual, flatten, sum } from '../../lib/nodash';
import { isSample, isPaint, getVariantPrice, isMemo } from '../../lib/product';
import calculateCartDiscounts from '../../lib/cart/calculateCartDiscounts';
import calculateSavedOnPaint from '../../lib/cart/calculateSavedOnPaint';
import calculateSavedOnSupplies from '../../lib/cart/calculateSavedOnSupplies';
import mapOrder from '../../lib/mapOrder';

import { shopifyClient } from '../../gatsby-theme-apollo/client';
import {
  GET_CHECKOUT,
  CHEKOUT_LINE_ITEMS_REMOVE,
  APPLY_DISCOUNT_CODE,
} from '../../queries/cartQueries';
import useAddToCart from './useAddToCart';
import useUpdateLineItems from './useUpdateLineItems';
import countCartItems from '../../lib/cart/countCartItems';
import checkLimits from '../../lib/cart/checkLimits';
import parseCheckoutDiscounts from '../../lib/cart/parseCheckoutDiscounts';
import {
  toggleOpen as reduxToggleOpen,
  setOpen as reduxSetOpen,
  removeFromSamplesArrangement as reduxRemoveFromSamplesArrangement,
} from '../../state/cart/cartSlice';

function useCart() {
  const dispatch = useDispatch();
  const checkoutId = useSelector((state) => state.cart.checkoutId);
  const discountCode = useSelector((state) => state.cart.discountCode);
  const samplesArrangement = useSelector(
    (state) => state.cart.samplesArrangement
  );

  const { client } = useApolloClient({ client: shopifyClient });
  const loading = false;
  const error = null;

  const data = client.readQuery({
    query: GET_CHECKOUT,
    variables: { id: checkoutId },
  });

  // query cache-only to ensure a render when data from useCart changes.
  // If this isn't called, sample cart doesn't always re-render when removing
  // items
  useQuery(GET_CHECKOUT, {
    client: shopifyClient,
    variables: {
      id: checkoutId,
    },
    fetchPolicy: 'cache-only',
  });

  // Computations
  const lineItems = get('node.lineItems.edges', data) || [];
  const completedAt = get('node.completedAt', data);
  const cartItemCount = useMemo(() => countCartItems(lineItems), [lineItems]);
  const sampleItemCount = useMemo(
    () =>
      countCartItems(
        filter((x) => isMemo(x) || isSample(x), map('node', lineItems))
      ),
    [lineItems]
  );

  const customAttributes = get('node.customAttributes', data);

  const subtotal = useMemo(
    () => parseFloat(get('node.subtotalPriceV2.amount', data)),
    [data]
  );
  const lineItemSubtotal = useMemo(
    () => parseFloat(get('node.lineItemsSubtotalPrice.amount', data)),
    [data]
  );
  const orderDiscounts = parseCheckoutDiscounts(
    get('node.discountApplications', data)
  );
  const savings = subtotal - lineItemSubtotal;

  const preDiscountSubtotal = parseFloat(
    get('node.lineItemsSubtotalPrice.amount', data) || 0
  );

  const gallonsToPaintDiscount = useMemo(
    () => calculateCartDiscounts.gallonsToPaintDiscount(lineItems),
    [lineItems]
  );

  const savedOnPaint = calculateSavedOnPaint(lineItems);
  const savedOnSupplies = calculateSavedOnSupplies(lineItems);

  const checkoutUrl = get('node.webUrl', data);

  const samples = useMemo(
    () => filter(isSample, map('node', lineItems)),
    [lineItems]
  );

  const memos = useMemo(
    () => filter(isMemo, map('node', lineItems)),
    [lineItems]
  );

  const sampleCartSamples = useMemo(
    () => filter((x) => isMemo(x) || isSample(x), map('node', lineItems)),
    [lineItems]
  );

  const paintItems = useMemo(
    () => filter(isPaint, map('node', lineItems)),
    [lineItems]
  );

  const gallonsToSupplyDiscount = useMemo(() =>
    calculateCartDiscounts.gallonsToSupplyDiscount(lineItems)
  );
  const samplesOnly = isEqual(0, paintItems.length) && samples.length > 0;
  const sampleItems = useMemo(
    () =>
      flatten(
        map((x) => {
          if (x.quantity > 1) {
            const instances = new Array(x.quantity);
            instances.fill(x);
            return instances;
          } else {
            return x;
          }
        }, samples)
      ),
    [samples]
  ).sort(mapOrder(samplesArrangement, 'variant.id'));
  const samplesSubtotal = sum(
    map((x) => {
      const price = getVariantPrice(get('variant', x), true);
      return parseFloat(price);
    }, sampleCartSamples)
  );

  // Mutations
  const [removeLineItems] = useMutation(CHEKOUT_LINE_ITEMS_REMOVE, {
    client: shopifyClient,
  });
  const [applyDiscountCode] = useMutation(APPLY_DISCOUNT_CODE, {
    client: shopifyClient,
  });
  const { loading: loadingAddToCart } = useAddToCart();
  const {
    adjustLineItemQuantity,
    updateLineItems,
    loading: adjusting,
  } = useUpdateLineItems();

  // Callbacks
  const removeFromSamplesArrangement = useCallback((id) =>
    dispatch(reduxRemoveFromSamplesArrangement(id))
  );

  // Actions
  const toggleOpen = () => dispatch(reduxToggleOpen());
  const setOpen = (payload) => dispatch(reduxSetOpen(payload));

  return {
    loading: loading || loadingAddToCart,
    completedAt,
    adjusting,
    error,
    cartItemCount,
    sampleItemCount,
    updateLineItems,
    removeLineItems,
    applyDiscountCode,
    checkLimits,
    toggleOpen,
    setOpen,
    subtotal,
    sampleItems,
    samplesSubtotal,
    orderDiscounts,
    savings,
    preDiscountSubtotal,
    lineItems,
    customAttributes,
    checkoutUrl,
    checkoutId,
    gallonsToPaintDiscount,
    savedOnPaint,
    savedOnSupplies,
    samples,
    memos,
    sampleCartSamples,
    paintItems,
    adjustLineItemQuantity,
    gallonsToSupplyDiscount,
    samplesOnly,
    discountCode,
    removeFromSamplesArrangement,
  };
}

export default useCart;
