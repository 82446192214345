import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const Container = styled(Box)``;

import HeaderSectionMenuItem from './HeaderSectionMenuItem';
import TextTitle from './TextTitle';
import useVisibilitySettings from './useVisibilitySettings';

const HeaderSectionMenu = ({ title, items, ...rest }) => {
  const isVisible = useVisibilitySettings(rest.visibility || []);
  return isVisible ? (
    <Container gap="small">
      {title && <TextTitle>{title}</TextTitle>}
      {items.map((item, i) => (
        <HeaderSectionMenuItem key={item._uid} {...item} index={i} />
      ))}
    </Container>
  ) : null;
};

HeaderSectionMenu.propTypes = {
  component: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
};

export default memo(HeaderSectionMenu);
