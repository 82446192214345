// TODO: Remove Filter/Query Sort actions and state properties in favor of
// route-based filtering

import { createSlice } from '@reduxjs/toolkit';
import { get, reject } from '../../lib/nodash';
import { decodeQueryParams, StringParam } from 'serialize-query-params';
import queryString from 'query-string';
import { CommaArrayParam } from '../../lib/customParams';

const isBrowser = typeof window !== 'undefined';

const decodedQuery = decodeQueryParams(
  {
    tags: CommaArrayParam,
    tagGroups: CommaArrayParam,
    colorTags: CommaArrayParam,
    colors: CommaArrayParam,
    sort: StringParam,
    placement: StringParam,
  },
  queryString.parse(isBrowser ? window.location.search : '')
);

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    activePlacementId: decodedQuery.placement,
    activeProduct: null,
    activePlacements: [],
    activeCategories: [],
    activeFilters: [],
    ugcTags: decodedQuery.tags,
    colorTags: decodedQuery.colorTags,
    colors: decodedQuery.colors,
    relatedProducts: [],
    allFilterCriteria: [],
    sorter: isBrowser ? decodedQuery.sort : null,
    searchQuery: null,
    page: 1,
    pageSize: 52,
  },
  reducers: {
    viewPlacementDetails: (state, action) => {
      state.activePlacementId = action.payload.placementId;
      if (action.payload.placements) {
        state.activePlacements = action.payload.placements;
      }
      if (action.payload.product) {
        state.activeProduct = action.payload.product;
      }
    },
    clearPlacementDetails: (state) => {
      state.activePlacementId = null;
      state.activePlacements = [];
      state.activeProduct = null;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    activateColorFamiliesAndColors: (state, action) => {
      const { payload } = action;
      state.activeCategories = [
        ...state.activeCategories,
        ...payload.colorFamilies,
      ];
    },
    setSupportingData: (state, action) => {
      state.featuredFilters = action.payload.featuredFilters;
      state.allFilterCriteria = action.payload.allFilterCriteria;
      state.activeCategories = action.payload.activeCategories;
    },
    activateColorFamilyFilter: (state, action) => {
      const { payload } = action;
      state.activeCategories.push(payload);
    },
    deactivateColorFamilyFilter: (state, action) => {
      const { payload } = action;
      state.activeCategories = reject(
        { slug: get('slug', payload) },
        state.activeCategories
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  viewPlacementDetails,
  clearPlacementDetails,
  resetFilters,
  setPage,
  changeTagFilter,
  changeColors,
  toggleCategory,
  toggleColorFilter,
  toggleTagFilter,
  setSupportingData,
  activateColorFamiliesAndColors,
  activateColorFamilyFilter,
  deactivateColorFamilyFilter,
  setSort,
} = gallerySlice.actions;

export default gallerySlice.reducer;
