import { useLayoutEffect, useState } from 'react';
import { throttle } from '../lib/nodash';
import isBrowser from '../lib/isBrowser';
export const useWindowSize = () => {
  const [size, setSize] = useState([
    isBrowser ? window.innerWidth : 1440,
    isBrowser ? window.innerHeight : 900,
  ]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      document.documentElement.style.setProperty(
        ' — app-height',
        `${window.innerHeight}px`
      );
    }
    const throttled = throttle(updateSize, 800);
    window.addEventListener('resize', throttled);
    updateSize();
    return () => window.removeEventListener('resize', throttled);
  }, []);
  return size;
};
